import {Button, Row, Col} from "antd";
import {useState, useEffect} from "react";
import Spinner from "../../../shared/spinner";
import {useNavigate} from "react-router-dom";
import useCurrentUser from "../../../Hooks/UserHook";
import ExportationTable from "../ExportationTable";
import InputSearch from "../../../Componentes/InputSearch/InputSearch";
import {ExportationStateEnum} from "../../../shared/enums/exportation-state.enum";
import {getExportationList} from "../../../Servicios/ExportationServices";
import localStorageFilter from "../../../shared/utils/local-storage-filter";
import {errorMessage} from "../../../utils/toast-message";

interface Filters {
  pageCount: number;
  currentPage: number;
  search: string;
}

const ExportationPendientesPage = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [exportations, setExportations] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter('exportationPendiente:filter', 'pageCount') ?? 15,
    currentPage: localStorageFilter('exportationPendiente:filter', 'currentPage') ?? 1,
    search: localStorageFilter('exportationPendiente:filter', 'search') ?? "",
  });

  useEffect(() => {
    if (currentUser) {
      initExportations(filters).then();
    }

    // save filters
    const localFilters = localStorageFilter();
    if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('exportationPendiente:filter', JSON.stringify(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, filters]);

  const initExportations = async (filters: Filters) => {
    try {
      setLoading(true);
      const data = await getExportationList(
        filters.currentPage,
        filters.pageCount,
        currentUser?.idubc,
        ExportationStateEnum.Creado,
        "",
        filters.search,
        "",
        0,
        false
      );
      setLoading(false);
      setTotalElements(data?.totalElements);

      setExportations(data.list.map((e, index) => {
        return {
          key: index,
          ...e,
        }
      }));
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  }

  const handleTableChange = (page, pageSize) => {
    setFilters({
      ...filters,
      pageCount: pageSize,
      currentPage: page,
    });
  }

  const handleSearch = async (value) => {
    setFilters({
      ...filters,
      search: value,
      currentPage: 1,
    });
  }

  return (
    <>
      <Spinner loading={loading}>
        <Row gutter={[16, 24]}>
          <Col span={24} style={{display: "flex", justifyContent: "space-between"}}>
            <h4>Control de Booking</h4>

            <Button type="primary" onClick={() => navigate("/exportation/create")}>
              Registrar Nueva Exportación
            </Button>
          </Col>
        </Row>

        <hr/>
        <div className="mb-3">
          <InputSearch initialValue={filters.search}
                       placeholder={'Search by shipping name, booking number, vin or lot'}
                       showButton={true}
                       doChange={handleSearch} />
        </div>
        <ExportationTable exportations={exportations}
                          pageCount={filters.pageCount}
                          currentPage={filters.currentPage}
                          totalElements={totalElements}
                          onPageChange={handleTableChange}
                          onRefreshTable={() => initExportations(filters).then()}
                          onEditExportation={(exportation) => navigate(`/exportation/edit/${exportation.exportationId}`)} />
      </Spinner>
    </>
  );
};

export default ExportationPendientesPage;
