import {Col, Row} from "antd";
import moment from "moment";

const TransporteCitaInfo = ({transporteCita}) => {
  return(
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <p className="fw-bold mb-0">Fecha de la Cita:</p>
          <p className="mb-0">{ moment(transporteCita?.fecha)?.format('MM-DD-yyyy') }</p>
        </Col>

        <Col xs={6}>
          <p className="fw-bold mb-0">Lote:</p>
          <p className="mb-0">{ transporteCita?.coordinacion?.lote }</p>
        </Col>

        <Col xs={18}>
          <p className="fw-bold mb-0">Vehículo:</p>
          <p className="mb-0">{ transporteCita?.coordinacion?.vehiculoName }</p>
        </Col>

        <Col xs={24}>
          <p className="fw-bold mb-0">Vin:</p>
          <p className="mb-0">{ transporteCita?.coordinacion?.vin }</p>
        </Col>

        <Col xs={6}>
          <p className="fw-bold mb-0">Transportista:</p>
          <p className="mb-0">{ transporteCita?.coordinacion?.transporteName }</p>
        </Col>
      </Row>
    </>
  );
}

export default TransporteCitaInfo;
