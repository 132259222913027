import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import {exportationContainerStateEnum} from "../../../shared/enums/exportation-container-state.enum";
import {Alert, Button, Select, Table} from "antd";
import EditableCell from "../../../Componentes/EditableCell/EditableCell";

const AsignarVehiculoTable = ({
                                cliente,
                                vehiculos,
                                selectedVehiculos,
                                exportationContainer,
                                onSelectVehiculo,
                                onDeleteVehiculo,
                                onUpdateVehicleVin }) => {

  const handleSelectVehiculo = (vehiculoId) => {
    onSelectVehiculo(vehiculoId);
  }

  const handleDeleteVehiculo = (vehiculo) => {
    onDeleteVehiculo(vehiculo);
  }

  const vehiculoTemplate = vehiculo => {
    return(
      <>
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fw-bold"># LOTE: {vehiculo.lote}</p>
        </div>
        <p className="mb-0">{vehiculo.vehiculoName}</p>
        <p className="mb-0">VIN: {vehiculo.vin}</p>
      </>
    );
  }

  const handleUpdateVehicleVin = (vehicle, newValue) => {
    onUpdateVehicleVin({ ...vehicle, vin: newValue });
  }

  const columns = [
    {
      width: 80,
      render: (_, element) => <ShowWhen show={!exportationContainer || exportationContainer.state !== exportationContainerStateEnum.cargado}>
        <Button type={'default'} danger={true} className="ms-3" onClick={() => handleDeleteVehiculo(element)}>
          <FontAwesomeIcon size={'sm'} icon={faTrash}/>
        </Button>
      </ShowWhen>
    },
    { key: 'lote', dataIndex: 'lote', title: 'Lote' },
    { key: 'vehiculoName', dataIndex: 'vehiculoName', title: 'Vehículo' },
    {
      key: 'vin',
      dataIndex: 'vin',
      title: 'Vin',
      render: (value, element) => <EditableCell type={'text'}
                                                value={value}
                                                onSubmit={(newValue) => handleUpdateVehicleVin(element, newValue)}
                                                editable={true}>{value}</EditableCell>
    }
  ];

  return(
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p className="mb-0 fw-bold">Vehículos</p>
      </div>

      <Alert message={`Al momento de asignar vehículos usted solo deberá seleccionar vehículos pertenecientes al cliente ${cliente?.nombreCompleto} - ${cliente?.importadoraName}`}
             type="info"
             showIcon/>

      <Select className="w-100 my-3"
              showSearch
              disabled={exportationContainer && exportationContainer.state === exportationContainerStateEnum.cargado}
              value={'Seleccionar un Vehículo'}
              placeholder={'Seleccionar un Vehículo'}
              onChange={handleSelectVehiculo}
              filterOption={(input, option) =>
                (option?.searchLabel ?? "").toLowerCase().includes(input.toLowerCase())}
              options={vehiculos?.map(e => ({
                label: vehiculoTemplate(e),
                value: e?.vehiculoId,
                searchLabel: `${e?.lote}-${e?.vehiculoName}-${e?.vin}`,
              })) ?? []}/>

      <Table size={'small'}
             columns={columns}
             dataSource={selectedVehiculos} />
    </>
  );
}

export default AsignarVehiculoTable;
