export const consecutivoEnum = {
  sequenceExportationCalifornia: "EXPORTATION",
  SequenceExportationFlorida: "FLORIDAEXPO",
  sequenceExportationPennsylvania: "EXPORT_PENNSYLVANIA",
  sequenceShippingInstruction: "SHIPPING_INSTRUCTION",
  sequenceExportGeneralCargoFlorida: "EXPORT_GC_FLORIDA",
  sequenceExportGeneralCargoCalifornia: "EXPORT_GC",
  sequenceExportGeneralCargoPennsylvania: "EXP_GC_PENNSYLVANIA",
  sequenceWarehouseClientePreferencial: "WAREHOUSE_CP",
  sequenceWarehouse: "WEREHOUSE",
  sequenceCliente: "EXP_CLIENTE_"
}
