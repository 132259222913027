import {Button, Layout, Modal, theme} from "antd";
import Sider from "antd/es/layout/Sider";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import TransportistaCitasCalendar from "./TransportistaCitasCalendar";
import {getTransporteCitas} from "../../Servicios/TransporteCitaService";
import moment from "moment";
import TransporteCitaInfo from "./TransporteCitaInfo";
import TransporteCitaCard from "./TransporteCitaCard";
import Spinner from "../../shared/spinner";
import useCurrentUser from "../../Hooks/UserHook";

const TransportistaCitasPage = () => {
  const { token: { colorBgContainer } } = theme.useToken();
  const currentUser = useCurrentUser();

  const [month, setMonth] = useState((new Date().getMonth() + 1));
  const [year, setYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [transporteCitas, setTransporteCitas] = useState([]);
  const [transporteCitaModal, setTransporteCitaModal] = useState(undefined);
  const [showTransporteCitaModal, setShowTransporteCitaModal] = useState(false);
  const [selectedTransporteCitas, setSelectedTransporteCitas] = useState([]);

  useEffect(() => {
    if (currentUser) {
      init(year, month).then();
    }
  }, [currentUser]);

  const init = async (year, month) => {
    setLoading(true);
    const transporteCitas = await getTransporteCitas(currentUser.idubc, year, month);
    setLoading(false);
    setTransporteCitas(transporteCitas);
  }

  const handleSelectTransporeCita = (transporteCita) => {
    setTransporteCitaModal(transporteCita);
    setShowTransporteCitaModal(true);
  }

  const handleMonthChange = (event) => {
    const pMonth = event.getMonth() + 1;
    const pYear = event.getFullYear();

    if (pMonth !== month || pYear !== year) {
      setMonth(pMonth);
      setYear(pYear);

      init(pYear, pMonth).then();
    }
  }

  return(
    <>
      <Spinner loading={loading}>
        <Layout className="transportista-citas-container-page" style={{ minHeight: 'calc(100vh - 160px)' }}>
          <Sider style={{ background: colorBgContainer }}
                 className="pe-4 overflow-auto"
                 collapsible
                 width={'280px'}
                 trigger={null}
                 collapsed={collapsed}
                 collapsedWidth={0}
                 onCollapse={(value) => setCollapsed(value)}>
            <p className="mb-0 fw-bold font-18">Lista de citas</p>
            <p className="font-14">{ moment(selectedTransporteCitas[0]?.fecha)?.format('ll') }</p>
            {
              selectedTransporteCitas.map((e, i) => {
                return(
                  <div key={i}>
                    <TransporteCitaCard transporteCita={e}
                                        onClick={() => handleSelectTransporeCita(e)}></TransporteCitaCard>
                  </div>
                )
              })
            }
          </Sider>
          <Layout className="p-3">
            <div className="d-flex align-items-center justify-content-start gap-3 mb-3">
              <Button type="text"
                      icon={<FontAwesomeIcon icon={faBars} />}
                      onClick={() => setCollapsed(!collapsed)} />
              <p className="mb-0 font-18 fw-bold">
                Citas
              </p>
            </div>
            <TransportistaCitasCalendar transporteCitas={transporteCitas}
                                        onMonthChange={handleMonthChange}
                                        onSelectTransporteCitas={(event) => setSelectedTransporteCitas(event)} />
          </Layout>
        </Layout>
      </Spinner>
      <Modal open={showTransporteCitaModal}
             title={'Información de la Cita'}
             onCancel={() => {
               setShowTransporteCitaModal(false);
               setTransporteCitaModal(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <TransporteCitaInfo transporteCita={transporteCitaModal} />
      </Modal>
    </>
  );
}

export default TransportistaCitasPage;
