import React, { useState, useEffect } from "react";
import {
  faArrowsRotate,
  faLeftLong,
  faFloppyDisk,
  faCirclePlus, faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import "../hojas-de-estilo/style.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {
  showAduana,
  showTransp,
  showOrigen,
  showSubasta, showCountries,
} from "../Servicios/CombosService";
import {viewTransp, addTransporte, editTransp} from "../Servicios/ComponedoresService";
import Select from "react-select";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { viewCoordi, editCoor } from "../Servicios/CoordService";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "antd";
import useCurrentUser from "../Hooks/UserHook";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../shared/spinner";
import {CoordinacionEntryTypeEnum} from "../shared/enums/coordinacion-entry-type.enum";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import InputSearchCustomer from "../Componentes/InputSearchCustomer/InputSearchCustomer";
import InputSearchImportadora from "../Componentes/InputSearchImportadora/InputSearchImportadora";
import {errorMessage} from "../utils/toast-message";
import {enviarEmail} from "../Servicios/SolicitudesService";
import environment from "../environments/environment";
import {CoordinacionCambioDestinoEmail} from "../Resources/HtmlEmailBody/CoordinacionCambioDestino.email";
import {Row as RowAntd, Col as ColAntd} from "antd";
import {VehiculoEstadoEnum} from "../shared/enums/vehiculo-estado.enum";
import {hasRol} from "../Servicios/AuthService";
import roles from "../utils/roles";
import {NotificacionTransporteEmail} from "../Resources/HtmlEmailBody/NotificacionTransporte.email";
import {CoordinacionStateEnum} from "../shared/enums/coordinacion-state.enum";

const EditarCoor = () => {
  const { id } = useParams();
  const user = useCurrentUser();
  const navigate = useNavigate();

  const [transporte, setTransporte] = useState(null);
  const [idTransporte, setIdTransporte] = useState(null);
  const [transportes, setTransportes] = useState([]);
  const [coordinacionState, setCoordinacionState] = useState(null);

  const [aduanas, setAduana] = useState([]);
  const [origen, setOrigen] = useState([]);
  const [subasta, setSubasta] = useState([]);
  const [country, setCountry] = useState([]);
  const [viewCoor, setViewCoor] = useState([]);

  const [idSubs, setIdSubs] = useState(null);

  const [showAgregarTransporte, setShowAgregarTransporte] = useState(false);
  const [showModificarTransporte, setShowModificarTransporte] = useState(false);

  const [aduanaId, setAduanaId] = useState(null);
  const [aduanaActual, setAduanaActual] = useState(null);
  const [countrySeleccionado, setCountrySeleccionado] = useState(null);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [importadoraSeleccionada, setImportadoraSeleccionada] = useState(null);

  const handleCloseAgregarTransporte = () => setShowAgregarTransporte(false);

  const handleCloseModificarTransporte = () => setShowModificarTransporte(false);

  const handleShowAgregarTransporte = () => setShowAgregarTransporte(true);

  const handleShowModificarTransporte = () => setShowModificarTransporte(true);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (idTransporte) {
      viewTransp(idTransporte).then((trans) => {
        setTransporte(trans);
      });
    }
  }, [idTransporte]);

  const buscarCoordinacion = () => {
    setIsLoading(true);
    viewCoordi(id)
      .then((coor) => {
        setViewCoor(coor);
        setIdSubs(coor.subastaId);
        setAduanaId(coor.aduanaId);
        setIdTransporte(coor.transporteId);
        setCoordinacionState(coor.state);
        setAduanaActual(coor.aduana);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadTransporte = async () => {
    const data = await showTransp();
    setTransportes(data);
  };

  const loadAduana = async () => {
    const data = await showAduana();
    setAduana(data);
  };

  const loadOrigen = async () => {
    const data = await showOrigen();
    setOrigen(data);
  };

  const loadSubasta = async () => {
    const data = await showSubasta();
    setSubasta(data.list);

    const countries = await showCountries();
    setCountry(countries);
  };

  useEffect(() => {
    if (id) {
      Promise.all([
        loadTransporte(),
        loadAduana(),
        loadOrigen(),
        loadSubasta(),
      ]).then(() => {
        buscarCoordinacion();
      })
    }
  }, [id]);

  const handleChangeTransp = (e) => {
    const { name, value } = e.target;
    setTransporte({
      ...transporte,
      [name]: value,
    });
  };

  const handleOnChangeCK = (evt) => {
    const { name, checked } = evt.target;
    setViewCoor({
      ...viewCoor,
      [name]: checked,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setViewCoor({
      ...viewCoor,
      [name]: value,
    });
  };

  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    setViewCoor({
      ...viewCoor,
      [name]: value === "" ? null : value,
    });
  };

  const handleChangeProfit = (e) => {
    const { name, value } = e.target;

    if (name === "cost") {
      const cost = value; // Se mantiene como string
      const costFloat = parseFloat(value);
      const profitFloat = viewCoor.rate_Quoted - costFloat;
      const profit = profitFloat.toString();

      setViewCoor({
        ...viewCoor,
        [name]: cost,
        profit: profit,
      });
    }
  };

  const handleChangeTotal = (e) => {
    const { name, value } = e.target;
    setViewCoor((prevViewCoor) => ({
      ...prevViewCoor,
      [name]: parseFloat(value) || 0, // Convierte a float o establece a 0 si es nulo o no es un número
      total: calculateTotal({
        ...prevViewCoor,
        [name]: parseFloat(value) || 0,
      }),
    }));
  };

  const calculateTotal = ({ flete, rate_Quoted, bl, costAdditional }) => {
    return flete + rate_Quoted + bl + costAdditional;
  };

  const handleSelectChangeSub = ({ value }) => {
    setIdSubs(value);
    const temp = { ...viewCoor, subastaId: value };
    setViewCoor(temp);
  };

  const handleSelectChange = ({ value }) => {
    setIdTransporte(value);
    const temp = { ...viewCoor, transporteId: value };
    setViewCoor(temp);
  };

  const editarcoordi = async () => {
    //if (!!viewCoor.transporteId && !viewCoor.estPickUp) {
      //errorMessage('La fecha de estimación de salida es requerida');
      //return;
    //}

    //if (!!viewCoor.transporteId && !viewCoor.estDelivery) {
      //errorMessage('La fecha de estimación de llegada es requerida');
      //return;
    //}

    if (!viewCoor.clientes && !clienteSeleccionado) {
      errorMessage('Debe seleccionar un cliente antes de guardar la coordinación');
      return;
    }

    if (!viewCoor.importadora && !importadoraSeleccionada) {
      errorMessage('Debe seleccionar una importadora antes de guardar la coordinación');
      return;
    }

    if (!viewCoor.countryId && !countrySeleccionado) {
      errorMessage('Debe seleccionar un país antes de guardar la coordinación');
      return;
    }

    try {
      setIsLoading(true);
      const data = await editCoor(id, {
        ...viewCoor,
        transporteId: viewCoor.transporteId == null
          ? null
          : +viewCoor.transporteId,
        subastaId: +viewCoor.subastaId === null || +viewCoor.subastaId === 0
            ? null
            : +viewCoor.subastaId,
        quoteId: +viewCoor.quoteId <= 0
          ? null
          : +viewCoor.quoteId,
        origenId: +viewCoor.origenId,
        clienteId: viewCoor.clientes
          ? +viewCoor.clienteId
          : +clienteSeleccionado.id,
        importadoraId: viewCoor.importadora
          ? +viewCoor.importadoraId
          : +importadoraSeleccionada.id,
        aduanaId: +viewCoor.aduanaId,
        countryId: viewCoor.countryId
          ? +viewCoor.countryId
          : +countrySeleccionado.value,
        vehiculoId: +viewCoor.vehiculoId,
        updateBy: user?.fullName,
        updateDate: moment().format("YYYY-MM-DD"),
      });
      setIsLoading(false);

      if (data) {
        // si la aduana es diferente
        // enviar correo
        if (aduanaId !== data.aduanaId && aduanaActual) {
          try {
            await enviarEmail({
              to: [environment.emails.grua],
              subject: `CAMBIO DE DESTINO EN LOTE ${data.lote}`,
              body: CoordinacionCambioDestinoEmail(data, aduanaActual),
            });
          } catch (ex) {
            console.log(ex);
          }
        }

        // notificacion de transporte
        if (viewCoor.transporteId !== null &&
          coordinacionState !== CoordinacionStateEnum.choferAsignado &&
          viewCoor.state === CoordinacionStateEnum.choferAsignado) {
          await enviarNotificacionTransportista(data);
        }

        notifyEditCor();
        navigate(`/coordinations/country/${viewCoor.origenId}`);
      }
    } catch (ex) {
      setIsLoading(false);
      errorMessage(ex.toString());
    }
  };

  const enviarNotificacionTransportista = async (data) => {
    if (!environment.production) {
      data.clientes.email = "manuelpc2495@gmail.com";
    }
    try {
      const to = data?.clientes?.email ? [data?.clientes?.email] : [];
      const cc = [environment.emails.grua];

      await enviarEmail({
        to: to,
        cc: cc,
        subject: `Chofer Asignado para retiro del Vehículo lote ${data.lote}`,
        body: NotificacionTransporteEmail(data)
      });
    } catch (ex) {
      errorMessage(ex.toString());
    }
  }

  const insertarTransport = async () => {
    try {
      setIsLoading(true);
      const data = await addTransporte({
        ...transporte,
      });
      setIsLoading(false);
      if (data) {
        setIdTransporte(data.transporteId);
        setViewCoor({
          ...viewCoor,
          transporteId: data.transporteId
        });
        loadTransporte();
        handleCloseAgregarTransporte();
      }
    } catch (ex) {
      setIsLoading(false);
      console.log(ex);
    }
  };

  const modificarTransport = async () => {
    try {
      setIsLoading(true);
      const data = await editTransp(transporte.transporteId, {
        ...transporte,
      });
      setIsLoading(false);
      if (data) {
        setIdTransporte(data.transporteId);
        setViewCoor({
          ...viewCoor,
          transporteId: data.transporteId
        });
        loadTransporte();
        handleCloseModificarTransporte();
      }
    } catch (ex) {
      setIsLoading(false);
      console.log(ex);
    }
  };

  const notifyEditCor = () =>
    toast.success("Datos guardados correctamente", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  if (!viewCoor) {
    return <></>;
  }

  return (
    <Spinner loading={isLoading}>
      <Container fluid={true}>
        <Row>
          <h4>Editando Coordinación de {viewCoor?.import}</h4>
        </Row>
        <hr/>
        <Row>
          <div className="col-md-3">
            <Form.Label className="text-aling-left">
              Fecha de Ingreso{" "}
            </Form.Label>{" "}
            <br/>
            <h5>{moment(viewCoor?.fechacor).format("l")} </h5>
          </div>
        </Row>
        <Row>
          <div className="col-md-2">
            <Form.Label className="text-aling-left">
              # Numero de Lote
            </Form.Label>
            <h5>{viewCoor?.lote}</h5>
          </div>
          <div className="col-md-3">
            <Form.Label className="text-aling-left"># VIN</Form.Label>
            <h5>{viewCoor?.vin}</h5>
          </div>
          <div className="col-md-3">
            <Form.Label className="text-aling-left">Vehículo</Form.Label>
            <h5>{viewCoor?.vehiculoName}</h5>
          </div>
          <div className="col-md-2 mb-2">
            <Form.Label className="text-aling-left">Color</Form.Label>
            <h5>{viewCoor?.color}</h5>
          </div>
          <div className="col-md-2">
            <div className="d-flex align-items-center justify-content-start gap-2">
              <Form.Check
                type="checkbox"
                className="checkbox"
                checked={viewCoor?.cdealer}
                onChange={handleOnChangeCK}
                name="cdealer"
                id="cdealer"
              />
              <label className="fw-bold" htmlFor="cdealer">Matus Dealer?</label>
            </div>
            <br/>
          </div>
          <hr/>
        </Row>
        <Row>
          <div className="col-12">
            <Form.Label className={`text-aling-left ${viewCoor?.clientes ? '' : 'text-danger fw-bold'}`}>
              Nombre del Cliente
            </Form.Label>
            <br/>
            <ShowWhen show={viewCoor?.clientes}>
              <Form.Label className="text-aling-left">
                <strong>{viewCoor?.clienteName}</strong>
              </Form.Label>
            </ShowWhen>
            <ShowWhen show={!viewCoor?.clientes}>
              <>
                <div className="mb-3">
                  <InputSearchCustomer selectedCustomer={clienteSeleccionado}
                                       onSelectedCustomer={value => setClienteSeleccionado(value)}/>
                </div>
                <div className="mb-3">
                  <Form.Label className={`text-aling-left ${viewCoor?.countryId ? '' : 'text-danger fw-bold'}`}>
                    Seleccionar País
                  </Form.Label>
                  <Select placeholder={'Seleccionar pais'}
                          name={'countryId'}
                          value={viewCoor ? { value: viewCoor?.countryId, label: viewCoor?.countryName} : ""}
                          onChange={value => setCountrySeleccionado(value)}
                          options={country && country.length ? country.map(e => {
                            return {
                              value: e.id,
                              label: e.nameCountry
                            }
                          }) : []} />
                </div>
              </>
            </ShowWhen>
          </div>
          <div className="col-md-2">
            <Form.Label className="text-aling-left"># Buyer</Form.Label>
            <Form.Control
              aria-label="Small"
              name="buyer"
              onChange={handleChange}
              value={viewCoor && viewCoor?.buyer}
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>

          <div className="col-md-2">
            <Form.Label className="text-aling-left"># Gate Pass</Form.Label>
            <Form.Control
              name="gatePassPin"
              onChange={handleChange}
              value={viewCoor?.gatePassPin}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>

          <div className="col-md-2">
            <Form.Label className="text-aling-left">Origen</Form.Label>
            <Form.Select
              name="origenId"
              onChange={handleChange}
              value={viewCoor?.origenId}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            >
              <option disabled selected>
                Seleccione
              </option>
              {origen &&
                origen.length &&
                origen.map((ori, index) => {
                  return (
                    <option key={index} value={ori.id}>
                      {ori.nameOrigen}
                    </option>
                  );
                })}
            </Form.Select>
          </div>

          <div className="col-md-3">
            <Form.Label className="text-aling-left">Aduana Destino</Form.Label>
            <Form.Select
              name="aduanaId"
              onChange={handleChange}
              value={viewCoor?.aduanaId}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            >
              <option disabled selected>
                Seleccione
              </option>
              {aduanas &&
                aduanas.length &&
                aduanas.map((aduanas, index) => {
                  return (
                    <option key={index} value={aduanas.id}>
                      {aduanas.nameAduana + "-" + aduanas.countryName}
                    </option>
                  );
                })}
            </Form.Select>
            <br/>
          </div>

          <div className="col-md-3">
            <Form.Label className="text-aling-left">Forma de Entrega en Bodega</Form.Label>
            <Form.Select
              name="entryType"
              onChange={handleChange}
              value={viewCoor?.entryType ?? "0"}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            >
              <option disabled value={"0"}>Seleccione</option>
              <option value={CoordinacionEntryTypeEnum.entryTypeByOwn}>{CoordinacionEntryTypeEnum.entryTypeByOwn}</option>
              <option value={CoordinacionEntryTypeEnum.entryTypeByClient}>{CoordinacionEntryTypeEnum.entryTypeByClient}</option>
              <option value={CoordinacionEntryTypeEnum.entryTypePendingByClient}>{CoordinacionEntryTypeEnum.entryTypePendingByClient}</option>
            </Form.Select>
          </div>
          <hr/>
        </Row>
        <Row>
          <div className="col-md-12">
            <Form.Label className={`text-aling-left ${viewCoor?.importadora ? '' : 'text-danger fw-bold'}`}>
              Nombre Importadora
            </Form.Label>
            <br/>
            <ShowWhen show={viewCoor.importadora}>
              <Form.Label className="text-aling-left">
                <strong>{viewCoor?.importadoraName}</strong>
              </Form.Label>
            </ShowWhen>
            <ShowWhen show={!viewCoor.importadora}>
              <div className="mb-3">
                <InputSearchImportadora selectedImportadora={importadoraSeleccionada}
                                        onSelectImportadora={value => setImportadoraSeleccionada(value)} />
              </div>
            </ShowWhen>
          </div>
          <hr/>
        </Row>
        <Row>
          <div className="col-md-12">
            <Form.Label className="text-aling-left">
              Subasta/Ubicación
            </Form.Label>
            <Select
              onChange={handleSelectChangeSub}
              name="subastaId"
              options={subasta.map((subs) => ({
                label: `${subs?.subName} - ${subs?.addressLine1Sub} ${subs?.addressLine2Sub ?? ""} ${subs?.city ?? ""}`.trim(),
                value: subs.subId,
              }))}
              value={
                idSubs
                  ? {
                    label: `${
                      subasta?.find((s) => s?.subId === idSubs)?.subName
                    } - ${
                      subasta.find((s) => s?.subId === idSubs)
                        ?.addressLine1Sub
                    } ${
                      subasta?.find((s) => s?.subId === idSubs)
                        ?.addressLine2Sub ?? ""
                    } ${subasta?.find((s) => s?.subId === idSubs)?.city ?? ""}`.trim(),
                    value: idSubs,
                  }
                  : null
              }
            />
            <br/>
          </div>
        </Row>

        <Row>
          <strong>Información de costos</strong>
          <Col xs={6} md={2}>
            <Form.Label className="text-aling-left">Tipo de Pago</Form.Label>
            <Form.Control
              onChange={handleChange}
              type="text"
              name="type_pay"
              value={viewCoor?.type_pay}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col xs={6} md={2}>
            <Form.Label className="text-aling-left">Fecha de Pago</Form.Label>
            <Form.Control
              onChange={handleChange}
              value={viewCoor && viewCoor?.paid}
              name="paid"
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            <br/>
          </Col>
          <Col xs={6} md={1}>
            <Form.Label className="text-aling-left">Flete</Form.Label>
            <Form.Control
              onChange={handleChangeTotal}
              type="text"
              name="flete"
              value={viewCoor?.flete}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col xs={6} md={1}>
            <Form.Label className="text-aling-left">Grua Cot.</Form.Label>
            <Form.Control
              onChange={handleChangeTotal}
              name="rate_Quoted"
              value={viewCoor && viewCoor?.rate_Quoted}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col xs={6} md={1}>
            <Form.Label className="text-aling-left">BL</Form.Label>
            <Form.Control
              onChange={handleChangeTotal}
              type="text"
              name="bl"
              value={viewCoor?.bl}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col xs={6} md={1}>
            <Form.Label className="text-aling-left">Ad. Cost</Form.Label>
            <Form.Control
              onChange={handleChangeTotal}
              type="text"
              name="costAdditional"
              value={viewCoor?.costAdditional}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col xs={6} md={1}>
            <Form.Label className="text-aling-left">Total</Form.Label>
            <Form.Control
              disabled
              readOnly
              type="text"
              value={viewCoor?.total}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col
            style={{borderLeft: "1px solid #c7c8c9", height: "80px"}}
          ></Col>
          <Col xs={6} md={1}>
            <Form.Label className="text-aling-left">Valor Grua</Form.Label>
            <Form.Control
              onChange={handleChangeProfit}
              type="text"
              name="cost"
              value={viewCoor?.cost}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col xs={6} md={1}>
            <Form.Label className="text-aling-left">Profit G.</Form.Label>
            <Form.Control
              disabled
              readOnly
              name="profit"
              value={viewCoor && viewCoor?.profit}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <hr/>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Label className="text-aling-left">
              <strong>Asigne un estado a esta coordinación</strong>
            </Form.Label>
            <Form.Select
              onChange={handleChange}
              name="state"
              value={viewCoor?.state}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            >
              <option disabled selected>
                Seleccione
              </option>
              <option>Vehiculo no pagado en subasta</option>
              <option>Vehiculo pagado en subasta</option>
              <option>Pendiente de prepago</option>
              <option>Chofer asignado</option>
              <option>Vehículo de dirección particular</option>
              <option>Vehículo para corte</option>
              <option>No pick Up</option>
              <option>No delivery</option>
              <option>Vehículo Posteado</option>
              <option>Vehículo en bodega sin WareHouse</option>
              <option>Vehículo en bodega con WareHouse</option>
              <option>Vehículo en bodega con título pendiente</option>
              <option>Vehículo de la cuenta dealer</option>
              {/* <option>Vehículo para entrega en bodega</option> */}
              <option>Vehículo para entrega en bodega</option>
              <option>COMPLETADO</option>
            </Form.Select>
            <br/>
          </Col>
        </Row>
        <Row>
          <hr/>
          <strong>Compañia de transporte</strong>
          <div className="col-md-4 find">
            <Form.Label className="text-aling-left">
              Buscar Transporte
            </Form.Label>
            <Select
              onChange={handleSelectChange}
              name="transporteId"
              options={
                transportes &&
                transportes.length &&
                transportes.map((transp) => ({
                  label: transp.nomComp + "-" + transp.contacto,
                  value: transp.transporteId,
                }))
              }
            />
          </div>
          <div className="col-md-2 align-self-end">
            <Tooltip title="Add new carrier">
              <Button variant="success" onClick={handleShowAgregarTransporte} className="me-2">
                <FontAwesomeIcon icon={faCirclePlus}/>
              </Button>
            </Tooltip>
            <Tooltip title="Edit carrier">
              <Button variant="success" onClick={handleShowModificarTransporte}>
                <FontAwesomeIcon icon={faPencil} />
              </Button>
            </Tooltip>
          </div>
        </Row>
        <br/>
        <Row>
          <Col>
            <Form.Label className="text-aling-left">
              Nombre de la Compañia
            </Form.Label>
            <Form.Control
              disabled
              readOnly
              aria-label="Small"
              value={transporte?.nomComp}
              name="nomComp"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col>
            <Form.Label className="text-aling-left">Conductor</Form.Label>
            <Form.Control
              disabled
              readOnly
              aria-label="Small"
              value={transporte?.contacto}
              name="contacto"
              aria-describedby="inputGroup-sizing-sm"
            />
          </Col>
          <Col>
            <Form.Label className="text-aling-left">
              Numero del Conductor
            </Form.Label>
            <Form.Control
              disabled
              readOnly
              aria-label="Small"
              value={transporte?.telefono}
              name="telefono"
              aria-describedby="inputGroup-sizing-sm"
            />
            <br/>
          </Col>
          <Col>
            <Form.Label className="text-aling-left">
              Correo de la Compañia
            </Form.Label>
            <Form.Control
              disabled
              readOnly
              aria-label="Small"
              value={transporte?.email ?? ""}
              name="email"
              aria-describedby="inputGroup-sizing-sm"
            />
            <br/>
          </Col>
          <hr/>
        </Row>
        <Row className="mb-3">
          <div className="col-md-3">
            <Form.Label className="text-aling-left">
              Primer dia Disponible
            </Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              name="firstBusDay"
              value={viewCoor && viewCoor?.firstBusDay}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
          <div className="col-md-3">
            <Form.Label className="text-aling-left">Est. Pick Up</Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              name="estPickUp"
              value={viewCoor && viewCoor?.estPickUp}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
          <div className="col-md-3">
            <Form.Label className="text-aling-left">Est. Delivery</Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              name="estDelivery"
              value={viewCoor && viewCoor?.estDelivery}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
          <div className="col-md-3">
            <Form.Label className="text-aling-left">Departing</Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              name="estArrival"
              value={viewCoor && viewCoor?.estArrival}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
        </Row>
        <Row className="mb-3">
          <div className="col-md-3">
            <Form.Label className="text-aling-left">
              Ultimo dia Disponible
            </Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              name="lastFreeDay"
              value={viewCoor && viewCoor?.lastFreeDay}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
          <div className="col-md-3">
            <Form.Label className="text-aling-left">Act. Pick Up</Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              name="actPickUp"
              value={viewCoor && viewCoor?.actPickUp}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
          <div className="col-md-3">
            <Form.Label className="text-aling-left">Act. Delivery</Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              name="actDelivery"
              value={viewCoor && viewCoor?.actDelivery}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
          <div className="col-md-3">
            <Form.Label className="text-aling-left">Arrival</Form.Label>
            <Form.Control
              onChange={handleChangeDate}
              name="actArrival"
              value={viewCoor && viewCoor?.actArrival}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
          </div>
        </Row>
        <Row className="mb-3">
          <div className="col-md-3">
            <Form.Label className="text-aling-left">
              Fecha de Despacho
            </Form.Label>
            <Form.Control
              onChange={handleChange}
              name="dispatchDate"
              value={viewCoor && viewCoor?.dispatchDate}
              type="date"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
            />
            <br/>
          </div>
          <hr/>
        </Row>
        <RowAntd gutter={16}>
          <ColAntd sm={12} md={6} lg={5} className="mb-3">
            <Form.Label className="text-aling-left">Storage-Client</Form.Label>
            <Form.Control
              onChange={handleChange}
              name="st_Cliente"
              value={viewCoor && viewCoor?.st_Cliente}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              disabled={viewCoor?.vehiculo?.estado === VehiculoEstadoEnum.recibidoEnBodega && !hasRol(roles.SUPERVISOR)}
            />
          </ColAntd>
          <ColAntd sm={12} md={6} lg={5} className="mb-3">
            <Form.Label className="text-aling-left">Storage Matus/Client</Form.Label>
            <Form.Control
              onChange={handleChange}
              name="stMatusCliente"
              value={viewCoor && viewCoor?.stMatusCliente}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              disabled={viewCoor?.vehiculo?.estado === VehiculoEstadoEnum.recibidoEnBodega && !hasRol(roles.SUPERVISOR)}
            />
          </ColAntd>
          <ColAntd sm={12} md={6} lg={5} className="mb-3">
            <Form.Label className="text-aling-left">Storage-Driver</Form.Label>
            <Form.Control
              onChange={handleChange}
              name="st_Driver"
              value={viewCoor && viewCoor?.st_Driver}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              disabled={viewCoor?.vehiculo?.estado === VehiculoEstadoEnum.recibidoEnBodega && !hasRol(roles.SUPERVISOR)}
            />
          </ColAntd>
          <ColAntd sm={12} md={6} lg={5} className="mb-3">
            <Form.Label className="text-aling-left">Storage-Matus</Form.Label>
            <Form.Control
              onChange={handleChange}
              name="st_Matus"
              value={viewCoor && viewCoor?.st_Matus}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              disabled={viewCoor?.vehiculo?.estado === VehiculoEstadoEnum.recibidoEnBodega && !hasRol(roles.SUPERVISOR)}
            />
          </ColAntd>
          <ColAntd sm={12} md={6} lg={4} className="mb-3">
            <Form.Label className="text-aling-left">Storage Pagado</Form.Label>
            <Form.Control
              onChange={handleChange}
              name="st_Paid"
              value={viewCoor && viewCoor?.st_Paid}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              disabled={viewCoor?.vehiculo?.estado === VehiculoEstadoEnum.recibidoEnBodega && !hasRol(roles.SUPERVISOR)}
            />
          </ColAntd>
        </RowAntd>
        <Row>
          <Col>
            <FloatingLabel controlId="floatingTextarea2" label="Comentario">
              <Form.Control
                name="nota"
                onChange={handleChange}
                value={viewCoor && viewCoor?.nota}
                placeholder="Leave a comment here"
                style={{height: "100px"}}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <hr/>
        <Row className="conte-padre">
          <Button
            style={{width: "120px"}}
            type="button"
            onClick={() =>
              navigate(`/coordinations/country/${viewCoor.origenId}`)
            }
            variant="warning"
          >
            <FontAwesomeIcon icon={faLeftLong}/>
            &nbsp;Regresar
          </Button>{" "}
          &nbsp;
          <Button
            onClick={() => editarcoordi()}
            style={{width: "180px"}}
            variant="primary"
          >
            <FontAwesomeIcon icon={faArrowsRotate}/>
            &nbsp;Guardar Cambios
          </Button>{" "}
        </Row>

        <>
          <Modal
            show={showAgregarTransporte}
            onHide={handleCloseAgregarTransporte}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Agregar Nuevo Transportista</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Spinner loading={isLoading}>
                <Form.Label>Compañia de transporte</Form.Label>
                <Form.Control
                  onChange={handleChangeTransp}
                  name="nomComp"
                  type="text"
                  placeholder="Nombre compañia de transporte"
                />
                <br/>
                <Form.Label>Conductor</Form.Label>
                <Form.Control
                  onChange={handleChangeTransp}
                  name="contacto"
                  type="text"
                  placeholder="Conductor"
                />
                <br/>
                <Form.Label>Numero de Conductor</Form.Label>
                <Form.Control
                  onChange={handleChangeTransp}
                  name="telefono"
                  type="text"
                  placeholder="Numero de contacto"
                />
                <br/>
              </Spinner>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseAgregarTransporte} variant="secondary">
                Cancelar
              </Button>
              <Button onClick={insertarTransport} variant="primary">
                {" "}
                <FontAwesomeIcon icon={faFloppyDisk}/> Guardar
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showModificarTransporte}
            onHide={handleCloseModificarTransporte}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Modificar Transportista</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Spinner loading={isLoading}>
                <Form.Label>Compañia de transporte</Form.Label>
                <Form.Control
                  value={transporte?.nomComp}
                  onChange={handleChangeTransp}
                  name="nomComp"
                  type="text"
                  placeholder="Nombre compañia de transporte"
                />
                <br/>
                <Form.Label>Conductor</Form.Label>
                <Form.Control
                  value={transporte?.contacto}
                  onChange={handleChangeTransp}
                  name="contacto"
                  type="text"
                  placeholder="Conductor"
                />
                <br/>
                <Form.Label>Numero de Conductor</Form.Label>
                <Form.Control
                  value={transporte?.telefono}
                  onChange={handleChangeTransp}
                  name="telefono"
                  type="text"
                  placeholder="Numero de contacto"
                />
                <br/>
                <Form.Label>Correo de la Compañia</Form.Label>
                <Form.Control
                  value={transporte?.email}
                  onChange={handleChangeTransp}
                  name="email"
                  type="text"
                  placeholder="Correo de la Compañia"
                />
              </Spinner>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseModificarTransporte} variant="secondary">
                Cancelar
              </Button>
              <Button onClick={modificarTransport} variant="primary">
                {" "}
                <FontAwesomeIcon icon={faFloppyDisk}/> Guardar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Container>
    </Spinner>
  );
};

export default EditarCoor;
