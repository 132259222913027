import {Button, Select, Table} from "antd";
import {exportationContainerStateEnum} from "../../../shared/enums/exportation-container-state.enum";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faWarning} from "@fortawesome/free-solid-svg-icons";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";

const AsignarWarehouseTable = ({ warehouses, selectedWarehouses, exportationContainer, onSelectWerehouse, onDeleteWarehouse }) => {

  const handleSelectWerehouse = (werehouseId) => {
    onSelectWerehouse(werehouseId);
  }

  const handleDeleteWerehouse = (warehouse) => {
    onDeleteWarehouse(warehouse);
  }

  const warehouseTemplate = warehouse => {
    if (warehouse.hasVehicle)
      return(
        <>
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb-0 fw-bold">{warehouse.codigo}</p>
            <p className="mb-0 fw-bold"># LOTE: {warehouse.lote}</p>
          </div>
          <p className="mb-0">{warehouse.vehiculoName}</p>
          <p className="mb-0">VIN: {warehouse.vin}</p>
        </>
      );

    return(
      <>
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0 fw-bold">{warehouse.codigo}</p>
          <p className="mb-0 fw-bold"><FontAwesomeIcon icon={faWarning} className="me-2"/> Warehouse con AutoPartes</p>
        </div>
        <p className="mb-0">{warehouse?.extraItems?.[0]?.extraItemName}</p>
        <p className="mb-0">Cant.: {warehouse?.extraItems?.[0]?.quantity} | Peso.: {warehouse?.extraItems?.[0]?.weight} Kg</p>
      </>
    );
  }

  const columns = [
    {
      width: 80,
      render: (_, element) => <ShowWhen show={!exportationContainer || exportationContainer.state !== exportationContainerStateEnum.cargado}>
        <Button type={'default'} danger={true} className="ms-3" onClick={() => handleDeleteWerehouse(element)}>
          <FontAwesomeIcon size={'sm'} icon={faTrash}/>
        </Button>
      </ShowWhen>
    },
    { width: 100, key: 'codigo', dataIndex: 'codigo', title: 'Código' },
    { width: 100, key: 'lote', dataIndex: 'lote', title: 'Lote' },
    {
      width: 250, key: 'vehiculo', dataIndex: 'vehiculo', title: 'Vehículo',
      render: (_, element) => {
        if (element.hasVehicle) {
          return <div>
            <p className="mb-0"><span className="fw-bold">VIN:</span> {element.vin}</p>
            <p className="mb-0">{element.vehiculoName}</p>
          </div>;
        } else {
          return <div>
            <p className="mb-0">{element?.extraItems?.[0]?.extraItemName}</p>
            <p className="mb-0">(x{element?.extraItems?.[0]?.quantity})</p>
          </div>;
        }
      }
    },
    {
      width: 250, key: 'aduana', dataIndex: 'aduana', title: 'Aduana',
      render: (_, element) => <div>
        <p className="mb-0 fw-bold">Aduana:</p>
        <p className="mb-0">{element.aduName} {element.aduana?.countryName}</p>
      </div>
    },
    {
      width: 150, key: 'flete', dataIndex: 'flete', title: 'Flete',
      render: (_, element) => <div>$ {element?.flete ?? 0}</div>
    },
    {
      width: 250, key: 'importadora', dataIndex: 'importadora', title: 'Importadora',
      render: (_, element) => <div>
        <p className="mb-0 fw-bold">Importadora:</p>
        <p className="mb-0">{element.impName}</p>
      </div>
    }
  ];

  return(
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p className="mb-0 fw-bold">Warehouse</p>
      </div>

      <Select className="w-100 mb-3"
              showSearch
              disabled={exportationContainer && exportationContainer.state === exportationContainerStateEnum.cargado}
              value={'Seleccionar un Warehouse'}
              placeholder={'Seleccionar un Warehouse'}
              onChange={handleSelectWerehouse}
              filterOption={(input, option) =>
                (option?.searchLabel ?? "").toLowerCase().includes(input.toLowerCase())}
              options={warehouses?.map(e => ({
                label: warehouseTemplate(e),
                value: e?.werehouseId,
                searchLabel: `${e?.codigo}-${e?.lote}-${e?.vehiculoName}-${e?.vin}`,
              })) ?? []}/>

      <Table size={'small'}
             columns={columns}
             dataSource={selectedWarehouses}
             scroll={{x: 1300}}
             summary={(pageData) => {
               let total = 0;

               pageData.forEach(({flete}) => {
                 total += flete;
               });

               return (
                 <>
                   <Table.Summary.Row>
                     <Table.Summary.Cell index={0} colSpan={5}></Table.Summary.Cell>
                     <Table.Summary.Cell index={1}>
                       <div className="fw-bold">$ {total}</div>
                     </Table.Summary.Cell>
                   </Table.Summary.Row>
                 </>
               )
             }}/>
    </>
  );
}

export default AsignarWarehouseTable;
