import moment from "moment";

export const SolicitudRecoleccionVehiculo = (data) => `<!DOCTYPE html>
  <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta name="x-apple-disable-message-reformatting">
    <title>Solicitud de recoleccion</title>
            <!--[if mso]>
            
            <noscript>
            
            <xml>
            
            <o:OfficeDocumentSettings>
            
            <o:PixelsPerInch>96</o:PixelsPerInch>
            
            </o:OfficeDocumentSettings>
            
            </xml>
            
            </noscript>
            
            <![endif]-->
            
            <style>
            table, td, div, h1, p {font-family: Arial, sans-serif;}
            </style>
            </head>
            <body style="margin:0;padding:0;">
            <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
            <tr>
            <td align="center" style="padding:0;">
            
              <table role="presentation" style="width:602px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
                  <tr>
                  <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
                    <img src="http://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
                  </td>
                </tr>
                <tr>
                  <td style="padding:36px 30px 42px 30px;">
                    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                      <tr>
                        <td style="padding:0;">
                          <h1>${data.subasta.subId === 571 ? 'Entrega en Bodega' : `Solicitud orden de recolección ${data?.dealer ? ' para la cuenta Dealer' : ''}${data?.cutVehicle ? ' de Vehículo para Corte' : ''}`} ${data.origen.state}</h1>
            
                          <p>Hola departamento de vehículo,<br/><br/> ${data.subasta.subId === 571 ? 'Envio de entrega en bodega' : 'Envio orden de recolección'} del cliente: ${
                            data?.clienteName
                          } bajo la importadora: ${
  data?.importadoraName
} con los siguientes datos:<br/></p>
                          
                          <p><strong>Numero de contacto del Cliente:</strong> ${
                            data?.contCli
                          }</p>
                          
                          <h4>Información del Vehículo (Provehída por el cliente)</h4>
            
                        </td>
                      </tr>
                      <tr>
                        <td style="padding:0;">
                          <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                              <td style="width:260px;padding:0;vertical-align:top;">
                                <strong>Lote:</strong> ${data?.lote}
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td style="width:260px;padding:0;vertical-align:top;">
                                <strong>VIN:</strong> ${data?.vin}
                              </td>
                            </tr>
                            <tr>
                              <td style="width:260px;padding:0;vertical-align:top;">
                                <strong>Vehiculo:</strong> ${data?.vehiculoName}
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td style="width:260px;padding:0;vertical-align:top;">
                                <strong>Buyer:</strong> ${data?.buyer ?? ""}
                              </td>
                            </tr>
                            <tr>
                              <td style="width:260px;padding:0;vertical-align:top;">
                                <strong>Gate Pass:</strong> ${data?.gatePass}
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td style="width:260px;padding:0;vertical-align:top;">
                                <strong>Dealer Matus?:</strong> ${
                                  data?.dealer ? "Si" : "No"
                                }
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style="padding:0;">
                          <h4>Tipo de pago:</h4> ${data?.tipoPago}
                        </td>
                      </tr>
                      <tr>
                        <td style="padding:0;">
                          <h4>Ubicacion / Subasta:</h4> ${data?.subastaCom}
                        </td>
                      </tr>
                      <tr>
                        <td style="padding:0;">
                          <h4>Destino:</h4> ${data?.aduanaName}
                        </td>
                      </tr>
                      <tr>
                        <td style="padding:0;">
                          <h4>Tipo de Título:</h4> ${(data?.vehiculo && data?.vehiculo?.title) ? data?.vehiculo?.title?.titleName : 'N/D'}
                        </td>
                      </tr>
                      <tr><td></td></tr>
                      <tr><td></td></tr>
                      <tr>
                        <td style="padding:0;">
                          <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                              <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                <strong>Servicio</strong>
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                <strong>Precio</strong>
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                FLETE:
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                $ ${data?.n1}
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                GRUA:
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                $ ${data?.n2}
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                BL:
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                $ ${data?.n3}
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                Costo Adicional:
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                $ ${data?.n4}
                              </td>
                            </tr>
                            <tr>
                              <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                <strong>Total:</strong>
                              </td>
                              <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                              <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                $ ${data?.total}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr><td></td></tr>
                      <tr>
                        <td style="padding:0;">
                          <h4>Comentario:</h4> ${data?.comentarios}
                        </td>
                      </tr>
                      <tr>
                        <td style="padding:0;">
                          <strong>Asesor/a:</strong> ${data?.creador}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style="padding:30px;background:#1C2E51;">
                    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                      <tr>
                        <td style="padding:0;width:50%;" align="left">
                          <p style="color:white;">&reg; Matus International, 2023</p>
                        </td>
                        <td style="padding:0;width:50%;" align="right">
                          <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                              <td style="padding:0 0 0 10px;width:38px;">
                              <a href="https://www.facebook.com/matusintl/"><img src="http://portalmatus.com/facebook-white.png" alt="Instagram" width="38" style="height:auto;display:block;border:0;" /></a>
                              </td>
                              <td style="padding:0 0 0 10px;width:38px;">
                                <a href="https://www.instagram.com/matusintl/?hl=en"><img src="http://portalmatus.com/instagram-white.png" alt="Facebook" width="38" style="height:auto;display:block;border:0;" /></a>
                              </td>
                              <td style="padding:0 0 0 10px;width:38px;">
                                <a href="https://www.matusintl.com/"><img src="http://portalmatus.com/web-white.png" alt="Web" width="28" style="height:auto;display:block;border:0;" /></a>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            </tr>
            </table>
            </body>
            </html>`;

export const SolicitudRecoleccionVehiculoFL = (soli) => `<!DOCTYPE html>
        <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width,initial-scale=1">
        <meta name="x-apple-disable-message-reformatting">
        <title>Solicitud de recolección</title>
        <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
        <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
        <style>
        table, td, div, h1, p {font-family: Arial, sans-serif;}
        </style>
        </head>
        <body style="margin:0;padding:0;">
        <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
        <tr>
        <td align="center" style="padding:0;">
        
            <table role="presentation" style="width:602px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
                <tr>
                    <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
                        <img src="https://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
                    </td>
                </tr>
                <tr>
                    <td style="padding:36px 30px 42px 30px;">
                        <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                                <td style="padding:0;">
                                <h1 style="text-align: center;">
                                    Solicitud orden de recolección ${
                                      soli?.dealer === true
                                        ? "para la cuenta Dealer"
                                        : ""
                                    }
                                </h1>
        
                                    <p>Hola departamento de vehículo,<br/><br/> Envio orden de recolección del cliente: ${
                                      soli?.clienteName
                                    } bajo la importadora: ${
                                      soli?.importadoraName
                                    } con los siguientes datos:<br/></p>
                                    <p><strong>Numero de contacto del Cliente:</strong> ${
                                      soli?.contCli
                                    }</p>
                                   ${soli?.clienteFinal ? `<hr />
                                    <p><strong>Cliente final:</strong> ${soli?.clienteFinal?.nombreCompleto}</p>
                                    <p><strong>Dirección:</strong> ${soli?.clienteFinal?.address}</p>
                                    <p><strong>Email:</strong> ${soli?.clienteFinal?.email}</p>
                                    <p><strong>Contacto:</strong> ${soli?.clienteFinal?.contNum} </p>
                                    <hr/>` : ""}

                                    <h4>Información del Vehículo (Provehída por el cliente)</h4>
        
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                                        <tr>
                                            <td style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Lote:</strong> ${
                                                  soli?.lote
                                                }
                                            </td>
                                            <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                            <td style="width:260px;padding:0;vertical-align:top;">
                                                <strong>VIN:</strong> ${
                                                  soli?.vin
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Vehiculo:</strong> ${
                                                  soli?.vehiculoName
                                                }
                                            </td>
                                            <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                            <td style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Buyer:</strong> ${
                                                  soli?.buyer
                                                }
                                            </td>
                                        </tr>
                                        <tr>
									        <td style="width:260px;padding:0;vertical-align:top;">
										        <strong>Gate Pass:</strong> ${soli?.gatePass}
									        </td>
									        <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
									        <td style="width:260px;padding:0;vertical-align:top;">
										        <strong>Dealer Matus?:</strong> ${soli?.dealer ? "SI" : "NO"}
									        </td>
								        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                    <h4>Tipo de pago:</h4> ${soli?.tipoPago}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                    <h4>Ubicacion / Subasta:</h4> ${
                                      soli?.subastaCom
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                    <h4>Destino:</h4> ${soli?.aduanaName}
                                </td>
                            </tr>
                            <tr><td></td></tr>
                            <tr><td></td></tr>
                            <tr>
                                <td style="padding:0;">
                                    <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                                        <tr>
                                            <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Servicio</strong>
                                            </td>
                                            <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                            <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Precio</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                                FLETE:
                                            </td>
                                            <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                            <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                                $ ${soli?.n1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                                GRUA:
                                            </td>
                                            <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                            <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                                $ ${soli?.n2}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                                BL:
                                            </td>
                                            <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                            <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                                $ ${soli?.n3}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                                Costo Adicional:
                                            </td>
                                            <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                            <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                                $ ${soli?.n4}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="right" style="width:260px;padding:0;vertical-align:top;">
                                                <strong>Total:</strong>
                                            </td>
                                            <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                            <td align="left" style="width:260px;padding:0;vertical-align:top;">
                                                $ ${soli?.total}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr><td></td></tr>
                            <tr>
                                <td style="padding:0;">
                                    <h4>Comentario:</h4> ${soli?.comentarios}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:0;">
                                    <strong>Asesor/a:</strong> ${soli?.creador}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="padding:30px;background:#1C2E51;">
                        <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                                <td style="padding:0;width:50%;" align="left">
                                    <p style="color:white;">&reg; Matus International, 2023</p>
                                </td>
                                <td style="padding:0;width:50%;" align="right">
                                    <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                                        <tr>
                                            <td style="padding:0 0 0 10px;width:38px;">
                                            <a href="https://www.facebook.com/matusintl/"><img src="https://portalmatus.com/facebook-white.png" alt="Instagram" width="38" style="height:auto;display:block;border:0;" /></a>
                                            </td>
                                            <td style="padding:0 0 0 10px;width:38px;">
                                                <a href="https://www.instagram.com/matusintl/?hl=en"><img src="https://portalmatus.com/instagram-white.png" alt="Facebook" width="38" style="height:auto;display:block;border:0;" /></a>
                                            </td>
                                            <td style="padding:0 0 0 10px;width:38px;">
                                                <a href="https://www.matusintl.com/"><img src="https://portalmatus.com/web-white.png" alt="Web" width="28" style="height:auto;display:block;border:0;" /></a>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </td>
        </tr>
        </table>
        </body>
        </html>`;

export const NotificacionProgramacionCargaExportation = exportation => {
  return exportation.exportationContainers.map(e => {
    return NotificacionProgramacionCarga(e, exportation);
  }).join('');
}

export const NotificacionProgramacionCarga = (exportationContainer, exportation) => `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="UTF-8">
<meta name="viewport" content="width=device-width,initial-scale=1">
<meta name="x-apple-disable-message-reformatting">
<title>Solicitud de recoleccion</title>
<!--[if mso]>
<noscript>
<xml>
<o:OfficeDocumentSettings>
<o:PixelsPerInch>96</o:PixelsPerInch>
</o:OfficeDocumentSettings>
</xml>
</noscript>
<![endif]-->
<style>
table, td, div, h1, p {font-family: Arial, sans-serif;}
</style>
</head>
<body style="margin:0;padding:0;">
<table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;background:#ffffff;">
<tr>
<td align="center" style="padding:0;">

    <table role="presentation" style="width:602px;border-collapse:collapse;border:1px solid #cccccc;border-spacing:0;text-align:left;">
        <tr>
            <td align="center" style="padding:20px 0 20px 0;background:#1C2E51;">
                <img src="https://portalmatus.com/logoblanco.png" alt="" width="300" style="height:auto;display:block;" />
            </td>
        </tr>
        <tr>
            <td style="padding:36px 30px 42px 30px;">
                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                        <td style="padding:0;">
                        <h1 style="text-align: center;">
                           Notificación de programa de carga
                        </h1>

                            <p>Hola buen dia. <br/> Los siguientes vehiculos serán cargados y esta es la información detalla del cargue:<br/></p>
                          
                            <p><strong>Fecha :</strong> ${moment(
                              exportationContainer?.loadingDate
                            ).format("MM/DD/YYYY")}</p>
                            
                            <h4>Información de logistica:</h4>

                        </td>
                    </tr>
                    <tr>
                        <td style="padding:0;">
                            <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                                <tr>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong>Secuencia:</strong> ${
                                          exportationContainer?.sequence
                                        }
                                    </td>
                                    <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong>Referencia:</strong> ${
                                          exportation?.shipmentName ?? ""
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong>Booking:</strong> ${
                                          exportation?.bookingNum ?? ""
                                        }
                                    </td>
                                    <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong>Naviera:</strong> ${
                                          exportationContainer?.carrier
                                            ?.oceanCarrierName
                                        }
                                    </td>
                                </tr>
                                <tr>
                  <td style="width:260px;padding:0;vertical-align:top;">
                    <strong>Tamaño del contenedor:</strong> ${
                      exportationContainer?.container?.nameContainer
                    }
                  </td>
                  <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                  <td style="width:260px;padding:0;vertical-align:top;">
                    <strong>Destino:</strong> ${
                      exportationContainer?.aduana?.nameAduana
                    }
                  </td>
                </tr>
                              <tr>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong>Trucker:</strong> ${
                                          exportation?.preCarriageBy?.nombre ?? ""
                                        }
                                    </td>
                                    <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong># Contenedor:</strong> ${
                                          exportationContainer?.containerNumber ?? ""
                                        }
                                    </td>
                                </tr>
                              <tr>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong>Capitan:</strong> ${
                                          exportationContainer?.captain
                                        }
                                    </td>
                                    <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong>Cono asignado:</strong> ${
                                          exportationContainer?.colorCono.name
                                        }
                                    </td>
                                </tr>
                              <tr>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                        <strong>Cantidad de vehículo:</strong> ${
                                          exportationContainer?.detalleExport?.length
                                        }
                                    </td>
                                    <td style="width:20px;padding:0;font-size:0;line-height:0;">&nbsp;</td>
                                    <td style="width:260px;padding:0;vertical-align:top;">
                                    <strong>Referencia:</strong> ${
                                      exportation?.shipmentName ?? ""
                                    }
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                    <tr><td></td></tr>

                    <tr><td>
                      <strong> 
                        Información de vehículo que sera cargados
                      </strong>
                      </td></tr>
                    <tr><td></td></tr>
                    <tr>
                        <td style="padding:0;">
                            <table style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                                <tr>
                                    <td  style="width:260px">
                                        <strong>Lote</strong>
                                    </td>
                                    <td  style="width:260px">
                                        <strong>Vin</strong>
                                    </td>
                                    <td  style="width:260px;">
                                    <strong>Vehículo</strong>
                                </td>
                                </tr>
                                ${exportationContainer?.detalleExport?.map((item) => {
                                  return `<tr> 
                                    <td style="width:260px">
                                        ${item?.vehiculo?.lote}
                                    </td>
                                    <td style="width:260px">
                                        ${item?.vehiculo?.vin}
                                    </td>
                                    <td style="width:260px">
                                      ${item?.vehiculo?.vehiculoName}
                                    </td>
                                    </tr>`;
                                }).join('')}
                            </table>
                        </td>
                    </tr>
                    <tr><td></td></tr>
                    <tr>
                        <td>
                            
                        </td>
                    </tr>
                  <tr>
                        <td>
                            
                        </td>
                    </tr>
                  <tr>
                        <td>
                            
                        </td>
                    </tr>
                    <tr>
                        <td style="padding:0;">
                             Gracias por el apoyo, cualquier cambio sera notificado.
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding:30px;background:#1C2E51;">
                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                        <td style="padding:0;width:50%;" align="left">
                            <p style="color:white;">&reg; Matus International, 2023</p>
                        </td>
                        <td style="padding:0;width:50%;" align="right">
                            <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                                <tr>
                                    <td style="padding:0 0 0 10px;width:38px;">
                                    <a href="https://www.facebook.com/matusintl/"><img src="https://portalmatus.com/facebook-white.png" alt="Instagram" width="38" style="height:auto;display:block;border:0;" /></a>
                                    </td>
                                    <td style="padding:0 0 0 10px;width:38px;">
                                        <a href="https://www.instagram.com/matusintl/?hl=en"><img src="https://portalmatus.com/instagram-white.png" alt="Facebook" width="38" style="height:auto;display:block;border:0;" /></a>
                                    </td>
                                    <td style="padding:0 0 0 10px;width:38px;">
                                        <a href="https://www.matusintl.com/"><img src="https://portalmatus.com/web-white.png" alt="Web" width="28" style="height:auto;display:block;border:0;" /></a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</td>
</tr>
</table>
</body>
</html>`;
