import React, {useState} from "react";
import Sidebar from "../../Componentes/Sidebar";
import HeaderComponent from "../../Componentes/Header";
import {  Layout, theme} from "antd";
import { Outlet } from "react-router-dom";
import "./index.css";
import {useMainContext} from "../../contexts/MainContext";

const { Content } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {token: { colorBgContainer }} = theme.useToken();
  const { clientesPreferenciales } = useMainContext();

  return (
    <Layout style={{height: '100% !important'}} className="simi-main-layout">
      <Sidebar collapsed={collapsed} clientesPreferenciales={clientesPreferenciales} />
      <Layout>
        <HeaderComponent onCollapsed={() => setCollapsed(!collapsed)} />
        <Content className="simi-main-content">
          <div className="simi-main-content--panel" style={{background: colorBgContainer}}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
