import menuItem from "../../utils/menu";
import {Menu} from "antd";
import React, {useEffect, useState} from "react";
import {
  BankOutlined,
  BookFilled, CarFilled, CarOutlined,
  CodepenOutlined, ContainerFilled,
  DatabaseOutlined, DollarOutlined,
  EditFilled, ExportOutlined, FileExcelFilled, GoldenFilled,
  HomeFilled, ReconciliationFilled, ShopFilled,
  VerticalAlignBottomOutlined
} from "@ant-design/icons";
import {AdminPanelSettings} from "@mui/icons-material";
import {getCountriesId, hasRol, isAdmin, validarPermisos} from "../../Servicios/AuthService";
import {permisos} from "../../utils/permisos";
import roles from "../../utils/roles";

const countriesId = getCountriesId();

const filtroSalvador = countriesId.indexOf('1') !== -1 || countriesId === '0';
const filtroGuatemala = countriesId.indexOf('2') !== -1 || countriesId === '0';
const filtroCostaRica = countriesId.indexOf('3') !== -1 || countriesId === '0';
const filtroNicaragua = countriesId.indexOf('4') !== -1 || countriesId === '0';
const filtroHonduras = countriesId.indexOf('5') !== -1 || countriesId === '0';
const filtroPanama = countriesId.indexOf('8') !== -1 || countriesId === '0';

const arrayMenu = [
  {
    label: "Inicio",
    icon: <HomeFilled />,
    type: "menu",
    path: "/home",
    // EN CASO DE NO TENER UN PERMISO ASIGANDO PERO QUIERES QUE SE MUESTRE PARA TODO EN GENERAL SOLO AGREGAR PERMISSION : 1
    // ESTO FUNCIONA PARA MENU PADRES E HIJOS
    permission: true
  },
  {
    label: "Admin",
    icon: <AdminPanelSettings />,
    type: "sub",
    permission: isAdmin(),
    children: [
      {
        label: "Usuarios",
        type: "menu",
        path: "/admin/users",
        permission: isAdmin(),
      },
      {
        label: "Roles",
        type: "menu",
        path: "/admin/roles",
        permission: isAdmin(),
      },
      {
        label: "Permisos",
        type: "menu",
        path: "/admin/permissions",
        permission: isAdmin(),
      },
    ]
  },
  {
    label: "Catalogos",
    icon: <BookFilled />,
    type: "sub",
    permission: isAdmin() || validarPermisos(permisos.CATALOGO_TIPETITLES),
    children: [
      {
        label: "Puerto de Carga/Descarga",
        path: "/catalogs/portCode",
        type: "menu",
        permission: isAdmin(),
      },
      {
        label: "Títulos de Embarque",
        path: "/catalogs/title",
        type: "menu",
        permission: isAdmin() || validarPermisos(permisos.CATALOGO_TIPETITLES),
      },
      {
        label: "Carrier",
        path: "/catalogs/carrier",
        type: "menu",
        permission: isAdmin(),
      },
    ]
  },
  {
    label: "Carteras de clientes",
    icon: <BookFilled />,
    type: "sub",
    permission:
      validarPermisos(permisos.VER_CLIENTE_SALVADOR) ||
      validarPermisos(permisos.VER_CLIENTE_GUATEMALA) ||
      validarPermisos(permisos.VER_CLIENTE_CR) ||
      validarPermisos(permisos.VER_CLIENTE) ||
      validarPermisos(permisos.VER_CLIENTE_HONDURAS),
    children: [
      {
        label: "El Salvador",
        type: "menu",
        path: "/portfolio/country/1",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_SALVADOR) && filtroSalvador,
      },
      {
        label: "Guatemala",
        type: "menu",
        path: "/portfolio/country/2",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_GUATEMALA) && filtroGuatemala,
      },
      {
        label: "Costa Rica",
        type: "menu",
        path: "/portfolio/country/3",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_CR) && filtroCostaRica,
      },
      {
        label: "Nicaragua",
        type: "menu",
        path: "/portfolio/country/4",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE) && filtroNicaragua,
      },
      {
        label: "Honduras",
        type: "menu",
        path: "/portfolio/country/5",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_HONDURAS) && filtroHonduras,
      },
      {
        label: "Panama",
        type: "menu",
        path: "/portfolio/country/8",
        permission: validarPermisos(permisos.VER_CLIENTE, permisos.VER_CLIENTE_PANAMA) && filtroPanama,
      },
      {
        label: "Actividad de Clientes",
        type: "menu",
        path: "/clientes/actividad",
        permission: validarPermisos(permisos.VER_CLIENTE),
      },
    ],
  },
  {
    label: "Importadoras",
    icon: <CodepenOutlined />,
    type: "menu",
    path: "/importer",
    permission: validarPermisos(permisos.AGREGAR_IMPORTADORA)
  },
  {
    label: "Aduanas",
    icon: <DatabaseOutlined />,
    type: "menu",
    path: "/customs",
    permission: validarPermisos(permisos.AGREGAR_ADUANA)
  },
  {
    label: "Solicitudes de clientes",
    icon: <EditFilled />,
    type: "menu",
    path: "/customerRequest",
    permission: 0
  },
  {
    label: "Solicitudes",
    icon: <EditFilled />,
    type: "sub",
    permission:
      validarPermisos(permisos.VER_SOLICITUDES) ||
      validarPermisos(permisos.AGREGAR_SOLICITUDES),
    children: [
      {
        label: "Lista de solicitudes",
        path: "/requests/list",
        type: "menu",
        permission: validarPermisos(permisos.VER_SOLICITUDES),
      },
      {
        label: "Nueva solicitud",
        path: "/request",
        type: "menu",
        permission: validarPermisos(permisos.AGREGAR_SOLICITUDES),
      },
      {
        label: "Multiples solicitudes",
        path: "/multiplesSolicitudes",
        type: "menu",
        permission:  validarPermisos(permisos.AGREGAR_SOLICITUDES),
      },
      {
        label: "Historial de solicitudes",
        path: "/requests/list/history",
        type: "menu",
        permission: validarPermisos(permisos.VER_SOLICITUDES_HISTORIAL),
      },
      {
        label: "Reporte de solicitudes",
        path: "/requests/list/report",
        type: "menu",
        permission: validarPermisos(permisos.VER_SOLICITUDES_REPORTE),
      },
      //{
      //label: "Estados por Semana",
      //path: "/solicitud/estado-por-semana",
      //permission: validarPermisos(permisos.VER_SOLICITUDES_REPORTE),
      //}
    ],
  },
  {
    label: "Solicitudes por Ventas",
    icon: <VerticalAlignBottomOutlined />,
    type: "sub",
    permission: validarPermisos(permisos.AGREGAR_COORDINACION),
    children: [
      {
        label: "Florida",
        type: "menu",
        path: "/incomingRequests/1",
        permission: validarPermisos(permisos.AGREGAR_COORDINACION),
      },
      {
        label: "California",
        type: "menu",
        path: "/incomingRequests/2",
        permission: validarPermisos(permisos.AGREGAR_COORDINACION),
      },
      {
        label: "Pennsylvania",
        type: "menu",
        path: "/incomingRequests/3",
        permission: validarPermisos(permisos.AGREGAR_COORDINACION),
      },
    ],
  },
  {
    label: "Ingreso Directo",
    icon: <BankOutlined />,
    type: "menu",
    path: "/multipleCoordinations",
    // permission: validarPermisos(permisos.AGREGAR_TRANSPORTE)
    permission:  validarPermisos(permisos.AGREGAR_COORDINACION) || validarPermisos(permisos.INGRESO_OFICINA),
  },
  {
    label: "Domestic",
    icon: <ContainerFilled />,
    type: "sub",
    permission:
      validarPermisos(permisos.VER_COORDINACION_FL) ||
      validarPermisos(permisos.VER_COORDINACION_CA) ||
      validarPermisos(permisos.VER_COORDINACION_PA),
    children: [
      {
        label: "Florida",
        type: "menu",
        path: "/coordinations/country/1",
        permission: validarPermisos(permisos.VER_COORDINACION_FL),
      },
      {
        label: "California",
        type: "menu",
        path: "/coordinations/country/2",
        permission: validarPermisos(permisos.VER_COORDINACION_CA),
      },
      {
        label: "Pennsylvania",
        type: "menu",
        path: "/coordinations/country/3",
        permission: validarPermisos(permisos.VER_COORDINACION_PA),
      },
      {
        label: "Recibidos sin Título",
        type: "menu",
        path: "/coordinations/recibidos-sin-titulo",
        permission: validarPermisos(permisos.VER_COORDINACION_FL, permisos.VER_COORDINACION_CA, permisos.VER_COORDINACION_PA),
      },
      {
        label: "Reporte de Storage",
        type: "menu",
        path: "/storageReport",
        permission: validarPermisos(permisos.VER_REPORTE_STORAGE),
      },
      {
        label: "Recepción de Transporte",
        type: "menu",
        path: "/coordinations/citas",
        permission: true,
      },
    ],
  },
  {
    label: "Contabilidad",
    icon: <DollarOutlined />,
    type: "sub",
    permission:
      validarPermisos(permisos.PAGOS_TRANSPORTISTAS),
    children: [
      {
        label: "Pagos Transportistas",
        type: "menu",
        path: "/payments/truks",
        permission: validarPermisos(permisos.PAGOS_TRANSPORTISTAS),
      },
    ],
  },
  {
    label: "Agregar transportistas",
    icon: <CarFilled />,
    type: "menu",
    path: "/addCarrier",
    permission: validarPermisos(permisos.AGREGAR_TRANSPORTE)
  },
  {
    label: "Agregar Subasta/Ubicacion",
    icon: <GoldenFilled />,
    type: "menu",
    path: "/addAuction",
    permission: validarPermisos(permisos.AGREGAR_SUBASTAS)
  },
  {
    label: "Recibo de vehiculos",
    icon: <ReconciliationFilled />,
    type: "sub",
    permission:
      validarPermisos(permisos.RECEIVING_FL) ||
      validarPermisos(permisos.RECEIVING_CA) ||
      validarPermisos(permisos.RECIBIDOS_HOY_FL) ||
      validarPermisos(permisos.RECIBIDOS_HOY_CA) ||
      validarPermisos(permisos.RECIBO_DE_VEHICULOS) ||
      validarPermisos(permisos.VER_RECIBIDOS_EN_CA),
    children: [
      {
        label: "Ingreso de Vehículos",
        type: "menu",
        path: "/vehicleReceipts",
        permission: validarPermisos(permisos.RECEIVING_FL, permisos.RECEIVING_CA),
      },
      {
        label: "Received today",
        type: "menu",
        path: "/receivedtoday",
        permission: validarPermisos(permisos.RECIBIDOS_HOY_FL, permisos.RECIBIDOS_HOY_CA, permisos.VER_RECIBIDOS_EN_CA),
      },
      {
        label: "Inventario",
        type: "menu",
        path: "/vehiculo/inventario",
        permission: true,
      }
    ],
  },
  {
    label: "Nuevo Vehiculo",
    icon: <CarOutlined />,
    type: "menu",
    path: "/newCar",
    permission: 0
  },
  {
    label: "Warehouse Receipt",
    icon: <ShopFilled />,
    type: "sub",
    path: "/warehouseReceipt ",
    permission:
      validarPermisos(permisos.WAREHOUSE_CA) ||
      validarPermisos(permisos.WAREHOUSE_FL),
    children: [
      {
        label: "Florida",
        type: "menu",
        path: "/warehouseReceipt/1",
        permission: validarPermisos(permisos.WAREHOUSE_FL),
      },
      {
        label: "California",
        type: "menu",
        path: "/warehouseReceipt/2",
        permission: validarPermisos(permisos.WAREHOUSE_CA),
      },
      {
        label: "Pennsylvania",
        type: "menu",
        path: "/warehouseReceipt/3",
        permission: validarPermisos(permisos.WAREHOUSE_PA),
      },
    ],
  },
  {
    label: "Planif. de Contenedores",
    icon: <ExportOutlined />,
    type: "sub",
    permission: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_VER),
    children: [
      {
        label: 'Vehículos',
        type: "menu",
        path: "/exportation/container",
        permission: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_VER),
      },
      {
        label: 'Carga General',
        type: "menu",
        path: "/exportation/container/carga-general",
        permission: validarPermisos(permisos.PLANIFICACION_CONTENEDOR_VER),
      },
    ]
  },
  {
    label: "Exportaciones",
    icon: <ExportOutlined />,
    type: "sub",
    permission: validarPermisos(
      permisos.EXPORTATIONS,
      permisos.EXPORTACION_EN_PROCESO_VER,
      permisos.EXPORTACION_VALIDACION_VER,
      permisos.NOTIFICACION_EMBARQUE_VER,
      permisos.REPORTES_EMBARQUE_VER,
      permisos.EXPORTACION_COMPLETADO_VER
    ),
    children: [
      {
        label: "Pendientes",
        type: "menu",
        path: "/exportation/list",
        permission: validarPermisos(permisos.EXPORTATIONS),
      },
      {
        label: "Validación",
        type: "menu",
        path: "/exportation/naviera/list",
        permission: validarPermisos(permisos.EXPORTACION_VALIDACION_VER),
      },
      {
        label: "Lista de Exportaciones",
        type: "menu",
        path: "/exportation/completados",
        permission: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER),
      },
      {
        label: "Carga General",
        type: "menu",
        path: "/exportation/carga-general",
        permission: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER),
      },
      {
        label: "Florida",
        type: "menu",
        path: "/exportation/completados/1",
        permission: validarPermisos(permisos.VER_EMBARQUES_FL),
      },
      {
        label: "California",
        type: "menu",
        path: "/exportation/completados/2",
        permission: validarPermisos(permisos.VER_EMBARQUES_CA),
      },
      {
        label: "Pennsylvania",
        type: "menu",
        path: "/exportation/completados/3",
        permission: validarPermisos(permisos.VER_EMBARQUES_PA),
      },
      {
        label: "Reporte de Embarques",
        type: "menu",
        path: "/exportation/reporte/embarque",
        permission: validarPermisos(permisos.REPORTES_EMBARQUE_VER),
      },
    ],
  },
  {
    label: "Notif. de Embarque",
    icon: <ExportOutlined />,
    type: "sub",
    path: "/exportation",
    permission: hasRol(roles.ADMIN, roles.ATENCION_CLIENTE),
    children: [
      {
        label: "Florida",
        type: "menu",
        path: "/exportation/notif-embarque/1",
        permission: validarPermisos(permisos.NOTIFICACION_EMBARQUE_FL),
      },
      {
        label: "California",
        type: "menu",
        path: "/exportation/notif-embarque/2",
        permission: validarPermisos(permisos.NOTIFICACION_EMBARQUE_CA),
      },
      {
        label: "Pennsylvania",
        type: "menu",
        path: "/exportation/notif-embarque/3",
        permission: validarPermisos(permisos.NOTIFICACION_EMBARQUE_PA),
      },
      {
        label: "Reporte de Embarque",
        type: "menu",
        path: "/exportation/reporte/embarque",
        permission: validarPermisos(permisos.REPORTES_EMBARQUE_VER),
      },
    ]
  },
  //Cotizacion
  {
    label: "Cotizacion",
    icon: <FileExcelFilled />,
    type: "menu",
    path: "/quotation/list",
    permission: validarPermisos(permisos.QUOTATION)
  },
  {
    label: "Reportes",
    icon: <FileExcelFilled />,
    type: "sub",
    path: "/reports",
    permission: 0,
    //validarPermisos(permisos.REPORTE_COMENT_CLIENTES) ||
    //validarPermisos(permisos.VER_TITULOS_PENDIENTES),
    children: [
      {
        label: "Reporte de comentarios",
        path: "/reportComments",
        type: "menu",
        permission: validarPermisos(permisos.REPORTE_COMENT_CLIENTES),
      },
      {
        label: "Reporte Titulos Pendientes",
        path: "/pendingTitles",
        type: "menu",
        permission: validarPermisos(permisos.VER_TITULOS_PENDIENTES),
      },
    ]
  },
];

const SimiMenu = ({ clientesPreferenciales }) => {
  const [menuItems, setMenuItems] = useState(arrayMenu);

  useEffect(() => {
    if (clientesPreferenciales && clientesPreferenciales.length) {
      arrayMenu.forEach(menu => {
        if (menu.label === 'Exportaciones') {
          if (!menu.children.some(x => x.label === "Clientes")) {
            const item = {
              label: "Clientes",
              type: "sub",
              permission: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER),
              children: clientesPreferenciales.map(c => ({
                type: "menu",
                label: c.nombreCompleto?.toUpperCase(),
                path: `/exportation/clientes/${c.id}`,
                permission: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER),
              }))
            };

            menu.children.splice(4, 0, item);
          }
        }
      });

      setMenuItems([...arrayMenu]);
    }
  }, [clientesPreferenciales]);

  return(
    <>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={menuItem(menuItems)}
      />
    </>
  );
}

export default SimiMenu;
