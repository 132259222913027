import React from "react";
import logoblanco from '../image/logoblanco.png';
import Sider from "antd/es/layout/Sider";
import SimiMenu from "./SimiMenu/SimiMenu";


const Sidebar = ({ collapsed, clientesPreferenciales }) => {
  return (
    <Sider breakpoint="lg" collapsedWidth="0" collapsible trigger={null} collapsed={collapsed} width={250}>
      <img
        src={logoblanco}
        width="160"
        height="59"
        style={{margin: 10}}
      />
      <SimiMenu clientesPreferenciales={clientesPreferenciales} />
    </Sider>
  );
};

export default Sidebar;
