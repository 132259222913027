import Spinner from "../../../shared/spinner";
import {Button, DatePicker, Form, Input, InputNumber, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import {useEffect, useState} from "react";
import {getWareHouse} from "../../../Servicios/ExportationServices";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  addExportationContainer, asignarDetalleExport, asignarDetalleExportClientePreferencial, removerDetalleExport,
  updateExportationContainer
} from "../../../Servicios/ExportationContainerService";
import {errorMessage, successMessage} from "../../../utils/toast-message";
import dayjs from "dayjs";
import TextArea from "antd/lib/input/TextArea";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import {useExportationContext} from "../../../contexts/ExportationContext";
import selectFilterOptions from "../../../shared/utils/select-filter-options";
import AsignarWarehouseTable from "./AsignarWarehouseTable";
import AsignarVehiculoTable from "./AsignarVehiculoTable";
import {editCar, getVehicleClientePreferencial, getVehicleInput} from "../../../Servicios/VehicleService";

const ExportationContainerForm = ({ externalLoading, cliente, idOrigen, idCarrier, idAduana, exportationContainer,
                                    editable, onCancel, onSubmit, onRefreshExportationContainer }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);

  const { loadingExportation, colors, aduanas, carriers, containers } = useExportationContext();

  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [selectedVehiculos, setSelectedVehiculos] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);

  useEffect(() => {
    setLoading(true);
    init().then(() => {
      setLoading(false);
    });
  }, [exportationContainer, idAduana, idCarrier]);

  useEffect(() => {
    if (idAduana) {
      findWarehousesByAduana(idAduana).then();
    }
  }, [idAduana]);

  useEffect(() => {
    if (cliente) {
      getVehicles().then();
    } else {
      setVehiculos([]);
    }
  }, [cliente]);

  const getVehicles = async () => {
    setLoading(true);
    const data = await getVehicleClientePreferencial(idOrigen);
    setLoading(false);
    setVehiculos(data);
  }

  const init = async () => {
    setLoading(true);

    form.setFieldsValue({
      sequence: exportationContainer ? exportationContainer.sequence : '',
      idContainer: exportationContainer ? exportationContainer.idContainer : '',
      idCarrier: idCarrier ? idCarrier : exportationContainer ? exportationContainer.idCarrier : '',
      idAduana: idAduana ? idAduana : exportationContainer ? exportationContainer.idAduana : '',
      idColor: exportationContainer ? exportationContainer.idColor : '',
      loadingDate: exportationContainer ? dayjs(exportationContainer.loadingDate) : dayjs(new Date()),
      letter: exportationContainer ? exportationContainer.letter : '',
      sealNumber: exportationContainer ? exportationContainer.sealNumber : '',
      containerNumber: exportationContainer ? exportationContainer.containerNumber : '',
      captain: exportationContainer ? exportationContainer.captain : '',
      loadedNotes: exportationContainer ? exportationContainer.loadedNotes : '**NO QUITAR LLANTAS**',
      containerValue: exportationContainer ? exportationContainer.containerValue : '',
    });

    if (exportationContainer) {
      if (cliente) {
        setSelectedVehiculos(exportationContainer.detalleExport.map(x => ({
          ...x,
          ...x.werehouse.vehiculo
        })));
      } else {
        setSelectedWarehouses(exportationContainer.detalleExport.map(x => ({
          ...x.werehouse,
          detalleExportId: x.detalleExportId,
          blDescription: x.blDescription,
          blWeight: x.blWeight ?? x?.vehiculo?.weight,
          blQuantity: x.blQuantity ?? 1
        })));

        findWarehousesByAduana(exportationContainer.idAduana).then();
      }
    }
  }

  const findWarehousesByAduana = async (aduanaId) => {
    setLoading(true);
    const warehouses = await getWareHouse(aduanaId, idOrigen, true);
    setLoading(false);

    setWarehouses(warehouses);
  }

  const handleSubmit = async (values) => {
    try {
      const inputData = {
        ...values,
        idCliente: cliente?.id,
        idOrigen: idOrigen,
        loadingDate: moment(values.loadingDate.$d).format("YYYY-MM-DDTHH:mm:ss"),
        werehouses: selectedWarehouses.map(e => e.werehouseId),
        vehiculos: selectedVehiculos.map(e => e.vehiculoId)
      }

      const input = exportationContainer
        ? { ...exportationContainer, ...inputData }
        : { ...inputData };

      setLoading(true);
      const data = exportationContainer
        ? await updateExportationContainer(exportationContainer.idExportationContainer, input)
        : await addExportationContainer(input);
      setLoading(false);

      if (data) {
        successMessage('Contenedor asignado con exito');
        form.setFieldValue('sequence', '');
        form.setFieldValue('idColor', '');
        form.setFieldValue('letter', '');
        form.setFieldValue('sealNumber', '');
        form.setFieldValue('containerNumber', '');
        form.setFieldValue('captain', '');
        form.setFieldValue('loadedNotes', '**NO QUITAR LLANTAS**');
        form.setFieldValue('createdDate', dayjs(new Date()));
        form.setFieldValue('containerValue', '');
        setSelectedWarehouses([]);
        if (onSubmit) onSubmit(data);
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  }

  const handleCancel = () => {
    form.setFieldValue('sequence', '');
    form.setFieldValue('idColor', '');
    form.setFieldValue('letter', '');
    form.setFieldValue('sealNumber', '');
    form.setFieldValue('containerNumber', '');
    form.setFieldValue('captain', '');
    form.setFieldValue('createdDate', dayjs(new Date()));
    form.setFieldValue('loadingDate', dayjs(new Date()));
    form.setFieldValue('loadedNotes', '**NO QUITAR LLANTAS**');
    form.setFieldValue('containerValue', '');
    setSelectedWarehouses([]);

    if (onCancel) onCancel();
  }

  const handleSelectWerehouse = async (werehouseId) => {
    if (exportationContainer) {
      try {
        setLoading(true);
        await asignarDetalleExport(exportationContainer.idExportationContainer, { data: werehouseId });
        onRefreshExportationContainer();
        setLoading(false);
        successMessage('Nuevo Warehouse agregado a la planificación');
      } catch (ex) {
        setLoading(false);
        errorMessage(ex.toString());
      }
    } else {
      const werehouse = warehouses.find(e => e.werehouseId === werehouseId);

      if (!selectedWarehouses.some(e => e.werehouseId === werehouse.werehouseId)) {
        setSelectedWarehouses([...selectedWarehouses, werehouse]);
      }
    }
  }

  const handleSelectVehiculo = async (vehiculoId) => {
    if (exportationContainer) {
      try {
        const input = {
          idVehiculo: vehiculoId,
          idCliente: exportationContainer.idCliente
        };

        setLoading(true);
        await asignarDetalleExportClientePreferencial(exportationContainer.idExportationContainer, input);
        onRefreshExportationContainer();
        setLoading(false);
        successMessage('Nuevo Warehouse agregado a la planificación');
      } catch (ex) {
        setLoading(false);
        errorMessage(ex.toString());
      }
    } else {
      const vehiculo = vehiculos.find(e => e.vehiculoId === vehiculoId);

      if (!selectedVehiculos.some(e => e.vehiculoId === vehiculo.vehiculoId)) {
        setSelectedVehiculos([...selectedVehiculos, vehiculo]);
      }
    }
  }

  const handleDeleteWerehouse = async (warehouse) => {
    if (exportationContainer) {
      try {
        setLoading(true);
        await removerDetalleExport(exportationContainer.idExportationContainer, { data: warehouse.werehouseId });
        onRefreshExportationContainer();
        setLoading(false);
        successMessage('El Warehouse ha sido eliminado');

        if (exportationContainer.cliente) {
          getVehicles().then();
        }
      } catch (ex) {
        setLoading(false);
        errorMessage(ex.toString());
      }
    } else {
      const werehouses = selectedWarehouses.filter(e => e.werehouseId !== warehouse.werehouseId);
      setSelectedWarehouses([...werehouses]);
    }
  }

  const handleDeleteVehiculo = async (vehiculo) => {
    if (exportationContainer) {
      const existingDetalleExport = exportationContainer
        .detalleExport
        .find(e => e?.werehouse?.vehiculoId === vehiculo.vehiculoId);

      if (existingDetalleExport) {
        try {
          setLoading(true);
          await removerDetalleExport(exportationContainer.idExportationContainer, { data: existingDetalleExport.idWR });
          onRefreshExportationContainer();
          setLoading(false);
          successMessage('El Warehouse ha sido eliminado');
        } catch (ex) {
          setLoading(false);
          errorMessage(ex.toString());
        }
      }
    } else {
      const vehiculos = selectedVehiculos.filter(e => e.vehiculoId !== vehiculo.vehiculoId);
      setSelectedVehiculos([...vehiculos]);
    }
  }

  const handleUpdateVehicleVin = async (vehicle) => {
    try {
      const input = getVehicleInput(vehicle);

      setLoading(true);
      await editCar(vehicle.vehiculoId, input);
      setLoading(false);

      const list = [...selectedVehiculos];
      list.forEach(e => {
        if (e.vehiculoId === vehicle.vehiculoId) {
          e.vin = vehicle.vin;
        }
      });
      setSelectedVehiculos([...list]);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleSelectAduana = aduanaId => {
    setSelectedWarehouses([]);
    findWarehousesByAduana(aduanaId).then();
  }

  return (
    <Spinner loading={loading || loadingExportation || externalLoading}>
      <Form form={form}
            layout={'vertical'}
            onFinish={handleSubmit}>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="sequence"
                           label="Secuencia"
                           rules={[{required: true, message: "La secuencia es requerida"}]}>
                  <InputNumber className="w-100"/>
                </Form.Item>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="loadingDate"
                           label="Fecha de Carga"
                           rules={[{required: true, message: "La fecha es requerido"}]}>
                  <DatePicker className="w-100"/>
                </Form.Item>
              </div>
            </div>

            <Form.Item label={'Seleccionar Aduana'}
                       rules={[{required: true, message: "La Aduana es requerido"}]}
                       name={'idAduana'}>
              <Select showSearch
                      disabled={!editable}
                      onChange={handleSelectAduana}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                      options={aduanas.map(e => ({
                        label: `${e.nameAduana} ${e.countryName}`, value: e.id,
                      }))}/>
            </Form.Item>

            <Form.Item label={'Contenedor'}
                       rules={[{required: true, message: "El contenedor es requerido"}]}
                       name={'idContainer'}>
              <Select disabled={!editable} options={containers.map(e => ({label: e.nameContainer, value: e.containerId}))}/>
            </Form.Item>

            <Form.Item label={'Nota de carga'}
                       name={'loadedNotes'}>
              <TextArea />
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <Form.Item label={'Carrier'}
                       rules={[{required: true, message: "El carrier es requerido"}]}
                       name={'idCarrier'}>
              <Select showSearch
                      disabled={!editable}
                      filterOption={(input, option) => {
                        return (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }}
                      options={carriers.map(e => ({label: e.oceanCarrierName, value: e.carrierId}))}/>
            </Form.Item>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Item label={'Color de Cono'}
                           rules={[{required: true, message: "El color de cono es requerido"}]}
                           name={'idColor'}>
                  <Select disabled={!editable}
                          showSearch={true}
                          filterOption={selectFilterOptions}
                          options={colors.map(e => ({ label: e.name, value: e.colorId }))} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <Form.Item name="letter"
                           label="Letra">
                  <Input className="w-100"/>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="containerNumber"
                           label="# de Contenedor">
                  <Input className="w-100"/>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="sealNumber"
                           label="# de Sello">
                  <Input className="w-100"/>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="captain"
                           label="Capitán">
                  <Input className="w-100"/>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <Form.Item name="containerValue"
                           label="$ Valor del Contenedor">
                  <InputNumber disabled={true} className="w-100"/>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <ShowWhen show={!cliente}>
              <AsignarWarehouseTable warehouses={warehouses}
                                     selectedWarehouses={selectedWarehouses}
                                     exportationContainer={exportationContainer}
                                     onSelectWerehouse={handleSelectWerehouse}
                                     onDeleteWarehouse={handleDeleteWerehouse} />
            </ShowWhen>
            <ShowWhen show={cliente}>
              <AsignarVehiculoTable cliente={cliente}
                                    vehiculos={vehiculos}
                                    selectedVehiculos={selectedVehiculos}
                                    exportationContainer={exportationContainer}
                                    onSelectVehiculo={handleSelectVehiculo}
                                    onDeleteVehiculo={handleDeleteVehiculo}
                                    onUpdateVehicleVin={handleUpdateVehicleVin} />
            </ShowWhen>
          </div>
          <div className="col-12 text-end mt-3">
            <Button htmlType={'button'} type={'default'} className="me-2" onClick={handleCancel}>
              <FontAwesomeIcon icon={faTimes} className="me-2"/> Cancelar
            </Button>
            <Button htmlType={'submit'} type={'primary'}><FontAwesomeIcon icon={faSave}
                                                                          className="me-2"/> Guardar</Button>
          </div>
        </div>
      </Form>
    </Spinner>
  );
}

export default ExportationContainerForm;
