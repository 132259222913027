import {ApiService} from "./ApiService";
import {server} from "../environments/environment";

export const getTransporteCitas = async (origenId, year, month) => {
  const params = {
    origenId: origenId,
    year: year,
    month: month,
  }

  const queryParams = new URLSearchParams(params);
  const response = await ApiService.get(`${server}transporteCita?${queryParams.toString()}`);
  const data = await response.json();

  if (data.status) {
    return data.list;
  } else {
    throw new Error(data.message);
  }
}
