import {Button, Card, Col, Dropdown, Form, InputNumber, Layout, Menu, Modal, Row, Tag, theme} from "antd";
import {useEffect, useState} from "react";
import {errorMessage, successMessage} from "../../../utils/toast-message";
import ExportationContainerForm from "./ExportationContainerForm";
import Spinner from "../../../shared/spinner";
import localStorageFilter from "../../../shared/utils/local-storage-filter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faFilePdf, faPlus} from "@fortawesome/free-solid-svg-icons";
import ExportationContainerTable from "./ExportationContainerTable";
import {
  getExportationContainerById,
  getExportationContainerList,
  getPlanificacionContenedorPdf,
  updateExportationContainerSequence
} from "../../../Servicios/ExportationContainerService";
import useCurrentUser from "../../../Hooks/UserHook";
import {getDateForMilliseconds} from "../../../utils/date.helper";
import moment from "moment/moment";
import {getListDateLoadingProgram} from "../../../Servicios/ExportationServices";
import {validarPermisos} from "../../../Servicios/AuthService";
import {permisos} from "../../../utils/permisos";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import InputSearch from "../../../Componentes/InputSearch/InputSearch";
import Sider from "antd/es/layout/Sider";
import {ExportationProvider} from "../../../contexts/ExportationContext";
import PdfReport from "../../../utils/pdfReport";
import {MenuProps} from "antd";
import {useMainContext} from "../../../contexts/MainContext";

class Filters {
  currentPage: number;
  pageCount: number;
  loadingDate: Date | undefined;
  lote: string;
  executeSearch: boolean;
}

const ExportationContainerPage = () => {
  const currentUser = useCurrentUser();
  const [form] = Form.useForm();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [showReporte, setShowReporte] = useState(false);
  const [showFormSequence, setShowFormSequence] = useState(false);

  const [cliente, setCliente] = useState(undefined);
  const [reporte, setReporte] = useState("");
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [loadingProgramDates, setLoadingProgramDates] = useState([]);
  const [exportationContainers, setExportationContainers] = useState([]);
  const [updateExportationContainer, setUpdateExportationContainer] = useState(undefined);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [filters, setFilters] = useState({
    currentPage: localStorageFilter('exportationContainer:filter', 'currentPage') ?? 1,
    pageCount: localStorageFilter('exportationContainer:filter', 'pageCount') ?? 10,
    loadingDate: localStorageFilter('exportationContainer:filter', 'loadingDate', true),
    lote: localStorageFilter('exportationContainer:filter', 'lote') ?? "",
    executeSearch: localStorageFilter('exportationContainer:filter', 'executeSearch') ?? false,
  });

  const { loadingMain, clientesPreferenciales } = useMainContext();

  useEffect(() => {
    setItems(clientesPreferenciales.map(e => ({
      key: e.id,
      label: `${e.nombreCompleto} - ${e.importadoraName}`.toUpperCase()
    })));
  }, [clientesPreferenciales]);

  useEffect(() => {
    const localFilters = localStorageFilter();

    if (JSON.stringify(filters) !== JSON.stringify(localFilters)) {
      localStorage.setItem('exportationContainer:filter', JSON.stringify(filters));
    }

    if (filters.executeSearch) {
      init(filters).then();
    }
  }, [filters]);

  useEffect(() => {
    if (!currentUser) return;
    fetchLoadingProgramDates().then();
  }, [currentUser]);

  const fetchLoadingProgramDates = async () => {
    setLoading(true);
    const list = await getListDateLoadingProgram(currentUser.idubc);
    setLoading(false);
    setLoadingProgramDates(list);

    if (filters.loadingDate) {
      setSelectedMenu(`${filters.loadingDate.toISOString().substring(0, 10)}T00:00:00`);
      setFilters({
        ...filters,
        executeSearch: true
      });
    } else {
      setSelectedMenu(list[0].date);
      setFilters({
        ...filters,
        executeSearch: true,
        loadingDate: new Date(list[0].date)
      });
    }
  };

  const init = async (filters: Filters) => {
    try {
      setLoading(true);
      const data = await getExportationContainerList(
        filters.currentPage,
        filters.pageCount,
        filters.loadingDate ? filters.loadingDate.getTime() : 0,
        currentUser?.idubc ?? 0,
        filters.lote,
        false
      );
      setLoading(false);

      setTotalElements(data.totalElements);
      setExportationContainers(data.list.map((e, index) => {
        return {
          key: index,
          ...e,
        }
      }));

      if (filters.lote !== "" && data.list.length > 0) {
        setSelectedMenu(`${new Date(data.list[0].loadingDate).toISOString().substring(0, 10)}T00:00:00`);
        setFilters({
          ...filters,
          executeSearch: false,
          loadingDate: new Date(data.list[0].loadingDate)
        });
      } else {
        setFilters({
          ...filters,
          executeSearch: false,
        });
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  }

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      currentPage: page,
      pageCount: pageSize,
      executeSearch: true,
    });
  };

  const handleAddExportationContainer = () => {
    setShowForm(true);
  }

  const handleMenuClick: MenuProps['onClick'] = (event) => {
    const cliente = clientesPreferenciales.find(e => +e.id === +event.key);
    setCliente(cliente);
    handleAddExportationContainer();
  };

  const handleUpdateExportationContainer = (element) => {
    // validar clientes preferenciales
    if (element.cliente) {
      setCliente(element.cliente);
    }

    setShowForm(true);
    setUpdateExportationContainer(element);
  }

  const handleRefreshExportationContainer = async () => {
    setLoading(true);
    const exportationContainer = await getExportationContainerById(updateExportationContainer.idExportationContainer);
    setUpdateExportationContainer({ ...exportationContainer });
    setLoading(false);
  }

  const handleUpdateExportationContainerSequence = (element) => {
    setShowFormSequence(true);
    setUpdateExportationContainer(element);

    form.setFieldsValue('sequence', element.sequence);
  }

  const handleSelectDate = async item => {
    const currentDate = getDateForMilliseconds(item?.key);
    setSelectedMenu([item.key]);
    setFilters({
      ...filters,
      lote: '',
      loadingDate: currentDate,
      executeSearch: true,
    });
  };

  const handleUpdate = async () => {
    await fetchLoadingProgramDates();
    await init(filters);
  }

  const handleInputSearch = (search) => {
    setFilters({
      ...filters,
      lote: search,
      executeSearch: true,
      loadingDate: search === ''
        ? filters.loadingDate
        : undefined
    });
  }

  const handleShowReporte = async () => {
    setShowReporte(true);
    setLoading(true);
    const data = await getPlanificacionContenedorPdf(filters?.loadingDate?.getTime(), currentUser?.idubc);
    setLoading(false);
    setReporte(data);
  }

  const hanldeFinishSecuencia = async (values) => {
    try {
      setLoading(true);
      await updateExportationContainerSequence(updateExportationContainer?.idExportationContainer, values);
      setLoading(false);
      successMessage('Secuencia modificada con exito');
      form.resetFields();
      setShowFormSequence(false);
      setUpdateExportationContainer(undefined);
      await init(filters);
    } catch (ex) {
      errorMessage(`${ex.toString()}`);
    }
  }

  return(
    <>
      <ExportationProvider>
        <Layout className="exportation-container-page" style={{ minHeight: 'calc(100vh - 64px)' }}>
          <Sider style={{ background: colorBgContainer }}
                 collapsible
                 width={'200px'}
                 trigger={null}
                 collapsed={collapsed}
                 collapsedWidth={0}
                 onCollapse={(value) => setCollapsed(value)}>
            <p className="pt-3 text-center fw-bolder">
              Fecha de Carga
            </p>

            <Menu theme="light"
                  mode="inline"
                  selectedKeys={selectedMenu}
                  onSelect={handleSelectDate}
                  items={loadingProgramDates.map((item) => {
                    return {
                      key: item.date,
                      label: <div key={item?.date} className="d-flex align-items-center justify-content-between flex-wrap">
                        <p className="mb-0 me-3 fw-bolder">
                          {moment(item.date).format( "MM-DD-YYYY")}
                        </p>
                        <Tag color={"blue"} className="m-0"><p className="mb-0 fw-bold">{item.count}</p></Tag>
                      </div>
                    }
                  })}/>
          </Sider>
          <Layout>
            <Card className="m-1" size={'small'}>
              <Spinner loading={loading || loadingMain}>
                <div className="d-flex align-items-center justify-content-between flex-wrap mb-3">
                  <h5 className="mb-3 mb-sm-0 d-flex align-items-center justify-content-start flex-wrap gap-2">
                    <Button type="text"
                            icon={<FontAwesomeIcon icon={faBars} />}
                            onClick={() => setCollapsed(!collapsed)} />
                    Planificación de Contenedores
                  </h5>
                  <div className="d-flex gap-2">
                    <Button type={'default'} onClick={handleShowReporte}>
                      <FontAwesomeIcon className="me-2" size={'sm'} icon={faFilePdf}/> Generar Reporte
                    </Button>
                    <ShowWhen show={validarPermisos(permisos.PLANIFICACION_CONTENEDOR_AGREGAR)}>
                      <Dropdown.Button type={'primary'}
                                       onClick={handleAddExportationContainer}
                                       menu={{ items: items, onClick: handleMenuClick }}>
                        <FontAwesomeIcon className="me-2" size={'sm'} icon={faPlus}/> Agregar Contenedor
                      </Dropdown.Button>
                    </ShowWhen>
                  </div>
                </div>
                <div className="mb-3">
                  <InputSearch initialValue={filters.lote} placeholder={'Buscar por lote o vin'}
                               doChange={handleInputSearch}/>
                </div>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ExportationContainerTable filters={filters}
                                               currentUser={currentUser}
                                               totalElements={totalElements}
                                               exportationContainers={exportationContainers}
                                               onUpdate={handleUpdate}
                                               onPageChange={handlePageChange}
                                               fechaSeleccionada={selectedMenu.length > 0 ? moment(selectedMenu[0]).format('MM-DD-yyyy') : undefined}
                                               onUpdateExportationContainer={handleUpdateExportationContainer}
                                               onUpdateExportationContainerSequence={handleUpdateExportationContainerSequence} />
                  </Col>
                </Row>

                <Modal width={'80%'}
                       open={showForm}
                       title={'Asignar contenedor'}
                       closable={false}
                       okButtonProps={{style: {display: 'none'}}}
                       cancelButtonProps={{style: {display: 'none'}}}>
                  <ExportationContainerForm cliente={cliente}
                                            idOrigen={currentUser?.idubc}
                                            editable={true}
                                            externalLoading={loading}
                                            exportationContainer={updateExportationContainer}
                                            onRefreshExportationContainer={handleRefreshExportationContainer}
                                            onCancel={() => {
                                              setShowForm(false);
                                              setCliente(undefined);
                                              setUpdateExportationContainer(undefined);
                                              init(filters).then();
                                            }}
                                            onSubmit={() => {
                                              setShowForm(false);
                                              setCliente(undefined);
                                              setUpdateExportationContainer(undefined);
                                              init(filters).then();
                                              fetchLoadingProgramDates().then();
                                            }} />
                </Modal>

                <Modal open={showFormSequence}
                       title={'Actualizar Secuencia'}
                       closable={false}
                       okButtonProps={{style: {display: 'none'}}}
                       cancelButtonProps={{style: {display: 'none'}}}>
                  <Spinner loading={loading}>
                    <Form form={form} layout={'vertical'} onFinish={hanldeFinishSecuencia}>
                      <Form.Item label={'Secuencia'} name={'sequence'}>
                        <InputNumber className="w-100" placeholder={'Secuencia'} />
                      </Form.Item>
                      <Button type={'primary'} htmlType={'submit'} className="me-2">Guardar</Button>
                      <Button type={'default'}
                              htmlType={'button'}
                              onClick={() => {
                                setShowFormSequence(false);
                                setUpdateExportationContainer(undefined);
                                form.resetFields();
                              }}>
                        Cancelar
                      </Button>
                    </Form>
                  </Spinner>
                </Modal>

                <PdfReport visible={showReporte} setVisible={setShowReporte} data={reporte} setData={setReporte} />
              </Spinner>
            </Card>
          </Layout>
        </Layout>
      </ExportationProvider>
    </>
  );
}

export default ExportationContainerPage;
