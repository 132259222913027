import {server} from "../environments/environment";
import {ApiService} from "./ApiService";
import {VehiculoEstadoEnum} from "../shared/enums/vehiculo-estado.enum";
import {VehiculoEstadoInspeccionEnum} from "../shared/enums/vehiculo-estado-inspeccion.enum";
import {getToken} from "./AuthService";

export const getVehicleList = async (origen = "", estado = "", lote = "", fechaInicio = 0, fechaFin = 0) => {
    let params = {
        fechaInicio: fechaInicio,
        fechaFin: fechaFin
    };

    if (origen !== "") params['origen'] = origen;
    if (estado !== "") params['estado'] = estado;
    if (lote !== "") params['lote'] = lote;

    const queryParams = new URLSearchParams(params);
    const response = await ApiService.get(`${server}Vehiculo?${queryParams.toString()}`);

    const data = await response.json();
    return data.list;
}

export const getVehicleClientePreferencial = async (origen) => {
    let params = {
        origenId: origen,
    };

    const queryParams = new URLSearchParams(params);
    const response = await ApiService.get(`${server}Vehiculo/clientePreferencial?${queryParams.toString()}`);

    const data = await response.json();
    return data.list;
}

export const listMake =  async () => { 
    const response = await ApiService.get(`${server}Vehiculo/MakeModelOnly`);
    const data = await response.json()
    return data;
};

export const listColor =  async () => {
    const response = await ApiService.get(`${server}Vehiculo/color`);
    const data = await response.json()
    return data.list;
};

export const updateVehiculoTituloEstado = async (id, car) => {
    const URL = `${server}vehiculo/${id}/tituloEstado`
    const response = await ApiService.put(URL, car);

    return response.json();
}

export const updateVehiculoEstadoEmbarcado = async (id) => {
    const URL = `${server}vehiculo/${id}/estadoEmbarcado`
    const response = await ApiService.get(URL);

    return response.json();
}

export const vinDecode = async (vin: string) => {
    const URL = `${server}vinDecode/${vin}`;
    const response = await ApiService.get(URL);

    return response.json();
}

export const addCar = async (vehiculo) => {
    const response = await ApiService.post(`${server}Vehiculo`, vehiculo);
    return response.json();
}

export const editCar = async (id, vehiculo) => {
    const response = await ApiService.put(`${server}Vehiculo/${id}`, vehiculo);

    return response.json();
}

export const uploadVehicleFile = async (id, lote, imagenTitulo = null, imagenFrontal = null, imagenVin = null) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();

        if (imagenTitulo)
            formData.append('imagenTitulo', imagenTitulo);

        if (imagenFrontal)
            formData.append('imagenFrontal', imagenFrontal);

        if (imagenVin)
            formData.append('imagenVin', imagenVin);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', `${server}vehiculo/${id}/uploadFiles/${lote}`, true);
        xhr.setRequestHeader('Authorization', `Bearer ${getToken()}`);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = Math.round((event.loaded / event.total) * 100);
                console.log(`Upload progress: ${percentComplete}%`);
                // Update your UI with the progress here
            }
        };

        xhr.onload = () => {
            const response = JSON.parse(xhr.response);
            resolve(response.data);
        };

        xhr.onerror = () => {
            reject(false);
        };

        xhr.send(formData);
    });
}

export const getVehicleInStock = async (currentPage = 0, pageCount = 0, search = "", origenId = 0, aduanaId = 0,
                                        countryId = 0, month = 0, year = 0) => {
    let params = {
        currentPage: currentPage,
        pageCount: pageCount,
        origenId: origenId,
        aduanaId: aduanaId,
        countryId: countryId,
        month: month,
        year: year
    };

    if (search !== "") params['search'] = search;

    const queryParams = new URLSearchParams(params);
    const response = await ApiService.get(`${server}Vehiculo/enInventario?${queryParams.toString()}`);

    const data = await response.json();
    if (data && data.status)
        return data;
    throw new Error(data.message);
}

export const getVehiculoSecciones = async (origenId) => {
    const response = await ApiService.get(`${server}VehiculoSeccion?origenId=${origenId}`);
    const data = await response.json();

    if (data && data.status) return data.list;
    throw new Error(data.message);
}

export const asignarVehiculoSeccion = async (id, idVehiculoSeccion) => {
    const response = await ApiService.put(`${server}Vehiculo/${id}/vehiculoSeccion`, { idVehiculoSeccion });
    const data = await response.json();

    if (data && data.status) return data.list;
    throw new Error(data.message);
}

export const getVehicleInput = vehicle => {
    return {
        year: vehicle ? vehicle.year : "",
        make: vehicle ? vehicle.make : "",
        model: vehicle ? vehicle.model : "",
        color: vehicle ? vehicle.color : "",
        height: vehicle ? vehicle.height : "0",
        length: vehicle ? vehicle.length : "0",
        width: vehicle ? vehicle.width : "0",
        vin: vehicle ? vehicle.vin : "",
        weight: vehicle ? vehicle.weight : 0,
        fecha: vehicle ? vehicle.fecha : null,
        lote: vehicle ? vehicle.lote : "",
        titulo: vehicle ? vehicle.titulo : false,
        llave: vehicle ? vehicle.llave : false,
        catalitico: vehicle ? vehicle.catalitico : false,
        estado: vehicle ? vehicle.estado : VehiculoEstadoEnum.noSolicitud,
        updateBy: vehicle ? vehicle.updateBy : "",
        origen: vehicle ? vehicle.origen : "",
        bio: vehicle ? vehicle.bio : false,
        storage: vehicle ? vehicle.storage : "",
        notas: vehicle ? vehicle.notas : "",
        ubication: vehicle ? vehicle.ubication : "",
        keyinside: vehicle ? vehicle.keyinside : false,
        radio: vehicle ? vehicle.radio : false,
        llantaResp: vehicle ? vehicle.llantaResp : false,
        billofSale: vehicle ? vehicle.billofSale : false,
        bateria: vehicle ? vehicle.bateria : false,
        cristalTrasero: vehicle ? vehicle.cristalTrasero : false,
        cristalDelantero: vehicle ? vehicle.cristalDelantero : false,
        ventanaIzquierda: vehicle ? vehicle.ventanaIzquierda : false,
        ventanaDerecha: vehicle ? vehicle.ventanaDerecha : false,
        openDoor: vehicle ? vehicle.openDoor : false,
        openCapo: vehicle ? vehicle.openCapo : false,
        estadoInspeccion: vehicle ? vehicle.estadoInspeccion : VehiculoEstadoInspeccionEnum.noInspeccionado,
        destinoCode: vehicle ? vehicle.destinoCode : "",
        idTitle: vehicle ? vehicle.idTitle : null,
        tituloRecibidoPor: vehicle ? vehicle.tituloRecibidoPor : "",
        clienteId: vehicle ? vehicle.clienteId : null,
        tienePiezasInterior: vehicle ? vehicle.tienePiezasInterior : false,
        recibidoSinTitulo: vehicle ? vehicle.recibidoSinTitulo : false,
        inspeccionadoPor: vehicle ? vehicle.inspeccionadoPor : "",
        imagenTitulo: vehicle ? vehicle.imagenTitulo : null,
        imagenFrontal: vehicle ? vehicle.imagenFrontal : null,
        imagenVin: vehicle ? vehicle.imagenVin : null,
        enInventario: vehicle ? vehicle.enInventario : false,
    }
}
