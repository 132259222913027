import {Button} from "antd";
import type {ButtonHTMLType, ButtonShape, ButtonType} from "antd/es/button/buttonHelpers";
import type {SizeType} from "antd/es/config-provider/SizeContext";
import * as React from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface SimiButtonProps {
  children: React.ReactNode;
  htmlType?: ButtonHTMLType;
  type?: ButtonType;
  block?: boolean;
  size?: SizeType;
  icon?: IconProp;
  shape?: ButtonShape;
  disabled?: boolean;
  loading?: boolean | { delay?: number; };
  color?: 'primary' | 'danger' | 'success' | 'warning';
  onClick?: () => void
}

const SimiButton = (props: SimiButtonProps) => {
  return(
    <Button className={`simi-button-${props.color ?? 'primary'}`}
            block={props.block ?? false}
            size={props.size ?? 'middle'}
            type={props.type ?? 'primary'}
            shape={props.shape ?? 'default'}
            loading={props.loading ?? false}
            danger={props.color === 'danger'}
            disabled={props.disabled ?? false}
            htmlType={props.htmlType ?? 'button'}
            onClick={props.onClick}>
      {props.icon ? <FontAwesomeIcon icon={props.icon} /> : ''}
      {props.children}
    </Button>
  );
}

export default SimiButton;
