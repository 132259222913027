import {Button} from "antd";
import React, {useEffect, useState} from "react";
import SendEmail from "../../../utils/email";
import moment from "moment/moment";
import environment from "../../../environments/environment";
import {NotificacionProgramacionCargaExportation} from "../../../Resources/HtmlEmailBody/SolicitudRecoleccionVehiculo";

const EnviarNotificacion = ({ currentUser, exportation }) => {
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (exportation) {
      const date = moment().format("DD-MM-YYYY");
      setBody(NotificacionProgramacionCargaExportation(exportation));
      setSubject(`${date} - VEHÍCULO PROXIMOS A CARGARSE CON ${exportation?.carrier?.oceanCarrierName} CON DESTINO ${exportation?.aduana?.nameAduana}`);
    }
  }, [exportation]);

  return(
    <>
      <Button block style={{backgroundColor: "green", color: "white"}} onClick={() => setShowModal(true)}>
        Enviar Notificación
      </Button>
      <SendEmail visible={showModal}
                 setVisible={setShowModal}
                 isLoading={false}
                 titulo={`Enviar Notificación`}
                 to={[environment.emails.grua]}
                 cc={[currentUser?.email]}
                 cco={[]}
                 subject={subject}
                 showMessageInput={false}
                 body={body}
                 attachments={[]} />
    </>
  );
}

export default EnviarNotificacion;
