import { Space, Spin } from "antd";

const Spinner = ({children,loading, tip = 'Loading...'}) => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Spin tip={tip} spinning={loading} size="large">
        {children}
      </Spin>
    </Space>
  );
};

export default Spinner;
