import Spinner from "../shared/spinner";
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Image, Input, InputNumber, Row, Upload} from "antd";
import {
  addQuoteVehiculoCorte,
  editQuoteVehiculoCorte,
  solicitudVehiculoCorteFotoReferencia
} from "../Servicios/SolicitudesService";
import {errorMessage, successMessage} from "../utils/toast-message";
import TextArea from "antd/lib/input/TextArea";
import {UploadOutlined} from "@ant-design/icons";
import SimiButton from "../Componentes/SimiButton/SimiButton";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";

const SolicitudVehiculoCorteForm = ({solicitud, solicitudVehiculoCorte, onFinish}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fotoReferencia, setFotoReferencia] = useState(undefined);

  useEffect(() => {
    const initForm = async () => {
      if (solicitudVehiculoCorte) {
        form.setFieldsValue({
          lote: solicitud?.lote ?? '',
          year: solicitud?.year ?? '',
          make: solicitud?.make ?? '',
          model: solicitud?.model ?? '',
          color: solicitud?.color ?? '',
          aduana: solicitud?.aduanaName ?? '',
          alfombras: solicitudVehiculoCorte?.alfombras ?? undefined,
          asientos: solicitudVehiculoCorte?.asientos ?? undefined,
          catalizador: solicitudVehiculoCorte?.catalizador ?? undefined,
          faroles: solicitudVehiculoCorte?.faroles ?? undefined,
          llantas: solicitudVehiculoCorte?.llantas ?? undefined,
          puertas: solicitudVehiculoCorte?.puertas ?? undefined,
          stops: solicitudVehiculoCorte?.stops ?? undefined,
          tapiceria: solicitudVehiculoCorte?.tapiceria ?? undefined,
          bomperDelantero: solicitudVehiculoCorte?.bomperDelantero ?? undefined,
          bomperTrasero: solicitudVehiculoCorte?.bomperTrasero ?? undefined,
          compuertaBaul: solicitudVehiculoCorte?.compuertaBaul ?? undefined,
          ejeTrasero: solicitudVehiculoCorte?.ejeTrasero ?? undefined,
          frenoDelantero: solicitudVehiculoCorte?.frenoDelantero ?? undefined,
          frenoTrasero: solicitudVehiculoCorte?.frenoTrasero ?? undefined,
          almaBomperDelantero: solicitudVehiculoCorte?.almaBomperDelantero ?? undefined,
          almaBomperTrasero: solicitudVehiculoCorte?.almaBomperTrasero ?? undefined,
          notasCorte: solicitudVehiculoCorte?.notasCorte ?? '',

          alfombrasCheck: !!solicitudVehiculoCorte?.alfombras ?? false,
          asientosCheck: !!solicitudVehiculoCorte?.asientos ?? false,
          catalizadorCheck: !!solicitudVehiculoCorte?.catalizador ?? false,
          farolesCheck: !!solicitudVehiculoCorte?.faroles ?? false,
          llantasCheck: !!solicitudVehiculoCorte?.llantas ?? false,
          puertasCheck: !!solicitudVehiculoCorte?.puertas ?? false,
          stopsCheck: !!solicitudVehiculoCorte?.stops ?? false,
          tapiceriaCheck: !!solicitudVehiculoCorte?.tapiceria ?? false,
          bomperDelanteroCheck: !!solicitudVehiculoCorte?.bomperDelantero ?? false,
          bomperTraseroCheck: !!solicitudVehiculoCorte?.bomperTrasero ?? false,
          compuertaBaulCheck: !!solicitudVehiculoCorte?.compuertaBaul ?? false,
          ejeTraseroCheck: !!solicitudVehiculoCorte?.ejeTrasero ?? false,
          frenoDelanteroCheck: !!solicitudVehiculoCorte?.frenoDelantero ?? false,
          frenoTraseroCheck: !!solicitudVehiculoCorte?.frenoTrasero ?? false,
          almaBomperDelanteroCheck: !!solicitudVehiculoCorte?.almaBomperDelantero ?? false,
          almaBomperTraseroCheck: !!solicitudVehiculoCorte?.almaBomperTrasero ?? false,
        });
      } else {
        form.setFieldsValue({
          lote: solicitud?.lote ?? '',
          year: solicitud?.year ?? '',
          make: solicitud?.make ?? '',
          model: solicitud?.model ?? '',
          color: solicitud?.color ?? '',
          aduana: solicitud?.aduanaName ?? ''
        });
      }

    }

    initForm().then();
  }, [solicitud, solicitudVehiculoCorte]);

  const handleFinish = async (values) => {
    try {
      const input = {
        idQuote: solicitud.quoteId,
        ...values,
      };

      setLoading(true);
      const data = solicitudVehiculoCorte
        ? await editQuoteVehiculoCorte(solicitudVehiculoCorte.id, input)
        : await addQuoteVehiculoCorte(input);

      // save foto
      if (fotoReferencia) {
        await solicitudVehiculoCorteFotoReferencia(data.id, solicitud.lote, fotoReferencia[0]);
      }
      setLoading(false);

      successMessage("Solicitud guardada correctamente");
      if (onFinish) onFinish();
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  return(
    <>
      <Spinner loading={loading}>
        <Form form={form}
              layout={'vertical'}
              onFinish={handleFinish}>
          <p className="fw-bold">Lista de verificación de corte de vehículo</p>
          <Row gutter={16}>
            <Col lg={18}>
              <Form.Item label={'No. de Lote:'} name={'lote'}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item label={'Año:'} name={'year'}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={'Marca:'} name={'make'}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'Modelo:'} name={'model'}>
            <Input disabled />
          </Form.Item>
          <Row gutter={16}>
            <Col lg={8}>
              <Form.Item label={'Color:'} name={'color'}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={16}>
              <Form.Item label={'Aduana:'} name={'aduana'}>
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <hr/>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={'Check'} name={'llantasCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('llantas', ev.target.checked ? 1 : undefined);
                }} />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={'Piezas:'} className="mb-0">
                <p className="mb-0">Llantas</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={'Cantidad'} name={'llantas'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'puertasCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('puertas', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Puertas</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'puertas'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'compuertaBaulCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('compuertaBaul', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Compuerta de Baúl</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'compuertaBaul'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'stopsCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('stops', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Stops</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'stops'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'farolesCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('faroles', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Silvines o Faroles</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'faroles'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'alfombrasCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('alfombras', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Alfombras</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'alfombras'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'tapiceriaCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('tapiceria', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Tapicería</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'tapiceria'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'bomperDelanteroCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('bomperDelantero', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Bomper Delantero</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'bomperDelantero'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'bomperTraseroCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('bomperTrasero', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Bomper Trasero</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'bomperTrasero'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'almaBomperDelanteroCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('almaBomperDelantero', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Alma de Bomper Delantero</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'almaBomperDelantero'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'almaBomperTraseroCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('almaBomperTrasero', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Alma de Bomper Trasero</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'almaBomperTrasero'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'asientosCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('asientos', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Asientos</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'asientos'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'ejeTraseroCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('ejeTrasero', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Eje Trasero</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'ejeTrasero'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'catalizadorCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('catalizador', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Catalizador</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'catalizador'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'frenoDelanteroCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('frenoDelantero', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Freno Delantero</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'frenoDelantero'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={4} className={'align-self-center'}>
              <Form.Item label={''} name={'frenoTraseroCheck'} className="mb-0" valuePropName={'checked'}>
                <Checkbox onChange={ev => {
                  form.setFieldValue('frenoTrasero', ev.target.checked ? 1 : undefined);
                }}  />
              </Form.Item>
            </Col>
            <Col xs={12} className={'align-self-center'}>
              <Form.Item label={''} className="mb-0">
                <p className="mb-0">Freno Trasero</p>
              </Form.Item>
            </Col>
            <Col xs={8} className={'align-self-center'}>
              <Form.Item label={''} name={'frenoTrasero'} className="mb-0">
                <InputNumber className="w-100" size={'small'} />
              </Form.Item>
            </Col>
          </Row>
          <hr/>
          <Form.Item label={'Notas sobre el corte:'} name={'notasCorte'}>
            <TextArea className="w-100" rows={5} />
          </Form.Item>

          <Form.Item label={'Foto de Referencia:'}>
            <div className="d-flex gap-3">
              <ShowWhen show={!!solicitudVehiculoCorte}>
                <Button style={{height: '120px'}} type={'dashed'} onClick={evt => evt.stopPropagation()}>
                  <Image width={100} src={solicitudVehiculoCorte?.fotoReferencia} />
                </Button>
              </ShowWhen>
              <Upload beforeUpload={(file) => {
                setFotoReferencia([file])
                return false;
              }}
                      onRemove={() => {
                        setFotoReferencia(undefined)
                      }} >
                <Button htmlType={'button'} style={{height: '120px'}} type={'dashed'} icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </div>
          </Form.Item>
          <SimiButton htmlType={'submit'}>Guardar</SimiButton>
        </Form>
      </Spinner>
    </>
  );
}

export default SolicitudVehiculoCorteForm;
