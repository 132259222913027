import {
  Button,
  Col,
  Row,
  Space,
  Tabs,
} from "antd";
import React, {useEffect, useState} from "react";
import {
  updateExportation,
  getExportationIdData,
  exportationContainerAsignar,
  exportationContainerRemover,
} from "../../../Servicios/ExportationServices";
import "react-toastify/dist/ReactToastify.css";
import useCurrentUser from "../../../Hooks/UserHook";
import Spinner from "../../../shared/spinner";
import ExportationPdfViewer from "../ExportationPdfViewer";
import {useNavigate, useParams} from "react-router";
import {errorMessage, successMessage} from "../../../utils/toast-message";
import PdfReport from "../../../utils/pdfReport";
import {ExportationProvider} from "../../../contexts/ExportationContext";
import ExportationBookingForm from "../ExportationForm/ExportationBookingForm";
import AsignacionContenedor from "../ExportationForm/AsignacionContenedor";
import ExportationAdditionalForm from "../ExportationForm/ExportationAdditionalForm";
import EnviarNotificacion from "../ExportationForm/EnviarNotificacion";

const STEPS = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_4: 4
}

const ExportationClientePreferencialFormPage = () => {
  const { id } = useParams();
  const { idCliente } = useParams();

  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [loading, setIsLoading] = useState(!!id);
  const [current, setCurrent] = useState(STEPS.STEP_1);
  const [visible, setVisible] = useState(false);
  const [dataReport, setDataReport] = useState("");
  const [tipSpinner, setTipSpinner] = useState("");
  const [exportation, setExportation] = useState(undefined);
  const [exportationEditable, setExportationEditable] = useState(true);

  const updateAndSaveInformationAditional = async (values) => {
    try {
      setTipSpinner("Actualizando Información Adicional");

      values.containerId = exportation?.containerId;
      values.countryId = exportation?.countryId;
      values.createDate = exportation?.createDate;
      values.state = exportation?.state;
      values.isActive = exportation?.isActive;
      values.amountAditional = exportation?.amountAditional;
      values.countryName = exportation?.countryName;
      values.customerReference = exportation?.customerReference;
      values.dateLoading = exportation?.dateLoading;
      values.approvalNumber = exportation?.approvalNumber;
      values.sealNumber = exportation?.sealNumber;
      values.userName = exportation?.userName;
      values.descriptionAditional = exportation?.descriptionAditional;
      values.noteLoading = exportation?.noteLoading;

      values.exportationId = exportation?.exportationId;

      const input = {
        ...exportation,
        ...values,
      };

      setIsLoading(true);
      await updateExportation(exportation.exportationId, input);
      setIsLoading(false);

      successMessage('Exportacion actualizada con exito');
      navigate(-1);
    } catch (ex) {
      errorMessage(`${ex.toString()}`);
    }
  };

  useEffect(() => {
    if (currentUser && id) {
      initExportation().then();
    }
  }, [currentUser, id]);

  const initExportation = async () => {
    setIsLoading(true);
    const data = await getExportationIdData(id);
    setIsLoading(false);

    setExportation({...data});
    // a futuro remover setExportationEditable
    setExportationEditable(true);
  }

  const handleSaveExportationBooking = async (newExportation) => {
    if (exportation) {
      setCurrent(STEPS.STEP_2);
      setExportation(newExportation);
    } else {
      navigate(-1);
    }
  }

  const handleSaveExportationContainer = async () => {
    await initExportation();
  }

  const handleSelectContainer = async (exportationContainer) => {
    try {
      const data = {
        idExportationContainer: exportationContainer.idExportationContainer,
        containerNumber: exportationContainer.containerNumber,
        sealNumber: exportationContainer.sealNumber,
        letter: exportationContainer.letter,
      };

      setIsLoading(true);
      await exportationContainerAsignar(exportation.exportationId, data).finally(() => setIsLoading);
      setIsLoading(false);

      successMessage('Contenedor asignado correctamente');
      await initExportation();
    } catch (ex) {
      errorMessage(`${ex.toString()}`)
    }
  }

  const handleDeleteContainer = async (exportationContainer) => {
    try {
      const data = {
        idExportationContainer: exportationContainer.idExportationContainer,
        containerNumber: exportationContainer.containerNumber,
        sealNumber: exportationContainer.sealNumber,
        letter: exportationContainer.letter,
      };

      setIsLoading(true);
      await exportationContainerRemover(exportation.exportationId, data).finally(() => setIsLoading);
      setIsLoading(false);

      successMessage('Contenedor eliminado correctamente');
      await initExportation();
    } catch (ex) {
      errorMessage(`${ex.toString()}`)
    }
  }

  const steps = [
    {
      title: "Control de Booking",
      key: STEPS.STEP_1,
      content: () => {
        return (
          <div className="p-3">
            <ExportationBookingForm idCliente={idCliente}
                                    editable={exportationEditable}
                                    exportation={exportation}
                                    onSubmit={handleSaveExportationBooking}/>
          </div>
        );
      },
    },
    {
      title: 'Asignación de contenedores',
      key: STEPS.STEP_2,
      content: () => {
        return (
          <div className="p-3">
            <AsignacionContenedor idCliente={idCliente}
                                  origen={currentUser?.idubc}
                                  exportation={exportation}
                                  editable={exportationEditable}
                                  onSelectContainer={handleSelectContainer}
                                  onDeleteContainer={handleDeleteContainer}
                                  onUpdate={handleSaveExportationContainer}/>

            <div className="row">
              <div className="col-6">
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button block onClick={() => setCurrent(STEPS.STEP_1)}>
                    Atras
                  </Button>
                </Space>
              </div>

              <div className="col-6">
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button type="primary" block onClick={() => setCurrent(STEPS.STEP_3)}>
                    Continuar
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Imprimir Documento",
      key: STEPS.STEP_3,
      content: () => {
        return (
          <div>
            <PdfReport
              visible={visible}
              setVisible={setVisible}
              data={dataReport}
              setData={setDataReport}
            />
            <ExportationPdfViewer exportation={exportation} />
            <Row gutter={[16, 24]} style={{marginBottom: 10}}>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <EnviarNotificacion currentUser={currentUser} exportation={exportation} />
                </Space>
              </Col>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button block onClick={() => setCurrent(STEPS.STEP_2)}>
                    {" "}
                    Atras{" "}
                  </Button>
                </Space>
              </Col>
              <Col span={8}>
                <Space direction="vertical" style={{width: "100%"}}>
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      setCurrent(STEPS.STEP_4);
                    }}>
                    Continuar
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        );
      },
    },
    {
      title: "Información Adicional",
      key: STEPS.STEP_4,
      content: () => {
        return (
          <div className="p-3">
            <ExportationAdditionalForm goBack={() => setCurrent(current - 1)}
                                       exportation={exportation}
                                       currentUser={currentUser}
                                       updateAndSaveInformationAditional={updateAndSaveInformationAditional} />
          </div>
        );
      },
    },
  ];

  const itemsTab = (exportation ? steps : steps.slice(0, 1)).map((item) => ({
    key: item.key,
    label: item.title,
    children: item.content()
  }));

  return (
    <>
      <ExportationProvider>
        <Spinner loading={loading} tip={tipSpinner ? tipSpinner : "Cargando..."}>
          <Row gutter={[16, 24]}>
            <Col
              span={24}
              style={{display: "flex", justifyContent: "space-between"}}
            >
              {id ? (
                <h4>Actualizar datos de exportación</h4>
              ) : (
                <h4>Registro de exportaciones</h4>
              )}
              <Button type="primary" onClick={() => navigate(-1)}>
                Lista de exportaciones
              </Button>
            </Col>
          </Row>
          <hr/>
          <Tabs activeKey={current} items={itemsTab} centered={true} onChange={(step) => setCurrent(step)}></Tabs>
        </Spinner>
      </ExportationProvider>
    </>
  );
};

export default ExportationClientePreferencialFormPage;
