import {Card} from "antd";

const TransporteCitaCard = ({transporteCita, onClick}) => {
  return(
    <>
      <Card className="cursor-pointer mb-3" size={'small'} onClick={onClick}>
        <p className="mb-0 fw-bold">{transporteCita?.coordinacion?.lote}</p>
        <p className="mb-0">{transporteCita?.coordinacion?.vehiculoName}</p>
        <p className="">{transporteCita?.coordinacion?.vin}</p>
        <p className="mb-0 fw-bold">{transporteCita?.coordinacion?.transporteName}</p>
      </Card>
    </>
  );
}

export default TransporteCitaCard;
