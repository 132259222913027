import {Alert, Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space} from "antd";
import Spinner from "../../../shared/spinner";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import {
  exportationPortConfig,
  getNumeroExportacion, getNumeroExportacionById,
  saveExportation,
  updateExportation
} from "../../../Servicios/ExportationServices";
import useCurrentUser from "../../../Hooks/UserHook";
import {errorMessage, successMessage} from "../../../utils/toast-message";
import dayjs from "dayjs";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import {useExportationContext} from "../../../contexts/ExportationContext";
import {PortCodeTypeStateEnum} from "../../../shared/enums/port-code-type-state.enum";
import {consecutivoEnum} from "../../../shared/enums/consecutivo.enum";
import {useMainContext} from "../../../contexts/MainContext";
import {viewClient} from "../../../Servicios/ClienteService";

const ExportationBookingForm = ({ generalCargo, idCliente, editable, exportation, onSubmit }) => {
  const currentUser = useCurrentUser();
  const [form] = Form.useForm();

  const [cliente, setCliente] = useState(undefined);
  const [exportationPortConfigs, setExportationPortConfigs] = useState([]);

  const [loading, setLoading] = useState(false);

  const { origenes, loadingMain } = useMainContext();
  const { loadingExportation, aduanas, carriers, subastas, portCodes } = useExportationContext();

  useEffect(() => {
    if (!currentUser) return;

    const init = async () => {
      let cliente = null;
      if (idCliente) {
        setLoading(true);
        cliente = await viewClient(idCliente);
        setLoading(false);

        setCliente(cliente);
      }

      if (exportation) {
        form.setFieldsValue({
          shipmentName: exportation.shipmentName,
          bookingNum: exportation.bookingNum,
          departureDate: exportation.departureDate
            ? dayjs(exportation.departureDate)
            : null,
          idAduana: exportation.idAduana,
          customerReference: exportation.customerReference,
          billLadingNum: exportation.billLadingNum,
          arrivalDate: exportation.arrivalDate
            ? dayjs(exportation.arrivalDate)
            : null,
          idCarrier: exportation.idCarrier,
          countryName: exportation.countryName,
          countryId: exportation.countryId,
          generalCargo: exportation.generalCargo,
          vesselNameFlag: exportation.vesselNameFlag,
          voyageIdentification: exportation.voyageIdentification,
        });
      } else {
        form.resetFields();
      }

      setLoading(true);
      await getNumberExp(cliente);
      setLoading(false);
    }

    init().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, exportation, idCliente]);

  useEffect(() => {
    try {
      exportationPortConfig().then((data) => setExportationPortConfigs(data));
    } catch (ex) {
      errorMessage(ex.toString());
    }
  }, []);

  const getNumberExp = async (cliente = null) => {
    try {
      if (!currentUser || currentUser.idubc === undefined) return;

      const origen = origenes.find(e => +e.id === +currentUser.idubc);
      if (!origen) return;
      const consecutivo = cliente
        ? await getNumeroExportacion(`${consecutivoEnum.sequenceCliente}${cliente.id}`)
        : await getNumeroExportacionById(generalCargo ? origen?.idConsecutivoCargaGeneral : origen?.idConsecutivo);

      form.setFieldsValue({
        quantity: 1,
        shipmentName: exportation
          ? exportation.shipmentName
          : cliente
            ? `${cliente?.importadoraName}-${(consecutivo + 1)}`
            : `${consecutivo?.format.replace('#', (consecutivo?.secuence + 1))}`,
      });
    } catch (ex) {
      errorMessage(ex.toString());
    }
  };

  const onFinish = async (values) => {
    const shipper = subastas.filter((e) => !!e.shipper).find(e => +e.origen === +currentUser?.idubc);
    const carrier = carriers.find(x => x.carrierId === +values.idCarrier);

    const portLoadingId = exportationPortConfigs
      .find(x => x.portType === PortCodeTypeStateEnum.Origin &&
        x.idOrigen === +currentUser?.idubc &&
        x.idCarrier === +values.idCarrier)?.idPortCode ?? null;

    const portUnloadingId = exportationPortConfigs
      .find(x => x.portType === PortCodeTypeStateEnum.Destination &&
        x.idOrigen === +currentUser?.idubc &&
        x.idCountry === +values.countryId)?.idPortCode ?? null;

    const portOriginCode = portCodes.find(e => e.portId === portLoadingId ?? 0)?.port;
    const portDestinationCode = portCodes.find(e => e.portId === portUnloadingId ?? 0)?.port;

    const valuesInput = {
      ...values,
      departureDate: values.departureDate
        ? moment(values.departureDate.$d).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      arrivalDate: values.arrivalDate
        ? moment(values.arrivalDate.$d).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      state: true,
      isActive: true,
      idSubShip: exportation ? exportation.idSubShip : shipper?.subId,
      idSubFa: exportation ? exportation.idSubFa : shipper?.subId,
      idImpUc: exportation ? exportation.idImpUc : null,
      idImpNp: exportation ? exportation.idImpNp : null,
      userId: exportation ? exportation.userId : +currentUser.idusr,
      idAduana: +values.idAduana,
      countryId: +values.countryId,
      idCarrier: +values.idCarrier,
      placeExId: exportation ? exportation.placeExId : +currentUser?.idubc,
      generalCargo: generalCargo,
      serviceId: exportation ? exportation.serviceId : carrier.serviceTypeId,
      exportationId: exportation ? exportation.exportationId : null,
      descriptionGoods: exportation ? exportation.descriptionGoods : "RELEASE OBL UNRATED AT DESTINATION",
      loadingPierTerminal: exportation ? exportation.loadingPierTerminal : carrier?.oceanCarrierName,
      portLoadingId: exportation ? exportation.portLoadingId : portLoadingId,
      portUnloadingId: exportation ? exportation.portUnloadingId : portUnloadingId,
      portOriginCode: portOriginCode,
      portDestinationCode: portDestinationCode,
      idCliente: idCliente ? +idCliente : null,
    }

    const input = exportation
      ? { ...exportation, ...valuesInput, }
      : { ...valuesInput }

    try {
      let data;
      setLoading(true);
      if (exportation) {
        data = await updateExportation(exportation.exportationId, input)
      } else {
        data = await saveExportation(input);
        successMessage('Exportación creada correctamente');
      }

      setLoading(false);
      if (onSubmit) onSubmit(data);
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  };

  const handleSelectAduana = aduanaId => {
    const aduana = aduanas.find(e => e.id === aduanaId);
    if (aduana) {
      form.setFieldsValue({
        countryId: aduana.countryId,
        countryName: aduana.countryName
      });
    }
  }

  return(
    <>
      <Spinner loading={loading || loadingExportation || loadingMain}>
        <Form layout={"vertical"}
              form={form}
              onFinish={onFinish}
              autoComplete="off">
          <Row gutter={[16, 24]}>
            <ShowWhen show={!!cliente}>
              <Col className="gutter-row" span={24}>
                <Alert type={'info'} showIcon={true} message={`Este embarque sera creado unicamente para vehículos asociados al cliente ${cliente?.nombreCompleto} - ${cliente?.importadoraName}`} />
              </Col>
            </ShowWhen>
            <Col className="gutter-row" span={12}>
              <Form.Item label="Country" name="countryId" hidden>
                <Input placeholder="Country id" />
              </Form.Item>

              <Form.Item label="Shipment Name:" name="shipmentName">
                <Input disabled style={{color: "#001529", textAlign: "center", fontWeight: "bold"}} />
              </Form.Item>
              <Form.Item
                label="Booking Number:"
                name="bookingNum">
                <Input placeholder="Booking Number" disabled={!editable} />
              </Form.Item>
              <Form.Item
                label="Departure Date/Time:"
                name="departureDate">
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                  disabled={!editable}
                />
              </Form.Item>
              <Form.Item label={'Seleccionar Aduana'}
                         rules={[{required: true, message: "La Aduana es requerido"}]}
                         name={'idAduana'}>
                <Select showSearch
                        disabled={!editable}
                        onChange={handleSelectAduana}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                        options={aduanas.map(e => ({
                          label: `${e.nameAduana} ${e.countryName}`, value: e.id,
                        }))}/>
              </Form.Item>

              <Form.Item label="Vessel Name and Flag:" name="vesselNameFlag">
                <Input disabled={!editable} placeholder="Vessel Name and Flag"/>
              </Form.Item>

              <ShowWhen show={!exportation}>
                <Form.Item className="w-100" label="Cantidad de Shipments" name="quantity"
                           extra={<div>
                             <p className="mb-0">Generar varios shipments al mismo tiempo</p>
                             <p className="mb-0">Especifique la cantidad para generar 1 o mas shipments con los mismo datos</p>
                           </div>}>
                  <InputNumber className="w-100" placeholder={'Cantidad de Shipments a generar'} />
                </Form.Item>
              </ShowWhen>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label="Customer Reference No.:"
                name="customerReference"
              >
                <Input disabled={!editable} placeholder="Customer Reference" />
              </Form.Item>

              <Form.Item
                label="Bill of Lading Number:"
                name="billLadingNum">
                <Input disabled={!editable} placeholder="Bill of Lading Number" />
              </Form.Item>

              <Form.Item label="Arrival Date/Time:" name="arrivalDate">
                <DatePicker
                  disabled={!editable}
                  format="YYYY-MM-DD"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item label={'Seleccionar Carrier'}
                         rules={[{required: true, message: "El carrier es requerido"}]}
                         name={'idCarrier'}>
                <Select showSearch
                        disabled={!editable}
                        filterOption={(input, option) => {
                          return (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }}
                        options={carriers.map(e => ({label: e.oceanCarrierName, value: e.carrierId}))}/>
              </Form.Item>

              <Form.Item
                label="Voyage Identification:"
                name="voyageIdentification"
              >
                <Input disabled={!editable} placeholder="Voyage Identification"/>
              </Form.Item>

              <Form.Item label="Country:" name="countryName">
                <Input placeholder="Country" disabled />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button type="primary" htmlType="submit" block>
                { exportation ? 'Continuar' : 'Generar Shipment' }
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spinner>
    </>
  );
}

export default ExportationBookingForm;
