import { NavLink } from "react-router-dom";

interface ItemMenu {
  label: string;
  icon: React.ReactElement;
  type: string;
  path: string;
  permission: boolean;
  children: ItemMenu[];
}

const processMenuItems = (array: ItemMenu[], parentKey: string = "key") => {
  return array
    .filter(item => item.permission)
    .map((item, index) => {
      const currentKey = `${parentKey}-${index}`;

      const newItem: Partial<ItemMenu> = {
        ...item,
        key: currentKey
      };

      // Si el item es de tipo "menu", el label debe tener NavLink
      if (item.type === "menu") {
        newItem.label = (
          <NavLink to={item.path} className="activeClicked" style={{ textDecoration: "none" }}>
            {item.label}
          </NavLink>
        );
      }

      // Si tiene hijos, los procesamos de manera recursiva
      if (item.children?.length) {
        newItem.children = processMenuItems(item.children, currentKey);
      }

      return newItem;
    });
};

const menuItem = (array: ItemMenu[]) => processMenuItems(array);

export default menuItem;
