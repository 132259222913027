import {Button, Col, Form, Input, Modal, Row, Select, Space, Switch, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowTurnRight,
  faCar,
  faFloppyDisk,
  faLeftLong,
  faPencil,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import SubastaForm from "../Componentes/SubastaForm/SubastaForm";
import ImportadoraForm from "../Componentes/ImportadoraForm/ImportadoraForm";
import {useNavigate} from "react-router-dom";
import {showImportadora, showSubasta} from "../Servicios/CombosService";
import {
  deleteExtraItem, showSchedule,
} from "../Servicios/WerehouseService";
import {viewCar} from "../Servicios/SolicitudesService";
import {getListFolderId} from "../Servicios/GoogleService";
import VehicleWarehouseUpdate from "./VehicleWarehouseUpdate";
import {errorMessage, successMessage} from "../utils/toast-message";
import AduanaSelector from "../Componentes/AduanaSelector/AduanaSelector";
import Spinner from "../shared/spinner";
import ExtraItemReactiveForm from "../Componentes/ExtraItemForm/ExtraItemReactiveForm";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import useCurrentUser from "../Hooks/UserHook";
import ExtraItemForm from "../Componentes/ExtraItemForm/ExtraItemForm";
import {useWarehouseContext} from "../contexts/WarehouseContext";
import {VehiculoEstadoEnum} from "../shared/enums/vehiculo-estado.enum";
import selectFilterOptions from "../shared/utils/select-filter-options";
import {useMainContext} from "../contexts/MainContext";
import {crearScheduleB} from "../Servicios/ScheduleBService";
import moment from "moment";

const tipoCargaPieza = "PIEZA";
const scheduleBNoVehicle = "6910100050";

const WarehouseForm = ({ coordinacion, werehouse, onUpdate, onSubmit, hasVehicle }) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const [form] = Form.useForm();
  const [formScheduleB] = Form.useForm();

  const [showSpinner, setShowSpinner] = useState(false);

  const [switchPoa, setSwitchPoa] = useState(false);
  const [switchFotos, setSwitchFotos] = useState(false);
  const [switchFactura, setSwitchFactura] = useState(false);
  const [switchVehiculoNuevo, setSwitchVehiculoNuevo] = useState(false);

  const [subastas, setSubastas] = useState([]);
  const [scheduleb, setScheduleb] = useState([]);
  const [extraItems, setExtraItems] = useState([]);
  const [importadoras, setImportadoras] = useState([]);

  const { origin, states, typemerch, codeexport, licencetype, loading } = useWarehouseContext();
  const { origenes, loadingMain  } = useMainContext();

  const [subasta, setSubasta] = useState(undefined);
  const [vehiculo, setVehiculo] = useState(undefined);
  const [importadora, setImportadora] = useState(undefined);

  const [editSubasta, setEditSubasta] = useState(undefined);
  const [addSubasta, setAddSubasta] = useState(false);

  const [editImportadora, setEditImportadora] = useState(undefined);
  const [addImportadora, setAddImportadora] = useState(false);

  const [addScheduleB, setAddScheduleB] = useState(false);
  const [showAduanaModal, setShowAduanaModal] = useState(false);
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [agregarExtraItem, setAgregarExtraItem] = useState(false);
  const [openVehicleModalDomestic, setOpenVehicleModalDomestic] = useState(false);

  const [extraItemNoVehicle, setExtraItemNoVehicle] = useState(undefined);
  const [tituloStateRequerido, setTituloStateRequerido] = useState(false);
  const [extraItemShowRequired, setExtraItemShowRequired] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    if (!loading) {
      setShowSpinner(true);
      initPage().then().finally(() => setShowSpinner(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinacion, werehouse, currentUser, loading]);

  const copyLink = async (lote) => {
    const elements = await getListFolderId(lote);

    let result;
    if (elements.data.length === 0) {
      result = "NO SE HAN CARGADO FOTOS PARA ESTE VEHICULO";
      setSwitchFotos(false);
    } else {
      result = `https://drive.google.com/drive/folders/${elements.data}`;
      setSwitchFotos(true);
    }

    return result;
  };

  const initPage = async () => {
    await init();

    if (werehouse) {
      const link = await copyLink(werehouse.lote);

      form.setFieldsValue({
        fecha: werehouse.fecha,
        recivedBy: werehouse.recivedBy,
        subastaId: werehouse.subastaId,
        importadoraId: werehouse.importadoraId,
        flete: werehouse.flete,
        bl: werehouse.bl,
        grua: werehouse.grua,
        buyer: werehouse.buyer,
        origenName: werehouse.origenName,
        idOrigen: werehouse.idOrigen,
        aduanaCom: werehouse.aduanaCom,
        aduanaId: werehouse.aduanaId,
        vin: werehouse.vin,
        lote: werehouse.lote,
        vehiculoName: werehouse.vehiculoName,
        color: werehouse.color,
        titulo: werehouse.vehiculo.titulo,
        bio: werehouse.vehiculo.bio,
        llave: werehouse.vehiculo.llave,
        catalitico: werehouse.vehiculo.catalitico,
        weight: werehouse.weight,
        length: werehouse.length,
        height: werehouse.height,
        width: werehouse.width,
        quantity: werehouse.quantity,
        poaW: werehouse.poaW,
        tieneFactura: werehouse.tieneFactura,
        vehiculoNuevo: werehouse.vehiculoNuevo,
        typeMerchId: werehouse.typeMerchId,
        auction: werehouse.subName,
        originId: werehouse.originId,
        schedulebId: werehouse.schedulebId,
        licenceTypeId: werehouse.licenceTypeId,
        expoCodeId: werehouse.expoCodeId,
        licenceNum: "NLR",
        numLlave: werehouse?.numLlave,
        linkFotos: link,
        notas: werehouse.notas,
        costoAdicional: werehouse.costoAdicional2,
        descripcionCostoAdicional: werehouse.descripcionCostoAdicional2,
        valorV: werehouse.valorV,
        volume: werehouse.volume,
        numTitle: werehouse.numTitle,
        stateId: werehouse.stateId,
        solicitudCostoAdicional: werehouse.costoAdicional,
        solicitudDescripcionCostoAdicional: werehouse.descripcionCostoAdicional,
      });

      setSubasta(werehouse.subasta);
      setVehiculo(werehouse.vehiculo);
      setImportadora(werehouse.importadora);

      if (!hasVehicle) {
        setExtraItemNoVehicle(werehouse.extraItems[0]);
      }

      setSwitchPoa(werehouse.poaW);
      setSwitchFotos(werehouse.fotos);
      setSwitchFactura(werehouse.tieneFactura);
      setOpenVehicleModalDomestic(false);
      setSwitchVehiculoNuevo(werehouse.vehiculoNuevo);
      setTituloStateRequerido(werehouse.vehiculo.titulo);
    } else if (coordinacion) {
      const link = await copyLink(coordinacion.lote);
      const typeMerch = typemerch.find(e => e.isDefault);

      form.setFieldsValue({
        fecha: coordinacion.fechaRecived,
        recivedBy: currentUser.fullName,
        subastaId: coordinacion.subastaId,
        importadoraId: coordinacion.importadoraId,
        flete: coordinacion.flete,
        bl: coordinacion.bl,
        grua: coordinacion.grua,
        buyer: coordinacion.buyer,
        origenName: coordinacion.origenName,
        idOrigen: coordinacion.origenId,
        aduanaCom: coordinacion.aduanaCom,
        aduanaId: coordinacion.aduanaId,
        vin: coordinacion.vin,
        lote: coordinacion.lote,
        vehiculoName: coordinacion.vehiculoName,
        color: coordinacion.color,
        titulo: coordinacion.titulo,
        bio: coordinacion.bioH,
        llave: coordinacion.llave,
        catalitico: coordinacion.catalitico,
        weight: coordinacion.weight,
        length: coordinacion.length,
        height: coordinacion.height,
        width: coordinacion.width,
        quantity: "1",
        poaW: false,
        tieneFactura: false,
        vehiculoNuevo: false,
        typeMerchId: typeMerch?.typeMerchId ?? 1,
        auction: coordinacion.subs,
        originId: 1,
        schedulebId: 1,
        licenceTypeId: 1,
        licenceNum: "NLR",
        numLlave: coordinacion?.vehiculo?.ubicacion,
        linkFotos: link,
        notas: coordinacion.notasVehi,
        solicitudCostoAdicional: coordinacion.costAdditional,
        solicitudDescripcionCostoAdicional: coordinacion?.quote?.descripcionCostoAdicional
      });

      setSubasta(coordinacion.subasta);
      setVehiculo(coordinacion.vehiculo);
      setImportadora(coordinacion.importadora);

      if (!hasVehicle) {
        setExtraItemNoVehicle(werehouse.extraItems[0]);
      }

      setSwitchPoa(false);
      setSwitchFactura(false);
      setSwitchVehiculoNuevo(false);
      setTituloStateRequerido(coordinacion.vehiculo.titulo);

      if (coordinacion.fechaRecived && coordinacion.vehiculo.estado === VehiculoEstadoEnum.recibidoEnBodega) {
        if (!openVehicleModalDomestic) {
          setOpenVehicleModalDomestic(true);
          await mostrarVehiculo(coordinacion.vehiculoId);
        }
      } else {
        errorMessage('No se puede crear el warehouse por que el vehículo no ha sido recibido en bodega');
      }
    } else {
      const origen = origenes.find(e => e.id === +currentUser.idubc);
      const licenceType = licencetype.find(e => e.isDefault);
      const typeMerch = typemerch.find(e => e.isDefault);
      const typeMerchPieza = typemerch.find(e => e.typeMerchName === tipoCargaPieza);
      const scheduleB = scheduleb.find(e => e.isDefault);
      const scheduleBPieza = scheduleb.find(e => e.codigo === scheduleBNoVehicle);
      const findOrigin = origin.find(e => e.isDefault);
      const codeExport = codeexport.find(e => e.code === origen.exportCode);

      form.resetFields();
      form.setFieldsValue({
        fecha: new Date().toLocaleDateString(),
        recivedBy: currentUser?.fullName,
        origenName: origen.nameOrigen,
        idOrigen: origen.id,
        licenceNum: "NLR",
        quantity: hasVehicle ? "1" : "",
        licenceTypeId: licenceType?.licenceTypeId,
        schedulebId: hasVehicle ? scheduleB?.schedulebId : scheduleBPieza?.schedulebId,
        originId: findOrigin?.originId,
        typeMerchId: hasVehicle ? typeMerch?.typeMerchId : typeMerchPieza?.typeMerchId,
        expoCodeId: codeExport?.expoCodeId,
      });

      setOpenVehicleModalDomestic(false);
    }
  }

  const init = async () => {
    await loadSubastas();
    await loadImports();

    const schedules = await showSchedule();
    setScheduleb([...schedules]);
  }

  const loadSubastas = async () => {
    const data = await showSubasta();
    setSubastas(data.list);
  }

  const loadImports = async () => {
    const data = await showImportadora();
    setImportadoras(data.list);
  }

  const handleFinish = (values) => {
    setExtraItemShowRequired(false);
    if (!hasVehicle) {
      if (!extraItemNoVehicle || !extraItemNoVehicle.extraItemName || !extraItemNoVehicle.weight || !extraItemNoVehicle.quantity) {
        setExtraItemShowRequired(true);
        return;
      }
    }

    if (onSubmit) {
      onSubmit({
        ...values,
        fecha: werehouse ? werehouse.fecha : moment().format("YYYY-MM-DDTHH:mm:ss"),
        fotos: switchFotos,
        vehiculoId: coordinacion
          ? coordinacion.vehiculoId
          : werehouse ? werehouse.vehiculoId : null,
        extraItem: extraItemNoVehicle,
        extraItems: extraItems,
      });
    }
  }

  const handleSelectChangeSubs = (value) => {
    const subasta = subastas.find(e => e.subId === value);
    setSubasta(subasta);
  }

  const handleSelectChangeImp = (value) => {
    const importadora = importadoras.find(e => e.id === value);
    setImportadora(importadora);
  }

  const handleUpdateSubasta = async subasta => {
    await loadSubastas();

    setSubasta(subasta);
    form.setFieldsValue({
      subastaId: subasta.subId,
    });

    setAddSubasta(false);
    setEditSubasta(undefined);
  }

  const handleUpdateImportadora = async importadora => {
    await loadImports();

    setImportadora(importadora);
    form.setFieldsValue({
      importadoraId: importadora.id,
    });

    setAddImportadora(false);
    setEditImportadora(undefined);
  }

  const openDes = () => {
    handleShowAduanaModal();
  };

  const handleSelectAduana = async aduana => {
    const aduanaCom = aduana.nameAduana;

    form.setFieldsValue({
      aduanaCom: aduanaCom,
      aduanaId: aduana.id,
    });

    handleCloseAduanaModal();
  }

  const handleCloseAduanaModal = () => setShowAduanaModal(false);

  const handleShowAduanaModal = () => setShowAduanaModal(true);

  const handleCloseVehicleModal = () => {
    setShowVehicleModal(false);
    form.validateFields().then();
  }

  const handleUpdateVehiculo = (vehiculo) => {
    successMessage('Vehículo actualizado correctamente');
    handleCloseVehicleModal();

    if (onUpdate && werehouse) {
      onUpdate();
    } else {
      setVehiculo(vehiculo);
      form.setFieldsValue({
        vin: vehiculo.vin,
        lote: vehiculo.lote,
        vehiculoName: vehiculo.vehiculoName,
        color: vehiculo.color,
        titulo: vehiculo.titulo,
        bio: vehiculo.bioH,
        llave: vehiculo.llave,
        catalitico: vehiculo.catalitico,
        weight: vehiculo.weight,
        length: vehiculo.length,
        height: vehiculo.height,
        width: vehiculo.width,
      });

      setTituloStateRequerido(vehiculo.titulo);
    }
  }

  const handleShowVehicleModal = () => setShowVehicleModal(true);

  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const mostrarVehiculo = async (vehiculoId) => {
    setShowSpinner(true);
    const vehiculo = await viewCar(vehiculoId);
    setShowSpinner(false);

    setVehiculo(vehiculo);
    handleShowVehicleModal();
  };

  const handleDeleteExtraPart = async (warehouseExtraItem) => {
    setShowSpinner(true);
    await deleteExtraItem(warehouseExtraItem.idWarehouseExtraItem);
    setShowSpinner(false);

    successMessage('Item eliminado con exito');
    if (onUpdate) onUpdate();
  }

  const handleAgregarExtraItem = (extraItem) => {
    setAgregarExtraItem(false);
    if (onUpdate) onUpdate();

    const temp = [...extraItems];
    temp.push(extraItem);
    setExtraItems([...temp]);
  }

  const handleFinishScheduleB = async (values) => {
    try {
      setShowSpinner(true);
      const data = await crearScheduleB(values);
      setShowSpinner(false);

      const schedules = await showSchedule();
      setScheduleb([...schedules]);

      successMessage('Schedule B creado correctamente');
      setAddScheduleB(false);

      form.setFieldValue('schedulebId', data.schedulebId);
    } catch (ex) {
      setShowSpinner(false);
      errorMessage(ex.toString());
    }
  }

  return(
    <Spinner loading={loading || showSpinner || loadingMain}>
      <Form
        style={{width: "100%"}}
        layout="vertical"
        onFinish={handleFinish}
        autoComplete="off"
        form={form}
      >
        <Row gutter={16} justify={"center"}>
          <ShowWhen show={!!werehouse}>
            <h4>Editando {werehouse?.codigo} de {werehouse?.impName}</h4>
          </ShowWhen>
          <ShowWhen show={!!coordinacion}>
            <h4>Creando WR de {coordinacion?.vehiculoName} Lote: {coordinacion?.lote}</h4>
          </ShowWhen>
          <ShowWhen show={!werehouse && !coordinacion}>
            <h4>Crear Warehouse</h4>
          </ShowWhen>
        </Row>
        <hr/>

        <Row gutter={[16, 24]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Fecha y Hora Recibido" name="fecha">
              <Input disabled required/>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Creado por" name="recivedBy">
              <Input disabled required/>
            </Form.Item>
          </Col>
        </Row>

        {/* COMPONER DE ULTIMO */}
        <Row gutter={[16, 24]}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <div className="d-flex align-items-center justify-content-between mb-1">
              <p className="mb-0">Shipper</p>
              <div>
                <Button type={'link'}
                        onClick={() => setAddSubasta(true)}>
                  <FontAwesomeIcon className="me-2" icon={faPlus} /> Agregar
                </Button>
                <Button type={'link'}
                        onClick={() => setEditSubasta(subasta)}>
                  <FontAwesomeIcon className="me-2" icon={faPencil} /> Modificar
                </Button>
              </div>
            </div>
            <Form.Item name="subastaId">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={handleSelectChangeSubs}
                onSearch={onSearch}
                filterOption={filterOption}
                defaultValue={{
                  label: "Seleccionar otro Shipper",
                  value: "empty",
                }}
                options={
                  subastas &&
                  subastas.length &&
                  subastas.map((subs) => ({
                    label: subs.subName + " - " + subs.direccion,
                    value: subs.subId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <div className="d-flex align-items-center justify-content-between mb-1">
              <p className="mb-0">Consignee</p>
              <div>
                <Button type={'link'}
                        onClick={() => setAddImportadora(true)}>
                  <FontAwesomeIcon className="me-2" icon={faPlus}/> Agregar
                </Button>
                <Button type={'link'}
                        onClick={() => setEditImportadora(importadora)}>
                  <FontAwesomeIcon className="me-2" icon={faPencil}/> Modificar
                </Button>
              </div>
            </div>
            <Form.Item label="" name="importadoraId">
              <Select
                showSearch
                optionFilterProp="children"
                onChange={handleSelectChangeImp}
                defaultValue={{
                  label: "Seleccionar otro Consignee",
                  value: "empty",
                }}
                name="importadoraId"
                onSearch={onSearch}
                filterOption={filterOption}
                options={
                  importadoras &&
                  importadoras.length &&
                  importadoras.map((imp) => ({
                    label: imp.impName + " - " + imp.direccion,
                    value: imp.id,
                  }))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16]}>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item label="Origen" name="origenName">
              <Input disabled />
            </Form.Item>
            <Form.Item name="idOrigen" hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <Form.Item label="Flete $" name="flete" rules={[{ required: true, message: 'Flete es requerido' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <Form.Item label="BL $" name="bl" hidden={!hasVehicle} rules={[{ required: hasVehicle, message: 'BL es requerido' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <Form.Item label="Grua $" name="grua" hidden={!hasVehicle} rules={[{ required: hasVehicle, message: 'Grua es requerido' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={4} md={4} sm={12} xs={24}>
            <Form.Item label="Buyer" name="buyer" hidden={!hasVehicle} rules={[{ required: hasVehicle, message: 'Buyer es requerido' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={8} xs={24}>
            <Form.Item label="Costo Adicional $" name="solicitudCostoAdicional">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={16} md={16} sm={16} xs={24}>
            <Form.Item label="Descripción del Costo Adicional" name="solicitudDescripcionCostoAdicional">
              <Input placeholder="descripción del costo adicional" />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={8} xs={24}>
            <Form.Item label="Costo Adicional 2 $" name="costoAdicional">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={16} md={16} sm={16} xs={24}>
            <Form.Item label="Descripción del Costo Adicional" name="descripcionCostoAdicional">
              <Input placeholder="descripción del costo adicional" />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="Destino" name="aduanaCom">
              <Input disabled/>
            </Form.Item>
            <Form.Item name="aduanaId" hidden>
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item label="  ">
              <Button type="primary" onClick={openDes} block>
                {" "}
                <FontAwesomeIcon
                  icon={faArrowTurnRight}
                  style={{
                    marginRight: 10,
                  }}
                />{" "}
                Actualizar aduana destino
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <ShowWhen show={hasVehicle}>
          <Row gutter={16}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <hr/>
              <p className="font-18 fw-bold">Datos del Vehículo</p>
              <Button
                className="mb-3"
                onClick={() => mostrarVehiculo(coordinacion ? coordinacion.vehiculoId : werehouse.vehiculoId)}
                type="primary"
                block
                icon={<FontAwesomeIcon icon={faCar} className="me-2" />}>
                Actualizar datos de vehículo
              </Button>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item label="VIN" name="vin">
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item label="Lote" name="lote">
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item label="Vehiculo" name="vehiculoName">
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item label="Color" name="color">
                <Input disabled/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item label="Titulo" name="titulo">
                <Switch disabled checked={vehiculo?.titulo} />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item label="BioH" name="bio">
                <Switch disabled checked={vehiculo?.bio} />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item label="LLave" name="llave">
                <Switch disabled checked={vehiculo?.llave} />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item label="Catalitico" name="catalitico">
                <Switch disabled checked={vehiculo?.catalitico} />
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item
                label="Peso (Kg)"
                name="weight"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese un peso",
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item
                label="Largo (In)"
                name="length"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese un largo",
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item
                label="Alto (In)"
                name="height"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese un alto",
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Form.Item
                label="Ancho (In)"
                name="width"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese un ancho",
                  },
                ]}
              >
                <Input disabled/>
              </Form.Item>
            </Col>
          </Row>
        </ShowWhen>

        <Row gutter={16}>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item
              label="Volumen (Ft3)"
              name="volume"
              // rules={[
              //   {
              //     required: true,
              //     message: "Por favor ingrese un ancho",
              //   },
              // ]}
            >
              <Input disabled/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Cantidad" name="quantity" rules={[{ required: true, message: "Por favor ingrese una cantidad" }]}>
              <Input type="number" value={werehouse?.quantity} disabled={hasVehicle} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Tiene POA?" name="poaW">
              <Switch checked={switchPoa} onChange={value => setSwitchPoa(value)} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Tiene Factura?" name="tieneFactura">
              <Switch checked={switchFactura} onChange={value => setSwitchFactura(value)} />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={12} xs={24}>
            <Form.Item label="Vehículo Nuevo" name="vehiculoNuevo">
              <Switch checked={switchVehiculoNuevo} onChange={value => setSwitchVehiculoNuevo(value)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label={'Tipo de Carga'} name="typeMerchId">
              <Select
                name="typeMerchId"
                options={
                  typemerch &&
                  typemerch.length &&
                  typemerch.map((tyme) => ({
                    label: tyme.typeMerchName,
                    value: tyme.typeMerchId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Numero de Titulo"
              name="numTitle"
              rules={[
                {
                  required: form.getFieldValue('titulo'),
                  message: "Por favor ingrese un numero de titulo",
                },
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item
              label="Estado del Título"
              name="stateId"
              rules={[
                {
                  required: tituloStateRequerido,
                  message: "Por favor seleccione un estado",
                },
              ]}
            >
              <Select
                showSearch={true}
                defaultValue={{
                  label: "Seleccionar otro estado",
                  value: "empty",
                }}
                filterOption={selectFilterOptions}
                options={
                  states &&
                  states.length &&
                  states.map((ste) => ({
                    label: `${ste?.smallState} - ${ste?.state}`,
                    value: ste.stateId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Form.Item label={hasVehicle ? 'Valor del Vehículo' : 'Valor de Carga'}
                       name="valorV"
                       rules={[{
                         required: true,
                         message: hasVehicle ? "Por favor ingrese un valor del vehículo" : "Por favor ingrese un valor de carga"
                       }]}>
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item label="Schedule B">
              <Space.Compact className="w-100">
                <Form.Item className="w-100"
                           name="schedulebId"
                           rules={[{ required: true, message: "Por favor seleccione un Schedule B" }]}>
                  <Select
                    className="w-100"
                    defaultValue={{
                      label: "Seleccionar otro Schedule B",
                      value: "empty",
                    }}
                    options={
                      (scheduleb ?? []).map((schb) => ({
                        label: `${schb.codigo} - ${schb?.description}`,
                        value: schb.schedulebId,
                      }))
                    }
                  />
                </Form.Item>
                <Button type="primary" onClick={() => {
                  setAddScheduleB(true);
                  formScheduleB.resetFields();
                }}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" /> Crear Schedule B
                </Button>
              </Space.Compact>
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              label="Subasta"
              name="auction"
              rules={[
                {
                  required: hasVehicle,
                  message: "Por favor ingrese una subasta del vehiculo",
                },
              ]}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              label="Origen"
              name="originId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un origen",
                },
              ]}
            >
              <Select
                name="originId"
                defaultValue={{
                  label: "Seleccionar otro origen",
                  value: "empty",
                }}
                value={werehouse?.originId}
                options={
                  origin &&
                  origin.length &&
                  origin.map((orn) => ({
                    label: orn.originName,
                    value: orn.originId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              label="Tipo de Licencia"
              name="licenceTypeId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un tipo de licencia",
                },
              ]}
            >
              <Select
                defaultValue={{
                  label: "Seleccionar otro tipo de Licencia",
                  value: "empty",
                }}
                options={
                  licencetype &&
                  licencetype.length &&
                  licencetype.map((lict) => ({
                    label: lict.typeCode,
                    value: lict.licenceTypeId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              label="Codigo de Exportacion"
              name="expoCodeId"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione un codigo de exportacion",
                },
              ]}
            >
              <Select
                defaultValue={{
                  label: "Seleccionar otro codigo de exportacion",
                  value: "empty",
                }}
                options={
                  codeexport &&
                  codeexport.length &&
                  codeexport.map((expco) => ({
                    label: expco.code,
                    value: expco.expoCodeId,
                  }))
                }
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              label="Numero de Licencia"
              name="licenceNum"
              rules={[
                {
                  required: hasVehicle,
                  message: "Por favor ingrese un numero de licencia",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <Form.Item
              label="Numero de Llave"
              name="numLlave"
              rules={[
                {
                  required: hasVehicle,
                  message: "Por favor ingrese un numero de llave",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={4} md={24} sm={24} xs={24}>
            <Form.Item label="Fotos" name="fotos">
              <Switch checked={switchFotos} onChange={value => setSwitchFotos(value)} />
            </Form.Item>
          </Col>
          <Col lg={10} md={12} sm={24} xs={24}>
            <Form.Item label="Link de Fotos" name="linkFotos">
              <Input.TextArea rows={4}/>
            </Form.Item>
          </Col>
          <Col lg={10} md={12} sm={24} xs={24}>
            <Form.Item
              label="Notas"
              name="notas"
            >
              <Input.TextArea
                rows={4}
                // onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <hr/>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <p className="mb-0 font-18 fw-bold">Datos Autoparts</p>
              <ShowWhen show={hasVehicle}>
                <Button type={'primary'} onClick={() => setAgregarExtraItem(true)}>
                  <FontAwesomeIcon className="me-2" icon={faPlus}/> Agregar Autopart
                </Button>
              </ShowWhen>
            </div>
            <ShowWhen show={!hasVehicle}>
              <ExtraItemReactiveForm extraItem={extraItemNoVehicle}
                                     showRequired={extraItemShowRequired}
                                     getExtraItem={extraItem => setExtraItemNoVehicle(extraItem)} />
            </ShowWhen>
            <ShowWhen show={hasVehicle}>
              <Table size={'small'}
                     dataSource={werehouse ? werehouse?.extraItems : extraItems}
                     columns={[
                       { key: 'actions', dataIndex: 'actions', title: '',
                         render: (_, element) => <Button danger={true} onClick={() => handleDeleteExtraPart(element)}><FontAwesomeIcon icon={faTrash} /></Button>
                       },
                       { key: 'extraItemName', dataIndex: 'extraItemName', title: 'Descripcion' },
                       { key: 'quantity', dataIndex: 'quantity', title: 'Cantidad' },
                       { key: 'weight', dataIndex: 'weight', title: 'Peso (Kg)' },
                     ]}/>
            </ShowWhen>
          </Col>
        </Row>

        <Row gutter={16} justify={"center"} className={"mt-3"}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Button type="primary" onClick={() => navigate(-1)} danger block>
              <FontAwesomeIcon icon={faLeftLong} style={{marginRight: 10}} /> Cancelar
            </Button>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Button
              type="primary"
              htmlType="submit"
              block
            >
              <FontAwesomeIcon
                icon={faFloppyDisk}
                style={{
                  marginRight: 10,
                }}
              />{" "}
              Guardar WR
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal open={addSubasta || !!editSubasta}
             title={addSubasta ? 'Crear Nuevo' : 'Modificar'}
             onCancel={() => {
               setAddSubasta(false);
               setEditSubasta(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <SubastaForm titulo={'Shipper'}
                     subasta={editSubasta}
                     afterSave={handleUpdateSubasta}
                     onCancel={() => {
                       setAddSubasta(false);
                       setEditSubasta(undefined);
                     }} />
      </Modal>

      <Modal open={addImportadora || !!editImportadora}
             title={addImportadora ? 'Crear Nuevo' : 'Modificar'}
             onCancel={() => {
               setAddImportadora(false);
               setEditImportadora(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm titulo={'Consignee'}
                         importadora={editImportadora}
                         afterSubmit={handleUpdateImportadora}
                         onCancel={() => {
                           setAddImportadora(false);
                           setEditImportadora(undefined);
                         }} />
      </Modal>

      <Modal
        title={`Editando Vehículo ${vehiculo?.make} ${vehiculo?.model} año ${vehiculo?.year}`}
        open={showVehicleModal}
        onCancel={handleCloseVehicleModal}
        footer={null}
        centered
        width={"50%"}
      >
        <VehicleWarehouseUpdate vehiculo={vehiculo}
                                onClose={handleCloseVehicleModal}
                                onUpate={handleUpdateVehiculo}></VehicleWarehouseUpdate>
      </Modal>

      <Modal
        title="Modificando Aduana Destino"
        open={showAduanaModal}
        onCancel={handleCloseAduanaModal}
        centered
        footer={null}
      >
        <AduanaSelector onClose={handleCloseAduanaModal}
                        onSelect={handleSelectAduana} />
      </Modal>

      <Modal title="Agregar Artículo Extra"
             open={agregarExtraItem}
             onCancel={() => setAgregarExtraItem(false)}
             footer={null}>
        <ExtraItemForm warehouseId={werehouse?.werehouseId}
                       onSubmit={handleAgregarExtraItem} />
      </Modal>

      <Modal title={'Crear Schedule B'}
             open={addScheduleB}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}
             onCancel={() => { setAddScheduleB(false) }}>
        <Spinner loading={showSpinner}>
          <Form layout={'vertical'} form={formScheduleB} onFinish={handleFinishScheduleB}>
            <Form.Item label={'Código'} name={'codigo'} rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Input placeholder={'Código'} />
            </Form.Item>
            <Form.Item label={'Descripción'} name={'description'} rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Input placeholder={'Descripción'} />
            </Form.Item>

            <div className="text-end">
              <Button type={'default'} className="me-2" onClick={() => { setAddScheduleB(false) }}>Cancelar</Button>
              <Button type={'primary'} htmlType={'submit'}>Guardar</Button>
            </div>
          </Form>
        </Spinner>
      </Modal>
    </Spinner>
  );
}

export default WarehouseForm;
