import {createContext, useContext, useEffect, useState} from "react";
import {
  getAduana,
  getColor,
  getContainer,
  getImportadora,
  getListCarrier, getPortCode,
  getSubastas
} from "../Servicios/ExportationServices";

const ExportationContext = createContext();

export const useExportationContext = () => useContext(ExportationContext);

export const ExportationProvider = ({ children }) => {
  const [errorExportation, setErrorExportation] = useState(null);
  const [loadingExportation, setLoadingExportation] = useState(true);

  const [colors, setColors] = useState([]);
  const [aduanas, setAduanas] = useState([]);
  const [subastas, setSubastas] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [portCodes, setPortCodes] = useState([]);
  const [containers, setContainers] = useState([]);
  const [importadoras, setImportadoras] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingExportation(true);

      try {
        const colors = await getColor();
        const aduanas = await getAduana();
        const subastas = await getSubastas();
        const carriers = await getListCarrier();
        const portCodes = await getPortCode();
        const containers = await getContainer();
        const importadoras = await getImportadora();

        setColors(colors);
        setAduanas(aduanas.filter(e => !e.isProvisional));
        setSubastas(subastas);
        setCarriers(carriers);
        setPortCodes(portCodes);
        setContainers(containers);
        setImportadoras(importadoras);

        setLoadingExportation(false);
      } catch (ex) {
        setErrorExportation(ex.toString());
        setLoadingExportation(false);
      }
    }

    fetchData().then();
  }, []);

  const updateImportadoras = async () => {
    const importadoras = await getImportadora();
    setImportadoras(importadoras);
  }

  return (
    <ExportationContext.Provider value={{
      colors,
      aduanas,
      subastas,
      carriers,
      portCodes,
      containers,
      importadoras,
      errorExportation,
      loadingExportation,
      updateImportadoras,
    }}>
      {children}
    </ExportationContext.Provider>
  );
}
