import React, {useState, useEffect} from "react";
import {Container, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import '../hojas-de-estilo/style.css';
import '../hojas-de-estilo/styleWR.css';
import {useParams, useNavigate} from "react-router-dom";
import {enviarEmailWarehouseReceipt, viewWR} from "../Servicios/WerehouseService";
import {faEnvelopeCircleCheck, faPrint, faLeftLong, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getPDFWRId} from "../Servicios/WerehouseService";
import getPdf from "../Servicios/Base64ToPdf";
import Spinner from "../shared/spinner";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import PdfViewer from "../Componentes/PdfViewer/PdfViewer";
import {errorMessage, successMessage} from "../utils/toast-message";
import {Button, Col, Row} from "antd";
import SimiButton from "../Componentes/SimiButton/SimiButton";

const VisualizarWR = () => {
  let {id} = useParams();
  const navigate = useNavigate();

  const [infowere, setInfowere] = useState([]);
  const [showT, setShowT] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);

  const handleCloseT = () => {
    setShowT(false);
    setToC([]);
    setCcC([]);
    setCcoC([]);
    setAttach([]);
    setSubject('');
  };

  const handleShowT = () => {
    setShowT(true);
    if (id !== undefined && id !== 0)
      getPDFWRId(id).then((pdf) => {
        const temp = [...attach]
        temp.push({nombreArchivo: 'Warehouse Receipt.pdf', archivo: "data:application/pdf;base64," + pdf})
        setAttach([...temp])
      });
    setSubject(`Vehiculo recibido en bodega Matus International - ${infowere?.origenName} - Lot#${infowere?.lote}`);
    setToC(currentToc => [...currentToc, infowere?.emailCli]);
  };

  const [to, setTo] = useState("");
  const [toC, setToC] = useState([]);

  const [cc, setCc] = useState("");
  const [ccC, setCcC] = useState([]);

  const [cco, setCco] = useState("");
  const [ccoC, setCcoC] = useState([]);

  const [attach, setAttach] = useState([]);
  const [subject, setSubject] = useState('');

  const agregarTo = (event) => {
    event.preventDefault();
    setToC(currentToc => [...currentToc, to]);
    setTo('');
  }

  const handleDeleteTO = (deletingItem) => {
    const lisTo = toC.filter((item) => item !== deletingItem);
    setToC(lisTo);
  }

  const agregarCA = () => {
    setCcC(currentCA => [...currentCA, "officecalifornia@matusintl.com"]);
  }
  const agregarFL = () => {
    setCcC(currentFL => [...currentFL, "warehousefl@matusintl.com"]);
  }
  const agregarCR = () => {
    setCcC(currentCR => [...currentCR, 'karla@matusintl.com', 'operacioncr@matusintl.com']);
  }
  const agregarHN = () => {
    setCcC(currentHN => [...currentHN, 'karla@matusintl.com', 'honduras@matusintl.com', 'ktorres@matusintl.com']);
  }
  const agregarGT = () => {
    setCcC(currentGT => [...currentGT, 'karla@matusintl.com', 'guatemala@matusintl.com', 'logisticagt@matusintl.com']);
  }
  const agregarSV = () => {
    setCcC(currentSV => [...currentSV, 'karla@matusintl.com', 'operacion@matusintl.com']);
  }

  const agregarCC = (event) => {
    event.preventDefault();
    setCcC(currentCC => [...currentCC, cc]);
    setCc('');
  }

  const handleDeleteCC = (deletingItem) => {
    const lisCC = ccC.filter((item) => item !== deletingItem);
    setCcC(lisCC);
  }

  const agregarCCO = (event) => {
    event.preventDefault();
    setCcoC(currentCCO => [...currentCCO, cco]);
    setCco('');
  }

  const handleDeleteCCO = (deletingItem) => {
    const lisCCO = ccoC.filter((item) => item !== deletingItem);
    setCcoC(lisCCO);
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      viewWR(id).then((were) => {
        setInfowere(were);
      });
      getPDFWRId(id).then((pdf) => {
        setLoading(false);
        setPdf(pdf);
      });
    }
  }, [id]);

  const handleChange = e => {
    setSubject(e.target.value);
  }

  const enviarCorreo = async () => {
    try {
      setLoading(true);
      await enviarEmailWarehouseReceipt({
        warehouseId: infowere?.werehouseId,
        countryCode: infowere?.country?.code,
        origenName: infowere?.origenName,
        vehiculoName: infowere?.vehiculoName,
        titulo: infowere?.titulo,
        llave: infowere?.llave,
        tieneFactura: infowere?.tieneFactura ? 'YES' : 'NO',
        poa: infowere?.poaWR,
        linkFotos: infowere?.linkFotos,
        to: toC,
        cc: ccC,
        cco: ccoC,
        subject: `Vehiculo recibido en bodega Matus International - ${infowere?.origenName} - Lot#${infowere?.lote}`
      });
      setLoading(false);

      successMessage("Correo Enviado");
      handleCloseT();
      setAttach([]);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const mostrarPDF = () => {
    getPdf('Warehouse Receipt', pdf);
  }

  return (
    <Spinner loading={loading}>
      <Container>
        <h4>Vehicle Receipt Format</h4>
        <hr/>
        <Row>
          <Col xs={12} lg="2">
            <div className="d-flex align-items-center justify-content-start gap-2">
              <SimiButton style={{width: '120px'}}
                          onClick={() => navigate(`/warehouseReceipt/${infowere?.idOrigen}`)}
                          type={'default'}
                          icon={faLeftLong}
                          color={'warning'}>
                Back
              </SimiButton>
              <SimiButton type={'default'} color={'success'} icon={faEnvelopeCircleCheck} onClick={handleShowT}>
                Send Email
              </SimiButton>
              <SimiButton onClick={mostrarPDF} icon={faPrint}>
                Print
              </SimiButton>
            </div>
          </Col>
        </Row>
        <hr/>
        <ShowWhen show={!!pdf}>
          <PdfViewer base64={pdf}/>
        </ShowWhen>
        <>
          <Modal
            show={showT}
            onHide={handleCloseT}
            backdrop="static"
            keyboard={false}
            className="emailsoli"
          >
            <Modal.Header closeButton>
              <Modal.Title>Enviando WR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row gutter={[16, 16]}>
                <Col xs={8} lg="8">
                  <SimiButton onClick={agregarCA} type={'dashed'} block={true}>+CC, CA</SimiButton>
                </Col>
                <Col xs={8} lg="8">
                  <SimiButton onClick={agregarFL} type={'dashed'} block={true}>+CC, FL</SimiButton>
                </Col>
                <Col xs={8} lg="8">
                  <SimiButton onClick={agregarSV} type={'dashed'} block={true}>+CC, SV</SimiButton>
                </Col>
                <Col xs={8} lg="8">
                  <SimiButton onClick={agregarGT} type={'dashed'} block={true}>+CC, GT</SimiButton>
                </Col>
                <Col xs={8} lg="8">
                  <SimiButton onClick={agregarCR} type={'dashed'} block={true}>+CC, CR</SimiButton>
                </Col>
                <Col xs={8} lg="8">
                  <SimiButton onClick={agregarHN} type={'dashed'} block={true}>+CC, HN</SimiButton>
                </Col>
              </Row>

              <Form.Label column={'sm'}><strong>Para:</strong></Form.Label>
              <ul className="list-group mb-3">
                {
                  toC?.map((item, index) => {
                    return <li key={index} className="list-group-item d-flex align-items-center justify-content-between">
                      {item}
                      <Button size={'small'} type={'default'} onClick={() => handleDeleteTO(item)}>x</Button>
                    </li>
                  })
                }
              </ul>
              <Row gutter={16}>
                <Col xs={18} lg="18">
                  <Form.Control value={to} onChange={(e) => setTo(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={6} lg="6">
                  <Button onClick={agregarTo} type={'primary'} block={true}>Agregar</Button>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Label column={'sm'}><strong>CC:</strong></Form.Label>
                  <ul className="list-group mb-3">
                    {
                      ccC?.map((item, index) => {
                        return <li key={index} className="list-group-item d-flex align-items-center justify-content-between">
                          {item}
                          <Button size={'small'} type={'default'} onClick={() => handleDeleteCC(item)}>x</Button>
                        </li>
                      })
                    }
                  </ul>
                </Col>
                <Col xs={18} lg="18">
                  <Form.Control value={cc} onChange={(e) => setCc(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={6} lg="6">
                  <Button onClick={agregarCC} type={'primary'} block={true}>Agregar</Button>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Label column={'sm'}><strong>CCO:</strong></Form.Label>
                  <ul className={'list-group mb-3'}>
                    {
                      ccoC?.map((item, index) => {
                        return <li key={index} className="list-group-item d-flex align-items-center justify-content-between">
                          {item}
                          <Button size={'small'} type={'default'} onClick={() => handleDeleteCCO(item)}>x</Button>
                        </li>
                      })
                    }
                  </ul>
                </Col>
                <Col xs={18} lg="18">
                  <Form.Control value={cco} onChange={(e) => setCco(e.target.value)} aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"/>
                </Col>
                <Col xs={6} lg="6">
                  <Button onClick={agregarCCO} type={'primary'} block={true}>Agregar</Button>
                </Col>
              </Row>
              <Form.Label column={'sm'} className="mt-3"><strong>Asunto:</strong></Form.Label>
              <Form.Control name="subject" onChange={handleChange} value={subject} as="textarea" rows={2} />

              <Form.Label column={'sm'} className="mt-3"><strong>Archivos Adjuntos</strong></Form.Label> <br/>
              {attach?.map((attach, key) => {
                return <li key={key}>{attach.id}{attach.nombreArchivo}</li>
              })}

              <Form.Label column={'sm'} className="mt-3"><strong>Mensaje</strong></Form.Label>
              <Form.Control disabled readOnly name="body"
                            value="**El mensaje esta predeterminado por el sistema con los datos ingresados en el WR**
        - Si agrega nuevos correos asegurarse esten bien escritos y aparezcan en la lista de agregados" as="textarea"
                            rows={3}/>

            </Modal.Body>
            <Modal.Footer>
              <Button type={'default'} onClick={handleCloseT}>
                Cancelar
              </Button>
              <Button onClick={enviarCorreo} type={'primary'} loading={loading}>
                <FontAwesomeIcon icon={faPaperPlane} className="me-2"/> Enviar
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Container>
    </Spinner>
  )
}

export default VisualizarWR;
