import React, {useState, useEffect} from 'react';
import {faLeftLong, faEye} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from 'react-toastify';
import '../hojas-de-estilo/style.css';
import Form from 'react-bootstrap/Form';
import {viewQuote} from '../Servicios/SolicitudesService';
import {Container, Col, Row} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import car from '../image/card.png';
import {permisos} from '../utils/permisos';
import {isSeller, validarPermisos} from '../Servicios/AuthService';
import LoadingInside from '../Componentes/LoadingInside';
import {SolicitudEstadoEnum} from "../shared/enums/solicitud-estado.enum";
import VehiculoForm from "../Componentes/VehiculoForm/VehiculoForm";
import {Modal} from "antd/lib"
import Spinner from "../shared/spinner";
import SolicitudForm from "./SolicitudForm";
import {successMessage} from "../utils/toast-message";
import {Tabs} from "antd";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import SolicitudVehiculoCorteForm from "./SolicitudVehiculoCorteForm";

const EditarQuote = () => {
  const {id} = useParams();
  const navigate = useNavigate();

  const [tab, setTab] = useState("1");
  const [items, setItems] = useState([]);
  const [cliente, setCliente] = useState(undefined);
  const [vehiculo, setVehiculo] = useState(undefined);
  const [cutVehicle, setCutVehicle] = useState(false);
  const [showModalVehicle, setShowModalVehicle] = useState(false);
  const [estadoPendienteCompletar, setEstadoPendienteCompletar] = useState(false);

  const handleCloseModalVehicle = () => setShowModalVehicle(false);

  const handleShowModalVehicle = () => setShowModalVehicle(true);

  const [newQuote, setNewQuote] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const dat = moment(newQuote?.fechaQ).format('ll');
  const puedeAgregarSolicitud = validarPermisos(permisos.AGREGAR_SOLICITUDES);

  const handleVehicleUpdated = vehicle => {
    successMessage("Se actualizo informacion de vehiculo");
    handleCloseModalVehicle();
    setVehiculo(vehicle);
  }

  useEffect(() => {
    const infoEdit = async () => {
      setIsLoading(true);
      const quo = await viewQuote(id);
      setIsLoading(false);

      setNewQuote(quo);
      setCliente(quo.clientes);
      setVehiculo(quo.vehiculo);
      setCutVehicle(quo.cutVehicle);
      setEstadoPendienteCompletar(quo.estado === SolicitudEstadoEnum.PendienteCompletar && isSeller());

      if (quo.cutVehicle) {
        setItems([{ key: "1", label: 'Solicitud' }, { key: "2", label: 'Verificación de Corte de Vehículo' }]);
      } else {
        setItems([]);
      }
    }

    infoEdit().then();
  }, [id]);

  const caredit = () => {
    handleShowModalVehicle();
  }

  if (!newQuote) {
    return (
      <LoadingInside />
    );
  }

  const handleFinishSolicitudForm = () => {
    navigate('/requests/list');
  }

  return (
    <Container>
      <Row>
        <h4>Editando solicitud Lote # {newQuote?.lote} del Cliente {cliente?.nombreCompleto} </h4>
        <Col>
          {puedeAgregarSolicitud &&
            <Button as={Link} style={{width: "150px"}} to='/requests/list' variant="warning">
              <FontAwesomeIcon icon={faLeftLong}/>
              &nbsp;Regresar
            </Button>
          }
        </Col>
      </Row>
      <br/>
      <Row className="justify-content-md-center">
        <hr/>
        <Col xs={12} md={12} lg="10">
          <Spinner loading={isLoading}>
            <ShowWhen show={items.length > 0}>
              <Tabs activeKey={tab} items={items} onChange={(tab) => setTab(tab)} />
            </ShowWhen>
            <ShowWhen show={tab === "1"}>
              <Card>
                <Card.Header as="h5">Datos Solicitud</Card.Header>
                <Card.Body>
                  <strong><Form.Label className='text-aling-left'>Fecha de Ingreso: {dat} </Form.Label></strong>
                  <Card className="text-center">
                    <Card.Header>
                      Vehiculo Info. {!estadoPendienteCompletar && <Button onClick={() => caredit()}
                                                                           className="ms-2"
                                                                           style={{
                                                                             width: '75px',
                                                                             height: '25px',
                                                                             padding: '0px'
                                                                           }}
                                                                           variant="secondary">Editar <FontAwesomeIcon
                      icon={faEye}/></Button>}
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Row className="justify-content-md-center">
                          <Col xs lg="2">
                            <img // eslint-disable-line jsx-a11y/alt-text
                              src={car}
                              style={{width: "100px", height: "100px"}}/>
                          </Col>
                          <Col xs lg="4">
                            <Form.Label className='text-aling-left'>#Lote: {vehiculo?.lote}</Form.Label> <br/>
                            <Form.Label className='text-aling-left'>Vehiculo: {vehiculo?.vehiculoName}</Form.Label> <br/>
                            <Form.Label className='text-aling-left'>#VIN: {vehiculo?.vin}</Form.Label>
                          </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <SolicitudForm solicitud={newQuote}
                                 vehiculo={vehiculo} onFinish={handleFinishSolicitudForm} />
                </Card.Body>
              </Card>
            </ShowWhen>
            <ShowWhen show={tab === "2"}>
              <Card>
                <Card.Header as="h5">Datos de Verificación de Corte de Vehículo</Card.Header>
                <Card.Body>
                  <SolicitudVehiculoCorteForm solicitud={newQuote}
                                              solicitudVehiculoCorte={newQuote.quoteVehiculoCorte}
                                              onFinish={() => {
                                                setTab("1");
                                              }} />
                </Card.Body>
              </Card>
            </ShowWhen>
          </Spinner>
        </Col>
      </Row>
      <Modal open={showModalVehicle}
             title={`Modificar Vehículo ${vehiculo?.make} ${vehiculo?.model} año ${vehiculo?.year}`}
             cancelButtonProps={{style: {display: "none"}}}
             okButtonProps={{style: {display: "none"}}}
             onCancel={() => handleCloseModalVehicle()}>
        <VehiculoForm vehicle={vehiculo}
                      onVehicleSaved={handleVehicleUpdated}/>
      </Modal>
    </Container>
  );
}

export default EditarQuote;
