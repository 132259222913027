import {useEffect, useState} from "react";
import {getExportationList} from "../../../Servicios/ExportationServices";
import {ExportationStateEnum} from "../../../shared/enums/exportation-state.enum";
import InputSearch from "../../../Componentes/InputSearch/InputSearch";
import Spinner from "../../../shared/spinner";
import ExportationTable from "../ExportationTable";
import useCurrentUser from "../../../Hooks/UserHook";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {useMainContext} from "../../../contexts/MainContext";
import ShowWhen from "../../../Componentes/ShowWhen/ShowWhen";
import localStorageFilter from "../../../shared/utils/local-storage-filter";
import {errorMessage} from "../../../utils/toast-message";
import {getCountriesId} from "../../../Servicios/AuthService";

interface Filters {
  pageCount: number;
  currentPage: number;
  search: string;
}

const ExportationCompletadosPage = ({ filtrarPorPais }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const { origenes, loadingMain  } = useMainContext();

  const [origenName, setOrigenName] = useState("");
  const [loading, setLoading] = useState(false);

  const [exportations, setExportations] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const [filters, setFilters] = useState({
    pageCount: localStorageFilter('exportationCompletados:filter', 'pageCount') ?? 15,
    currentPage: localStorageFilter('exportationCompletados:filter', 'currentPage') ?? 1,
    search: localStorageFilter('exportationCompletados:filter', 'search') ?? "",
  });

  useEffect(() => {
    if (currentUser) {
      initExportations(filters).then();
    }

    if (id) {
      setOrigenName(origenes.find(e => e.id === +id)?.nameOrigen ?? "");
    }

    // save filters
    const localFilters = localStorageFilter();
    if (JSON.stringify(localFilters) !== JSON.stringify(filters)) {
      localStorage.setItem('exportationCompletados:filter', JSON.stringify(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentUser, filters]);

  const initExportations = async (filters: Filters) => {
    try {
      const origen = id ?? +currentUser.idubc;
      const idCountries = getCountriesId() === "0" ? "" : getCountriesId();

      setLoading(true);
      const data = await getExportationList(
        filters.currentPage,
        filters.pageCount,
        origen,
        ExportationStateEnum.Completado,
        '',
        filters.search,
        filtrarPorPais ? idCountries : '',
        0,
        false);
      setLoading(false);
      setTotalElements(data?.totalElements);

      if (data && data.status) {
        setExportations(data.list.map((e, i) => ({...e, key: i})));
      }
    } catch (ex) {
      setLoading(false);
      errorMessage(`${ex.toString()}`);
    }
  }

  const handlePageChange = (page, pageSize) => {
    setFilters({
      ...filters,
      pageCount: pageSize,
      currentPage: page,
    });
  }

  const handleSearch = async (value) => {
    setFilters({
      ...filters,
      search: value,
      currentPage: 1,
    });
  }

  return(
    <>
      <Spinner loading={loading || loadingMain}>
        <div className="mb-3">
          <ShowWhen show={origenName === ""}>
            <h5>Exportaciones Completadas</h5>
          </ShowWhen>

          <ShowWhen show={origenName !== ""}>
            <h5>EXPORTACIONES - { origenName }</h5>
          </ShowWhen>
        </div>
        <div className="mb-3">
          <InputSearch placeholder={'Search by shipping name, booking number, vin or lot'}
                       showButton={true}
                       doChange={handleSearch} />
        </div>
        <ExportationTable pageCount={filters.pageCount}
                          currentPage={filters.currentPage}
                          totalElements={totalElements}
                          exportations={exportations}
                          onPageChange={handlePageChange}
                          onRefreshTable={() => initExportations(filters).then()}
                          onEditExportation={(exportation) => navigate(`/exportation/edit/${exportation.exportationId}`)} />
      </Spinner>
    </>
  );
}

export default ExportationCompletadosPage;
