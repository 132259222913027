import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import {Card} from "antd";
import {useEffect, useMemo, useState} from "react";
const localizer = momentLocalizer(moment);

const TransportistaCitasCalendar = ({ transporteCitas, onSelectTransporteCitas, onMonthChange }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(transporteCitas.map(e => ({
      id: e.id,
      monthKey: `${new Date(e.fecha).getFullYear()}-${new Date(e.fecha).getMonth() + 1}-${new Date(e.fecha).getDate()}`,
      title: `LOTE: ${e.coordinacion?.lote}`,
      transportista: e.coordinacion?.transporteName?.toUpperCase() ?? "",
      vehiculo: e.coordinacion?.vehiculoName?.toUpperCase() ?? "",
      start: new Date(e.fecha),
      end: new Date(e.fechaFinal),
    })))
  }, [transporteCitas]);

  const EventCard = ({ event }) => {
    return (
      <span>
        <p className="m-0 fw-bold font-12">{event.title}</p>
        <p className="m-0 font-12">{event.vehiculo}</p>
        <p className="m-0 font-12">{event.transportista}</p>
      </span>
    )
  }

  const { components } = useMemo(() => ({
    components: {
      event: EventCard,
    },
  }), []);

  const handleSelectSlot = (slotInfo) => {
    const selectedEvents = events
      .filter(e => e.monthKey === `${slotInfo.start.getFullYear()}-${slotInfo.start.getMonth() + 1}-${slotInfo.start.getDate()}`);
    onSelectTransporteCitas(transporteCitas.filter(e => selectedEvents.some(i => e.id === i.id)));
  }

  const handleSelectEvent = (event) => {
    handleSelectSlot({ start: event.start });
  }

  return(
    <>
      <Card className="card-calendar">
        <div className="h-100">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            components={components}
            min={new Date(1972, 0, 1, 7, 0, 0)}
            max={new Date(1972, 0, 1, 18, 0, 0)}
            showAllEvents={true}
            selectable={true}
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            onNavigate={onMonthChange}
          />
        </div>
      </Card>
    </>
  );
}

export default TransportistaCitasCalendar;
