import {Button, Dropdown, Modal, Table} from "antd";
import {DetalleExportacionStateEnum} from "../../shared/enums/detalle-exportacion-state.enum";
import {
  enviarEmailNotificacionArribo,
  enviarEmailNotificacionEmbarque,
  getBillOfLading, getCargoManifest,
  getCartaEndoso,
  getCoverLetter, getExportationDetailById,
  getItn,
  getNotificacionArribo,
  getNotificacionEmbarque,
  updateDetalleExportBillLadingInfo,
  updateDetalleExportConsignatarioFinal,
  updateStatusDetailExportation
} from "../../Servicios/ExportationServices";
import {errorMessage, successMessage} from "../../utils/toast-message";
import {useState} from "react";
import useCurrentUser from "../../Hooks/UserHook";
import PdfReport from "../../utils/pdfReport";
import {useNavigate} from "react-router-dom";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {exportationContainerStateEnum} from "../../shared/enums/exportation-container-state.enum";
import ShowWhen from "../../Componentes/ShowWhen/ShowWhen";
import SendEmail from "../../utils/email";
import {validarPermisos} from "../../Servicios/AuthService";
import {permisos} from "../../utils/permisos";
import {CountryCodeEnum} from "../../shared/enums/country-code.enum";
import InputSearchImportadora from "../../Componentes/InputSearchImportadora/InputSearchImportadora";
import Spinner from "../../shared/spinner";
import ImportadoraForm from "../../Componentes/ImportadoraForm/ImportadoraForm";
import ConsignatarioFinalForm from "../../Componentes/ConsignatarioFinalForm/ConsignatarioFinalForm";
import DetalleExportationBillLadingForm from "./DetalleExportationBillLadingForm";
import environment from "../../environments/environment";

const DetalleExportationTable = ({ detalleExportations, onRefreshTable }) => {
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const [emailCc, setEmailCc] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [dataReport, setDataReport] = useState("");

  const [importadora, setImportadora] = useState(undefined);
  const [detalleExport, setDetalleExport] = useState(undefined);
  const [importadoraEdit, setImportadoraEdit] = useState(undefined);
  const [notificationAttachment, setNotificationAttachment] = useState(undefined);

  const [showAduanaEstadoModal, setShowAduanaEstadoModal] = useState(false);
  const [showArriboModalEmail, setShowArriboModalEmail] = useState(false);
  const [showEmbarqueModalEmail, setShowEmbarqueModalEmail] = useState(false);
  const [importadoraFinalEdit, setImportadoraFinalEdit] = useState(false);
  const [showInformacionBlForm, setShowInformacionBlForm] = useState(false);

  const items = element => {
    const items = [
      {
        key: '1',
        label: 'Generar Cover Letter',
        onClick: () => handleCoverLetter(element),
        visible: element?.containerState !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_GENERAR_COVER_LETTER)
      },
      {
        key: '2',
        label: 'Generar Bill of Lading',
        onClick: () => handleBillOfLading(element),
        visible: element?.containerState !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_GENERAR_BILL_LADING)
      },
      {
        key: '3',
        label: 'Send to AES',
        onClick: () => navigate(`/sendToAES/${element.shipmentNumber}`),
        visible: element.detalleState === DetalleExportacionStateEnum.NoEnviado &&
          element.containerState !== exportationContainerStateEnum.creado
      },
      {
        key: '4',
        label: 'Validar ITN',
        onClick: () => handleValidarITN(element),
        visible: element.detalleState === DetalleExportacionStateEnum.Enviado
      },
      {
        key: '5',
        label: 'Enviar Notificación de Embarque',
        onClick: () => handleNotificacionEmbarque(element),
        visible: element?.containerState !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_ENVIAR_NOTIFICACION_EMBARQUE)
      },
      {
        key: '6',
        label: 'Enviar Notificación de Arribo',
        onClick: () => handleNotificacionArribo(element),
        visible: element?.containerState !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_ENVIAR_NOTIFICACION_ARRIBO)
      },
      {
        key: '7',
        label: 'Cambiar Consignatario del Cover Letter',
        onClick: () => handleModificarImportadora(element),
        visible: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER, permisos.DETALLE_EXPORT_MODIFICAR_CONSIGNATARIO) &&
          element?.containerState === exportationContainerStateEnum.asignado &&
          element?.detalleState === DetalleExportacionStateEnum.Aceptado
      },
      {
        key: '8',
        label: 'Modificar Información del Consignatario actual',
        onClick: () => handleModificarImportadoraEdit(element),
        visible: validarPermisos(permisos.EXPORTACION_COMPLETADO_VER, permisos.DETALLE_EXPORT_MODIFICAR_CONSIGNATARIO) &&
          element?.containerState === exportationContainerStateEnum.asignado &&
          element?.detalleState === DetalleExportacionStateEnum.Aceptado
      },
      {
        key: '9',
        label: 'Consultar estado de envio a Aduana',
        onClick: () => handleMostrarEstadoAduana(element),
        visible: element?.detalleState === DetalleExportacionStateEnum.Enviado
      },
      {
        key: '10',
        label: 'Agregar Consignatario Final',
        onClick: () => handleModificarImportadoraFinalEdit(element),
        visible: validarPermisos(permisos.DETALLE_EXPORT_AGREGAR_CONSIGNATARIO_FINAL) &&
          element?.containerState !== exportationContainerStateEnum.creado &&
          element?.detalleState === DetalleExportacionStateEnum.Aceptado &&
          (element?.countryCode === CountryCodeEnum.GT || element?.countryCode === CountryCodeEnum.CR)
      },
      {
        key: '11',
        label: 'Generar Carta de Endoso',
        onClick: () => handleCartaEndoso(element),
        visible: element?.containerState !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_GENERAR_CARTA_ENDOSO_GT) &&
          element?.countryCode === CountryCodeEnum.GT
      },
      {
        key: '12',
        label: 'Modificar Información del BL',
        onClick: () => handleModificarInformacionBl(element),
        visible: element?.containerState !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_GENERAR_BILL_LADING)
      },
      {
        key: '13',
        label: 'Generar Manifiesto de Carga',
        onClick: () => handleCargoManifest(element),
        visible: element?.containerState !== exportationContainerStateEnum.creado &&
          validarPermisos(permisos.DETALLE_EXPORT_GENERAR_CARGO_MANIFEST_SV) &&
          element?.countryCode === CountryCodeEnum.SV
      },
    ];

    return items.filter(e => e.visible).map(e => ({
      key: e.key,
      label: e.label,
      onClick: e.onClick,
    }));
  }

  const columns = [
    {
      title: "",
      width: "7%",
      dataIndex: "options",
      key: "options",
      render: (_, element) => (
        <ShowWhen show={items(element).length > 0}>
          <Dropdown
            trigger={['click']}
            menu={{ items: items(element) }}
            placement="bottomLeft"
          >
            <Button shape="circle" type={'primary'}><FontAwesomeIcon size={'sm'} icon={faBars} /></Button>
          </Dropdown>
        </ShowWhen>
      ),
    },
    {
      title: "Shipment #",
      width: "12%",
      dataIndex: "shipmentNumber",
      key: "shipmentNumber",
      render: (value) =><p className="font-12 mb-0">{value}</p>
    },
    {
      title: "Warehouse",
      width: "12%",
      dataIndex: "warehouse",
      key: "warehouse",
      render: (_, element) => {
        return(
          <>
            <ShowWhen show={element?.lote}>
              <p className="font-12 mb-0">Lote: {element?.lote}</p>
            </ShowWhen>
            <p className="font-12 mb-0">{element?.werehouseCode}</p>
          </>
        );
      },
    },
    {
      title: "Vehículo",
      width: "16%",
      dataIndex: "vehicle",
      key: "vehicle",
      render: (_, element) => {
        if (element?.vin)
          return(
            <>
              <p className="font-12 mb-0">VIN: {element?.vin}</p>
              <p className="font-12 mb-0">{element?.year} {element?.make} {element?.model}</p>
            </>
          )

        return(
          <>
            <p className="font-12 mb-0">{element?.extraItems}</p>
          </>
        )
      },
    },
    {
      title: "Importadora",
      width: "14%",
      dataIndex: "carrier",
      key: "carrier",
      render: (_, element) => {
        return(<>
          <ShowWhen show={element?.importadoraIdentification}>
            <p className="font-12 mb-0 text-uppercase">
              {element?.importadoraIdentificationType}: {element?.importadoraIdentification}
            </p>
          </ShowWhen>
          <p className="font-12 mb-0 text-uppercase">{element?.importadora}</p>
        </>)
      }
    },
    {
      title: "#",
      width: "16%",
      dataIndex: "containerNumber",
      key: "containerNumber",
      render: (_, element) => {
        return(
          <>
            <p className="font-12 mb-0"><span className="fw-bold"># de Contenedor: </span>{element?.containerNumber}</p>
            <p className="font-12 mb-0"><span className="fw-bold"># de Sello: </span>{element?.sealNumber}</p>
          </>
        );
      },
    },
    {
      title: "ITN",
      width: "13%",
      dataIndex: "itn",
      key: "itn",
      render: (_, element) => <p className="font-12 mb-0">{element?.itn}</p>,
    },
    {
      title: "Estado",
      width: "10%",
      dataIndex: "estado",
      key: "estado",
      render: (_, element) => {
        const bg = element?.detalleState === DetalleExportacionStateEnum.Enviado
          ? 'bg-info'
          : element?.detalleState === DetalleExportacionStateEnum.Aceptado
            ? 'bg-success'
            : 'bg-danger';

        return <div className={'badge ' + bg}>
          {element?.detalleState}
        </div>
      },
    }
  ];

  const handleValidarITN = async (element) => {
    setLoading(true);
    const data = await getItn(element.shipmentNumber);
    setLoading(false);

    if (data && data.status) {
      successMessage('ITN actualizado exitosamente');
      if (onRefreshTable) onRefreshTable();
    } else {
      errorMessage(data.message);
    }
  }

  const handleBillOfLading = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setVisible(true);
      const res = await getBillOfLading(exportationDetail.codeExpSecuential);
      setDataReport(res);
    } catch (ex) {
      setLoading(false);
      setVisible(false);
      errorMessage(ex.toString());
    }
  }

  const handleCartaEndoso = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setVisible(true);
      const res = await getCartaEndoso(exportationDetail.codeExpSecuential);
      setDataReport(res);
    } catch (ex) {
      setLoading(false);
      setVisible(false);
      errorMessage(ex.toString());
    }
  }

  const handleCargoManifest = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setVisible(true);
      const res = await getCargoManifest(exportationDetail.codeExpSecuential);
      setDataReport(res);
    } catch (ex) {
      setLoading(false);
      setVisible(false);
      errorMessage(ex.toString());
    }
  }

  const handleModificarInformacionBl = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setShowInformacionBlForm(true);
      setDetalleExport(exportationDetail);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleCoverLetter = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setVisible(true);
      const res = await getCoverLetter(exportationDetail.codeExpSecuential, currentUser);
      setDataReport(res);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleNotificacionEmbarque = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setVisible(true);
      const res = await getNotificacionEmbarque(exportationDetail.codeExpSecuential);
      setVisible(false);

      if (!environment.production) {
        setEmailCc("manuelpc2495@gmail.com");
      } else if (exportationDetail.werehouse.country.code === CountryCodeEnum.SV) {
        setEmailCc("operacion@matusintl.com");
      } else if (exportationDetail.werehouse.country.code === CountryCodeEnum.HN) {
        setEmailCc("honduras@matusintl.com");
      } else if (exportationDetail.werehouse.country.code === CountryCodeEnum.CR) {
        setEmailCc("operacioncr@matusintl.com");
      } else if (exportationDetail.werehouse.country.code === CountryCodeEnum.GT) {
        setEmailCc("Guatemala@matusintl.com");
      }

      setDataReport(res);
      setDetalleExport(exportationDetail);
      setShowEmbarqueModalEmail(true);
      setNotificationAttachment([
        {
          nombreArchivo: `aviso_embarque_${exportationDetail?.codeExpSecuential ?? ""}.pdf`,
          archivo: "data:aplication/pdf;base64," + res,
        }
      ]);
    } catch (ex) {
      setLoading(false);
      setVisible(false);
      errorMessage(ex.toString());
    }
  }

  const handleNotificacionArribo = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setVisible(true);
      const res = await getNotificacionArribo(exportationDetail.codeExpSecuential);
      setVisible(false);

      if (!environment.production) {
        setEmailCc("manuelpc2495@gmail.com");
      } else if (exportationDetail.werehouse.country.code === CountryCodeEnum.SV) {
        setEmailCc("operacion@matusintl.com");
      } else if (exportationDetail.werehouse.country.code === CountryCodeEnum.HN) {
        setEmailCc("honduras@matusintl.com");
      } else if (exportationDetail.werehouse.country.code === CountryCodeEnum.CR) {
        setEmailCc("operacioncr@matusintl.com");
      } else if (exportationDetail.werehouse.country.code === CountryCodeEnum.GT) {
        setEmailCc("Guatemala@matusintl.com");
      }

      setDataReport(res);
      setDetalleExport(exportationDetail);
      setShowArriboModalEmail(true);

      setNotificationAttachment([
        {
          nombreArchivo: `aviso_arribo_${exportationDetail?.codeExpSecuential ?? ""}.pdf`,
          archivo: "data:aplication/pdf;base64," + res,
        }
      ]);
    } catch (ex) {
      setLoading(false);
      setVisible(false);
      errorMessage(ex.toString());
    }
  }

  const handleModificarImportadora = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setImportadora(exportationDetail?.coverLetterConsignee ? exportationDetail?.coverLetterConsignee : exportationDetail?.werehouse?.importadora);
      setDetalleExport(exportationDetail);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleModificarImportadoraEdit = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setImportadoraEdit(exportationDetail?.coverLetterConsignee ? exportationDetail?.coverLetterConsignee : exportationDetail?.werehouse?.importadora);
      setDetalleExport(exportationDetail);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleModificarImportadoraFinalEdit = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setImportadoraFinalEdit(true);
      setDetalleExport(exportationDetail);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleMostrarEstadoAduana = async (element) => {
    try {
      setLoading(true);
      const exportationDetail = await getExportationDetailById(element.idExportationDetail);
      setLoading(false);

      setDetalleExport(exportationDetail);
      setShowAduanaEstadoModal(true);
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleOcultarEstadoAduana = () => {
    setDetalleExport(undefined);
    setShowAduanaEstadoModal(false);
  }

  const handleSelectImportadora = async (importadora) => {
    const input = {
      ...detalleExport,
      werehouseId: detalleExport?.werehouse?.werehouseId,
      letter: detalleExport?.exportationContainer?.letter,
      consigneeId: importadora?.id
    };

    setLoading(true);
    await updateStatusDetailExportation(input, detalleExport?.detalleExportId)
    setLoading(false);

    successMessage('Importadora actualizada con exito');
    setImportadora(undefined);
    setDetalleExport(undefined);
    if (onRefreshTable) onRefreshTable();
  }

  const handleConsignatarioFinal = async (values) => {
    try {
      setLoading(true);
      await updateDetalleExportConsignatarioFinal(detalleExport?.detalleExportId, values);
      setLoading(false);

      setDetalleExport(undefined);
      setImportadoraFinalEdit(false);
      if (onRefreshTable) onRefreshTable();
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleSubmitBillLadingForm = async (values) => {
    setDetalleExport(undefined);
    setShowInformacionBlForm(false);

    try {
      setLoading(true);
      await updateDetalleExportBillLadingInfo(detalleExport?.detalleExportId, values)
      setLoading(false);

      successMessage('Información actualizada correctamente');
      if (onRefreshTable) onRefreshTable();
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleCloseNotificationModal = () => {
    setDataReport("");
    setDetalleExport(undefined);
    setNotificationAttachment(undefined);

    setShowArriboModalEmail(false);
    setShowEmbarqueModalEmail(false);
  }

  const handleEnviarNotificacionEmbarque = async ({ to, cc, cco }) => {
    const phoneNumber = {
      [CountryCodeEnum.SV]: "+503 79851567",
      [CountryCodeEnum.HN]: "+504 88191095",
      [CountryCodeEnum.GT]: "+502 55112911",
      [CountryCodeEnum.CR]: "+506 87567950",
    }

    const whatsapp = {
      [CountryCodeEnum.SV]: "https://wa.me/50379851567",
      [CountryCodeEnum.HN]: "https://wa.me/50488191095",
      [CountryCodeEnum.GT]: "https://wa.me/50255112911",
      [CountryCodeEnum.CR]: "https://wa.me/50687567950",
    }

    const input = {
      codeExpSecuential: detalleExport.codeExpSecuential,
      country: detalleExport?.aduana?.countryName,
      whatsapp: whatsapp[detalleExport?.werehouse?.country?.code] ?? "",
      phoneNumber: phoneNumber[detalleExport?.werehouse?.country?.code] ?? "",
      countryCode: detalleExport?.werehouse?.country?.code,
      to: to,
      cc: cc,
      cco: cco,
      subject: `Aviso de Embarque Lote ${detalleExport?.werehouse?.lote} / ${detalleExport?.codeExpSecuential}`
    };

    try {
      setLoading(true);
      await enviarEmailNotificacionEmbarque(input);
      setLoading(false);

      successMessage('Correo enviado correctamente');
      handleCloseNotificationModal();
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  const handleEnviarNotificacionArribo = async ({ to, cc, cco }) => {
    const phoneNumber = {
      [CountryCodeEnum.SV]: "+503 79851567",
      [CountryCodeEnum.HN]: "+504 88191095",
      [CountryCodeEnum.GT]: "+502 55112911",
      [CountryCodeEnum.CR]: "+506 87567950",
    }

    const whatsapp = {
      [CountryCodeEnum.SV]: "https://wa.me/50379851567",
      [CountryCodeEnum.HN]: "https://wa.me/50488191095",
      [CountryCodeEnum.GT]: "https://wa.me/50255112911",
      [CountryCodeEnum.CR]: "https://wa.me/50687567950",
    }

    const link = {
      [CountryCodeEnum.SV]: "https://drive.google.com/drive/folders/1v6_yQrpLbQDxXWwTX3jNUihFmApaY86S?usp=drive_link",
      [CountryCodeEnum.HN]: "https://drive.google.com/drive/folders/1zdt2yZt56cKaF1LRZ_JDlDJk-_D-xqG5?usp=drive_link",
      [CountryCodeEnum.GT]: "https://drive.google.com/drive/folders/1texM-qhk4jvX6n8J5m9-_SB95PTvq8cM?usp=drive_link",
      [CountryCodeEnum.CR]: "https://drive.google.com/drive/folders/1G6REpox_CBr-vIjKdmIgnA7hZEFEyAbe",
    }

    const input = {
      codeExpSecuential: detalleExport.codeExpSecuential,
      link: link[detalleExport?.werehouse?.country?.code] ?? "",
      whatsapp: whatsapp[detalleExport?.werehouse?.country?.code] ?? "",
      phoneNumber: phoneNumber[detalleExport?.werehouse?.country?.code] ?? "",
      countryCode: detalleExport?.werehouse?.country?.code,
      to: to,
      cc: cc,
      cco: cco,
      subject: `Aviso de Arribo Lote ${detalleExport?.werehouse?.lote} / ${detalleExport?.codeExpSecuential}`
    };

    try {
      setLoading(true);
      await enviarEmailNotificacionArribo(input);
      setLoading(false);

      successMessage('Correo enviado correctamente');
      handleCloseNotificationModal();
    } catch (ex) {
      setLoading(false);
      errorMessage(ex.toString());
    }
  }

  return(
    <>
      <Table size={'small'}
             loading={loading}
             columns={columns}
             dataSource={detalleExportations}
             pagination={false} />
      <PdfReport visible={visible} setVisible={setVisible} data={dataReport} setData={setDataReport} />
      <ShowWhen show={showEmbarqueModalEmail && detalleExport}>
        <SendEmail base64Pdf={dataReport}
                   visible={showEmbarqueModalEmail}
                   setVisible={handleCloseNotificationModal}
                   titulo={`Enviar Notificación de Embarque`}
                   to={[environment.production ? detalleExport?.werehouse?.emailCli : "manuelpc2495@gmail.com"]}
                   cc={emailCc === "" ? [] : [emailCc]}
                   cco={[]}
                   isLoading={loading}
                   subject={`Aviso de Embarque Lote ${detalleExport?.werehouse?.lote} / ${detalleExport?.codeExpSecuential}`}
                   body={''}
                   showMessageInput={false}
                   attachments={notificationAttachment}
                   doSendEmail={handleEnviarNotificacionEmbarque}
                   avoidExecuteService={true}
        />
      </ShowWhen>
      <ShowWhen show={showArriboModalEmail && detalleExport}>
        <SendEmail base64Pdf={dataReport}
                   visible={showArriboModalEmail}
                   setVisible={handleCloseNotificationModal}
                   titulo={`Enviar Notificación de Arribo`}
                   to={[environment.production ? detalleExport?.werehouse?.emailCli : "manuelpc2495@gmail.com"]}
                   cc={emailCc === "" ? [] : [emailCc]}
                   cco={[]}
                   isLoading={loading}
                   subject={`Aviso de Arribo Lote ${detalleExport?.werehouse?.lote} / ${detalleExport?.codeExpSecuential}`}
                   body={''}
                   showMessageInput={false}
                   attachments={notificationAttachment}
                   avoidExecuteService={true}
                   doSendEmail={handleEnviarNotificacionArribo}
        />
      </ShowWhen>
      <Modal open={!!importadora}
             title={'Modificar Consignatario'}
             onCancel={() => {
               setImportadora(undefined);
               setDetalleExport(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <br/>
        <Spinner loading={loading}>
          <InputSearchImportadora selectedImportadora={importadora}
                                  onSelectImportadora={handleSelectImportadora}/>
        </Spinner>
      </Modal>
      <Modal open={!!importadoraEdit}
             title={'Modificar Consignatario'}
             onCancel={() => {
               setDetalleExport(undefined);
               setImportadoraEdit(undefined);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ImportadoraForm importadora={importadoraEdit}
                         afterSubmit={() => {
                           setDetalleExport(undefined);
                           setImportadoraEdit(undefined);
                           if (onRefreshTable) onRefreshTable();
                         }}
                         onCancel={() => {
                           setDetalleExport(undefined);
                           setImportadoraEdit(undefined);
                         }} />
      </Modal>
      <Modal open={!!importadoraFinalEdit}
             title={'Modificar Consignatario Final'}
             onCancel={() => {
               setDetalleExport(undefined);
               setImportadoraFinalEdit(false);
             }}
             okButtonProps={{style: {display: "none"}}}
             cancelButtonProps={{style: {display: "none"}}}>
        <ConsignatarioFinalForm consigneeName={detalleExport?.consigneeName}
                                consigneeAddress={detalleExport?.consigneeAddress}
                                consigneeIdentification={detalleExport?.consigneeIdentification}
                                afterSubmit={(values) => {
                                  handleConsignatarioFinal(values).then();
                                }}
                                onCancel={() => {
                                  setDetalleExport(undefined);
                                  setImportadoraFinalEdit(false);
                                }} />
      </Modal>
      <ShowWhen show={detalleExport && showAduanaEstadoModal}>
        <Modal open={showAduanaEstadoModal}
               width={'90%'}
               onCancel={handleOcultarEstadoAduana}
               onOk={handleOcultarEstadoAduana}
               title={`AES STATUS ${detalleExport?.codeExpSecuential}`}>
          <iframe width={'100%'}
                  title={'Status'}
                  height={'400px'}
                  src={`https://aes.gsf24.com/aesstatus.aspx?customercode=MATUS&hbl=${detalleExport?.codeExpSecuential}`}></iframe>
        </Modal>
      </ShowWhen>

      <ShowWhen show={detalleExport && showInformacionBlForm}>
        <Modal title={'Modificar Información del BL'}
               onCancel={() => {
                 setDetalleExport(undefined);
                 setShowInformacionBlForm(false);
               }}
               cancelButtonProps={{ style: { display: "none" } }}
               okButtonProps={{ style: { display: "none" } }}
               open={showInformacionBlForm}>
          <DetalleExportationBillLadingForm detalleExport={detalleExport} onSubmit={handleSubmitBillLadingForm} />
        </Modal>
      </ShowWhen>
    </>
  );
}

export default DetalleExportationTable;
