import React, {useEffect, useState} from 'react'
import {Modal, Button, Form, Input, Tag, Space, Spin, Row, Col} from 'antd'
import { DeleteFilled, PlusOutlined} from '@ant-design/icons'
import { enviarEmail } from '../Servicios/SolicitudesService'
import {errorMessage, successMessage} from "./toast-message";
import Spinner from "../shared/spinner";
import ShowWhen from "../Componentes/ShowWhen/ShowWhen";
import PdfViewer from "../Componentes/PdfViewer/PdfViewer";

const SendEmail = ({ visible,
                     setVisible,
                     titulo = 'Envio de correo',
                     to = [],
                     cc = [],
                     cco = [],
                     body = '',
                     subject = '',
                     attachments = [],
                     isLoading,
                     showMessageInput = true,
                     doSendEmail,
                     base64Pdf,
                     avoidExecuteService,
                   }) => {

  const [form] = Form.useForm()
  
  const [emailsTo, setEmailsTo] = useState(to ?? []);
  const [emailsCc, setEmailsCc] = useState(cc ?? []);
  const [emailsCco, setEmailsCco] = useState(cco ?? []);
  const [loading, setLoading] = useState(false);

  const [inputValueTo, setInputValueTo] = useState('');
  const [inputValueCc, setInputValueCc] = useState('');
  const [inputValueCco, setInputValueCco] = useState('');

  useEffect(() => {
    console.log(to);
    setEmailsTo([...to]);
  }, [to]);

  const warning = () => {
    Modal.warning({
      title: '¿Está seguro de cerrar el modal de envio de correo?',
      content: 'Si cierra el modal de correo no se enviará el correo y no se podrá recuperar. ¿Desea cerrar el modal?',
      onCancel() {
        console.log('Cancel');
      },
      onOk() {
        setVisible(false)
      },
      okText: 'Si cerrar',
      cancelText: 'No cerrar',
      okCancel: true,
    });
  };

  const onFinish = async (values) => {
    // ejecuta el boton "Enviar Correo" sin ejecutar el servicio de "enviarEmail"
    if (avoidExecuteService) {
      if (doSendEmail) {
        doSendEmail({ to: emailsTo, cc: emailsCc, cco: emailsCco });
      }
      return;
    }

    setLoading(true);
    const item = await enviarEmail({
      to: emailsTo,
      cc: emailsCc,
      cco: emailsCco,
      body: body ?? values.message,
      subject: subject,
      attachedFile: attachments
    });
    setLoading(false);

    if (item && item.status) {
      if (doSendEmail) {
        doSendEmail();
      }

      successMessage('Correo enviado correctamente');
      setVisible(false);
    } else {
      errorMessage(item.message);
    }
  }

  const onCancel = () => {
    // setVisible(false)

    // add new modal to confirm if user wants to close the modal
    warning();
  }


  const handleInputChangeTo = (e) => {
    setInputValueTo(e.target.value);
  };

  const handleInputConfirmTo = () => {
    if (inputValueTo && !emailsTo.includes(inputValueTo)) {
      setEmailsTo([...emailsTo, inputValueTo]);
      form.setFieldsValue({ to: '' });
      setInputValueTo('');
    }
  };

  const handleEmailRemoveTo = (removedEmail) => {
    const updatedEmails = emailsTo.filter(email => email !== removedEmail);
    setEmailsTo(updatedEmails);
    
  };


  const handleInputChangeCc = (e) => {
    setInputValueCc(e.target.value);
  }

  const handleInputConfirmCc = () => {
    if (inputValueCc && !emailsCc.includes(inputValueCc)) {
      setEmailsCc([...emailsCc, inputValueCc]);
      form.setFieldsValue({ cc: '' });
      setInputValueCc('');
    }
  }

  const handleEmailRemoveCc = (removedEmail) => {
    const updatedEmails = emailsCc.filter(email => email !== removedEmail);
    setEmailsCc(updatedEmails);
  }


  const handleInputChangeCco = (e) => {
    setInputValueCco(e.target.value);
  }

  const handleInputConfirmCco = () => {
    if (inputValueCco && !emailsCco.includes(inputValueCco)) {
      setEmailsCco([...emailsCco, inputValueCco]);
      form.setFieldsValue({ cco: '' });
      setInputValueCco('');
    }
  }

  const handleEmailRemoveCco = (removedEmail) => {
    const updatedEmails = emailsCco.filter(email => email !== removedEmail);
    setEmailsCco(updatedEmails);
  }

  return (
    <Modal title={titulo} open={visible} footer={null} width={base64Pdf ? '90%' : '40%'} onCancel={onCancel} maskClosable={false}>
      <Spin spinning={isLoading} tip="Cargando...">
        <Spinner loading={loading}>
          <Row gutter={16}>
            <ShowWhen show={!!base64Pdf}>
              <Col xs={16}>
                <p className="mb-2">Archivo Adjunto</p>
                <PdfViewer base64={base64Pdf}/>
              </Col>
            </ShowWhen>
            <Col xs={base64Pdf ? 8 : 24}>
              <Form form={form} name="control-hooks" onFinish={onFinish} layout={'vertical'}>
                {/*TO*/}
                <Form.Item
                  name="to"
                  label="TO:"
                  rules={[
                    {
                      required: emailsTo.length === 0,
                      message: 'Por favor ingrese un correo electronico',
                    },
                    {
                      type: 'email',
                      message: 'Por favor ingrese un correo electronico valido',
                    }
                  ]}
                  extra={
                    <>
                      {emailsTo.map((email, index) => (
                        <Tag key={index} closable onClose={() => handleEmailRemoveTo(email)} color="blue" closeIcon={<DeleteFilled
                          style={{
                            fontSize: '0.8rem',
                            color: 'black',
                            verticalAlign: 'middle',
                          }}
                        />} style={{
                          marginBottom: '0.8rem',
                          marginTop: '0.8rem',
                          display: 'flex !important',
                          alignItems: 'center !important',
                          fontSize: '0.8rem',
                        }}>
                          {email}
                        </Tag>
                      ))}
                    </>
                  }
                >
                  <Space.Compact style={{ width: '100%' }}>
                    <Input
                      type="email"
                      value={inputValueTo}
                      onChange={handleInputChangeTo}
                      onPressEnter={handleInputConfirmTo}
                      onBlur={handleInputConfirmTo}
                      placeholder="Enter email"
                    />

                    <Button type="primary" onClick={handleInputConfirmTo}><PlusOutlined style={{
                      verticalAlign: 'middle',
                    }} /></Button>
                  </Space.Compact>
                </Form.Item>
                {/*CC*/}
                <Form.Item
                  name="cc"
                  label="CC:"
                  rules={[
                    {
                      required: emailsTo.length === 0,
                      message: 'Por favor ingrese un correo electronico',
                    },
                    {
                      type: 'email',
                      message: 'Por favor ingrese un correo electronico valido',
                    }
                  ]}
                  extra={
                    <>
                      {emailsCc.map((email, index) => (
                        <Tag key={index} closable onClose={() => handleEmailRemoveCc(email)} color="blue" closeIcon={<DeleteFilled
                          style={{
                            fontSize: '0.8rem',
                            color: 'black',
                            verticalAlign: 'middle',
                          }}
                        />} style={{
                          marginBottom: '0.8rem',
                          marginTop: '0.8rem',
                          display: 'flex !important',
                          alignItems: 'center !important',
                          fontSize: '0.8rem',
                        }}>
                          {email}
                        </Tag>
                      ))}
                    </>
                  }
                >
                  <Space.Compact style={{ width: '100%' }}>
                    <Input
                      type="email"
                      value={inputValueCc}
                      onChange={handleInputChangeCc}
                      onPressEnter={handleInputConfirmCc}
                      onBlur={handleInputConfirmCc}
                      placeholder="Enter email"
                    />

                    <Button type="primary" onClick={handleInputConfirmCc}><PlusOutlined style={{
                      verticalAlign: 'middle',
                    }} /></Button>
                  </Space.Compact>

                </Form.Item>
                {/*CCO*/}
                <Form.Item
                  name="cco"
                  label="CCO:"
                  rules={[
                    {
                      required: emailsTo.length === 0,
                      message: 'Por favor ingrese un correo electronico',
                    },
                    {
                      type: 'email',
                      message: 'Por favor ingrese un correo electronico valido',
                    }
                  ]}
                  extra={
                    <>
                      {emailsCco.map((email, index) => (
                        <Tag key={index} closable onClose={() => handleEmailRemoveCco(email)} color="blue" closeIcon={<DeleteFilled
                          style={{
                            fontSize: '0.8rem',
                            color: 'black',
                            verticalAlign: 'middle',
                          }}
                        />} style={{
                          marginBottom: '0.8rem',
                          marginTop: '0.8rem',
                          display: 'flex !important',
                          alignItems: 'center !important',
                          fontSize: '0.8rem',
                        }}>
                          {email}
                        </Tag>
                      ))}
                    </>
                  }
                >
                  <Space.Compact style={{ width: '100%' }}>
                    <Input
                      type="email"
                      value={inputValueCco}
                      onChange={handleInputChangeCco}
                      onPressEnter={handleInputConfirmCco}
                      onBlur={handleInputConfirmCco}
                      placeholder="Enter email"
                    />

                    <Button type="primary" onClick={handleInputConfirmCco}><PlusOutlined style={{
                      verticalAlign: 'middle',
                    }} /></Button>
                  </Space.Compact>
                </Form.Item>

                { /*attached files*/ }
                <ShowWhen show={!!attachments && attachments.length > 0}>
                  <Form.Item name="attachedFiles" label="Archivos Adjuntos:">
                    <Space>
                      {(attachments ?? []).map((file, index) => (
                        <Tag key={index} closable
                          // onClose={() => handleRemoveFile(file)}
                             color="blue" closeIcon={<DeleteFilled
                          style={{
                            fontSize: '0.8rem',
                            color: 'black',
                            verticalAlign: 'middle',
                          }}
                        />} style={{
                          marginBottom: '0.8rem',
                          marginTop: '0.8rem',
                          display: 'flex !important',
                          alignItems: 'center !important',
                          fontSize: '0.8rem',
                        }}>
                          {file.nombreArchivo}
                        </Tag>
                      ))}
                    </Space>
                  </Form.Item>
                </ShowWhen>

                {/*Mensaje*/}
                <ShowWhen show={!!showMessageInput}>
                  <Form.Item
                    name="message"
                    label="Mensaje:"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese un mensaje',
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Ingrese su mensaje"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                  </Form.Item>
                </ShowWhen>
                <Form.Item>
                  <Button type="primary" htmlType="submit" block>
                    Enviar Correo
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Spinner>
      </Spin>
    </Modal>
  )
}

export default SendEmail
